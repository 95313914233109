import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LoadingIndicatorService} from '../services/loading-indicator/loading-indicator.service';
import {Observable} from 'rxjs';
import {finalize} from "rxjs/operators";


@Injectable()
export class LoadingIndicatorInterceptor implements HttpInterceptor {

  constructor(private loadingIndicatorService: LoadingIndicatorService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // don't show loading screen if request has header attribute "no-load"
    if (!req.headers.get("no-load")) {
      let newReq = req.clone({headers: req.headers.delete("Loading")});
      // emit onStarted event before request execution
      this.loadingIndicatorService.onStarted(newReq);

      // emit onFinished event after request execution
      return next.handle(newReq).pipe(finalize(() => this.loadingIndicatorService.onFinished(newReq)));
    } else {
      return next.handle(req);
    }
  }

}
