import {Component, Input} from "@angular/core";
import {GeecFormGroup} from "../../../../core/directives/reactive-forms/geec-form-group";
import {DadesExecucioSharedFormValidatorModel} from "../../../models/forms/dades-basiques/dades-basiques-shared-form-validator.model";
import {AbstractControl, FormGroup} from "@angular/forms";
import {calculateDataFi, GeecLocale, LOCALE_CA} from "../../../utils/calendar-utils";
import {forkJoin} from "rxjs";
import {catchError} from "rxjs/operators";
import {SelectItem} from "primeng/api";
import {Router} from "@angular/router";
import {DadaMestraService} from "../../../services/dada-mestra/dada-mestra.service";
import {DadesExecucioControllerService} from "./dades-execucio-controller.service";

@Component({
  template: ''
})
export class DadesExecucioControllerComponent {

  @Input() public parentFormGroup: GeecFormGroup;
  @Input() public templateValidators: DadesExecucioSharedFormValidatorModel;
  public locale: GeecLocale = LOCALE_CA;

  motivoNoAdjudicadoOptions: SelectItem[] = [];

  constructor(protected router: Router,
              protected dadaMestraService: DadaMestraService,
              protected service: DadesExecucioControllerService) {
    this._loadOptions();
  }

  private _loadOptions(): void {
    forkJoin(
      this.service.getMotivoNoAdjudicado(),
    ).pipe(
      catchError(() => this.router.navigateByUrl("error"))
    ).subscribe(
      ([motivoNoAdjudicado]: [SelectItem[]]) => {
        this.motivoNoAdjudicadoOptions = motivoNoAdjudicado;
      });
  }

  get dadesExecucioForm(): FormGroup {
    return <FormGroup>this.parentFormGroup.get("dadesExecucio");
  }

  public calculateDataFiExecucio(): void {
    const termini: AbstractControl = this.parentFormGroup.get("terminis").get('terminiExecucio');
    const tipusTermini: AbstractControl = this.parentFormGroup.get("terminis").get('tempsTerminiExecucio');
    const dataInici: AbstractControl = this.dadesExecucioForm.get('iniciExecucio');
    const dataFi: AbstractControl = this.dadesExecucioForm.get('fiExecucio');
    calculateDataFi(termini, tipusTermini, dataInici, dataFi);
  }

  public resetProvinciaExecucio(): void {
    const SPAIN: Number = 65;
    let provinciaExecucio: AbstractControl = this.dadesExecucioForm.get('provinciaExecucio');
    const paisExecucio: AbstractControl = this.dadesExecucioForm.get('paisExecucio');

    // If country is different from Spain, province must be null.
    if (paisExecucio.value !== SPAIN) {
      provinciaExecucio.setValue(null);
    }
  }
}
