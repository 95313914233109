import {FormControl} from "@angular/forms";
import {SelectItemData} from "../models/forms/geec-form-dto.model";
import {TipusIvaUtils} from "../utils/tipus-iva.utils";
import {NumberUtils} from "../utils/number-utils";

export interface TipusIvaControllerConfig {
  importLicitacio: FormControl;
  importAdjudicacio: FormControl;
  tipusIvaLicitacioData: SelectItemData[];
  tipusIvaAdjudicacioData: SelectItemData[];
  ivaLicitacio: FormControl;
  ivaAdjudicacio: FormControl;
}

export class TipusIvaController {
  private _tipusIvaConfig: TipusIvaControllerConfig;

  constructor(tipusIvaControllerConfig: TipusIvaControllerConfig) {
    this._tipusIvaConfig = tipusIvaControllerConfig;
  }

  get tipusIvaConfig(): TipusIvaControllerConfig {
    return this._tipusIvaConfig;
  }

  getImportLicitacioAmbIva(importValue: number): number {
    return this.getImportAmbIva(importValue);
  }

  getImportAdjudicacioAmbIva(importValue: number): number {
    return this.getImportAmbIva(importValue, false);
  }

  getImportAmbIva(importValue: number, licitacio: boolean = true): number {
    const tipusIvaId: number = licitacio ? this.tipusIvaConfig.ivaLicitacio.value : this.tipusIvaConfig.ivaAdjudicacio.value;
    const tipusIvaData: SelectItemData[] = licitacio ? this.tipusIvaConfig.tipusIvaLicitacioData : this.tipusIvaConfig.tipusIvaAdjudicacioData;
    return NumberUtils.calculatePercent(importValue, TipusIvaUtils.findTipusIva(tipusIvaId, tipusIvaData), false);
  }


}
