import {Injectable} from "@angular/core";
import {SelectItem} from "primeng/api";
import {DadaMestra, DadaMestraService, DadaMestraType} from "../../../services/dada-mestra/dada-mestra.service";
import {CustomUtils} from "../../../utils/custom.utils";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DadesGeneralsControllerService {

  constructor(private _dadaMestraService: DadaMestraService) {
  }

  public getPais(): Observable<SelectItem[]> {
    return this._dadaMestraService.getOptions(DadaMestraType.PAIS).pipe(map((data: DadaMestra[]) => {
      return CustomUtils.toSelectItemArray(data);
    }, (err: any) => console.error(err)));
  }

  public getCondicionEspcialEjecucion(): Observable<SelectItem[]> {
    return this._dadaMestraService.getOptions(DadaMestraType.CONDICION_ESPECIAL_EJECUCION).pipe(map((data: DadaMestra[]) => {
      return CustomUtils.toSelectItemArray(data);
    }, (err: any) => console.error(err)));
  }
}
