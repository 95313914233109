import {CustomUtils} from '../../utils/custom.utils';
import {CacheItem} from './cache-item.model';

export class UserPreference {
  userId: number = null;
  key: string = null;
  subKey: string = null;
  content: string = null;
  modelVersion: string = null;

  static createFromCacheItem(userId: number, key: string, domain: CacheItem, subKey?: string): UserPreference {
    let userPreference = new UserPreference();
    userPreference.userId = userId;
    // object to json
    userPreference.content = JSON.stringify(domain.content);
    userPreference.modelVersion = domain.modelVersion;
    userPreference.key = key;
    if (CustomUtils.isDefined(subKey)) {
      userPreference.subKey = subKey;
    }
    return userPreference;
  }

}
