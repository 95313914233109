import {EventEmitter, Input, ViewChild} from "@angular/core";
import {Dialog} from "primeng/dialog";
import {SubmitPopupEvent} from "../../../core/classes/geec-form.controller";

export class ReopenPopup {

  @ViewChild('dialog')
  public dialog: Dialog;

  @Input()
  public visible: boolean = false;

  public onSubmit$: EventEmitter<SubmitPopupEvent> = new EventEmitter();

  public motiu: string = '';

  public eventSubmit: SubmitPopupEvent = {};

  constructor() { }

  public isMotiuValid(): boolean {
    return this.motiu.trim().length > 0;
  }

  public submit() {
    if (this.isMotiuValid()) {
      this.eventSubmit.motiu = this.motiu;
      this.onSubmit$.emit(this.eventSubmit);
    }
  }

  private _resetMotiu() {
    this.motiu = '';
  }

}
