import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ControllerResponse, ResponseMessage} from "../../../shared/models/forms/controller-response.model";
import {Message} from "primeng/api";
import {mapResponseMessages} from "../../../shared/utils/conversion-utils";
import {HttpErrorResponse} from "@angular/common/http";
import {NotificationDialogConfig} from "../../components/notifications/notification-dialog-config";
import {IdentifiedResponse} from "../../../llistats/listing-detail/listing-detail.component";
import {CustomUtils} from "../../../shared/utils/custom.utils";

type Severities = 'success' | 'info' | 'warn' | 'error';

export const enum Severity {
  SUCCESS = 'success',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error'
}

@Injectable()
export class NotificationsService {

  readonly notificationChange$: Subject<Object> = new Subject<Object>();
  readonly dialogText$: Subject<NotificationDialogConfig> = new Subject<NotificationDialogConfig>();

  notify(severity: Severities, summary: string, detail: string) {
    this.notificationChange$.next({severity, summary, detail});
  }

  getMessagesAndNotify(responses: ControllerResponse<any>[], messages: Message[] = []): Message[] {
    return responses.reduce<Message[]>((previous: Array<Message>, response: ControllerResponse<any>) => {
      return previous.concat(mapResponseMessages(response.messages).filter((message: Message) => {
        let shouldPass: boolean = false;
        switch (message.severity) {
          case Severity.ERROR:
            shouldPass = true;
          case Severity.INFO:
          case Severity.WARN:
          case Severity.SUCCESS:
            this.notify(message.severity, message.summary, message.detail);
            break;
          default:
            break;
        }
        return shouldPass;
      }));
    }, messages);
  }

  handleErrorResponse(err: HttpErrorResponse): Message[] {
    let messages: Message[] = [];
    if (err && err.error) {
      messages = this.getMessagesAndNotify([<ControllerResponse<any>>err.error]);
    }
    return messages;
  }

  openDialog(config: NotificationDialogConfig): void {
    this.dialogText$.next(config);
  }

  showDetailResponseMessages(messages: ResponseMessage[], header: string): void {
    let returnMessage: string = "";
    messages.forEach((msg: ResponseMessage) => {
      returnMessage += msg.summary + "<br>";
    });
    this.openDialog({
      header: header,
      text: returnMessage
    });
  }

  identifyMessages(responses: IdentifiedResponse[]): ControllerResponse<any>[] {
    return responses.map((res: IdentifiedResponse) => {
      res.response.messages = res.response.messages.map((msg: ResponseMessage) => {
        if (msg.severity === Severity.ERROR && CustomUtils.areDefined(msg.detail, res.identifiedField)) {
          msg.detail = res.identifiedField + ": " + msg.detail;
        }
        return msg;
      });
      return res.response;
    });
  }

}
