import {Directive, Host, Optional, Self} from '@angular/core';
import {Calendar} from "primeng/calendar";
import {LOCALE_ES} from "../../shared/utils/calendar-utils";

@Directive({
  selector: 'p-calendar'
})
export class CalendarDefaultDirective {

  constructor(@Host() @Self() @Optional() public host: Calendar) {
    if (host != null) {
      host.dateFormat = 'dd/mm/yy';
      host.locale = LOCALE_ES;
    }
  }

}
