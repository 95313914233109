import {CUSTOM_ELEMENTS_SCHEMA, NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BooleanCheckComponent} from './components/boolean-check/boolean-check.component';
import {ShowErrorsComponent} from './components/show-errors/show-errors.component';
import {DirectivesModule} from '../core/directives/directives.module';
import {FormDataResolve} from '../core/resolvers/form-data.resolve';
import {FormService} from '../core/services/form.service';
import {DadaMestraService} from './services/dada-mestra/dada-mestra.service';
import {FormDataService} from './services/form-data/form-data.service';
import {PartidaPressupostariaService} from './services/partida-pressupostaria/partida-pressupostaria.service';
import {CentreGestorService} from './services/centre-gestor/centre-gestor.service';
import {PortaSignaturesService} from './services/portasignatures/portasignatures.service';
import {UsuariValidadorService} from './services/usuari-validador/usuari-validador.service';
import {PipesModule} from './pipes/pipes.module';
import {DadesExpedientComponent} from './components/dades-expedient/dades-expedient.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DocumentsOpcionalsComponent} from './components/documents-opcionals/documents-opcionals.component';
import {LicitadorService} from './services/licitador/licitador.service';
import {PoblacioService} from './services/poblacio/poblacio.service';
import {AutocompleteComponent} from "./components/autocomplete/autocomplete.component";
import {NumberInputModule} from './components/number-input/number-input.module';
import {DadesBasiquesMapperService} from './services/dades-basiques-mapper/dades-basiques-mapper.service';
import {CodisComponent} from "./components/codis/codis.component";
import {CofinancatsComponent} from "./components/cofinancats/cofinancats.component";
import {TerminisComponent} from "./components/terminis/terminis.component";
import {ContentHeaderComponent} from './components/content-header/content-header.component';
import {ContentFooterComponent} from './components/content-footer/content-footer.component';
import {TascaComponent} from './components/tasca/tasca.component';
import {CopiaAutenticaService} from './services/copia-autentica/copia-autentica.service';
import {HelpButtonModule} from "./components/help-button/help-button.module";
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DropdownModule} from 'primeng/dropdown';
import {FileUploadModule} from 'primeng/fileupload';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {MultiSelectModule} from 'primeng/multiselect';
import {SelectButtonModule} from 'primeng/selectbutton';
import {TooltipModule} from 'primeng/tooltip';
import {EditorModule} from 'primeng/editor';
import {Dialog, DialogModule} from 'primeng/dialog';
import {PanelModule} from 'primeng/panel';
import {TreeModule} from 'primeng/tree';
import {FacturaService} from './services/factura/factura.service';
import {TableModule} from 'primeng/table';
import {DisplayFieldComponent} from './components/display-field/display-field.component';
import {IconComponent} from './components/icon/icon.component';
import {PanelErrorsIconComponent} from './components/panel-errors-icon/panel-errors-icon.component';
import {ReassignarFacturesComponent} from './standalone/components/reassignar-factures/reassignar-factures.component';
import {RebutjarFacturesComponent} from "./standalone/components/rebutjar-factures/rebutjar-factures.component";
import {RadioButtonModule} from "primeng/radiobutton";
import {BustiaService} from "./services/bustia/bustia.service";
import {ActuacionsContractualsService} from "../actuacions-contractuals/services/actuacions-contractuals.service";
import {FaseService} from "./services/fase/fase.service";
import {TramitsService} from "./services/tramits/tramits.service";
import {UsuariService} from "../usuaris/services/usuari.service";
import {DefinicioIntegracionsService} from "./services/definicio-integracions/definicio-integracions.service";
import {DocumentService} from "./services/document/document.service";
import {DadesBasiquesComponentsModule} from "./components/dades-basiques/dades-basiques-components.module";
import {ProcessarFacturesComponent} from './standalone/components/processar-factures/processar-factures.component';
import {PartidesOfertesService} from "./services/partides-ofertes/partides-ofertes.service";
import {TipusIvaService} from './services/tipus-iva.service';
import {TramitacioMassivaService} from "../core/services/tramitacio-massiva/tramitacio-massiva.service";
import {DocumentacioService} from "../documentacio/documentacio.service";
import {FormMassiveDataResolve} from "../core/resolvers/form-massive-data.resolve";
import {TableCountComponent} from "../core/directives/table-count.component";
import {CriterisCercaComponent} from './components/criteris-cerca/criteris-cerca.component';
import {AcordMarcIncasolComponent} from "./standalone/components/crear-expedients-incasol/acord-marc-incasol.component";
import {RemesaService} from "./standalone/components/remesa/remesa.service";
import {OfertaService} from "./standalone/components/dades-ofertes/oferta.service";
import { BrowserModule } from '@angular/platform-browser';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ErrorLoginComponent } from './standalone/error-login/error-login.component';
import { CertificadoService } from './services/document/certificado.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
// export function translateLoader(http: Http) { return new TranslateStaticLoader(http, 'assets/i18n', '.json')}
@NgModule({
  declarations: [
    // components
    IconComponent,
    AutocompleteComponent,
    BooleanCheckComponent,
    ShowErrorsComponent,
    DadesExpedientComponent,
    DocumentsOpcionalsComponent,
    CodisComponent,
    CofinancatsComponent,
    TerminisComponent,
    ContentHeaderComponent,
    ContentFooterComponent,
    TascaComponent,
    DisplayFieldComponent,
    IconComponent,
    PanelErrorsIconComponent,
    ReassignarFacturesComponent,
    RebutjarFacturesComponent,
    ProcessarFacturesComponent,
    TableCountComponent,
    CriterisCercaComponent,
    AcordMarcIncasolComponent,
    ErrorLoginComponent
  ],
  exports: [

    CommonModule,
    // components
    IconComponent,
    AutocompleteComponent,
    BooleanCheckComponent,
    ShowErrorsComponent,
    DadesExpedientComponent,
    DocumentsOpcionalsComponent,
    CodisComponent,
    CofinancatsComponent,
    TerminisComponent,
    ContentHeaderComponent,
    ContentFooterComponent,
    TascaComponent,
    DisplayFieldComponent,
    PanelErrorsIconComponent,
    ReassignarFacturesComponent,
    RebutjarFacturesComponent,
    ProcessarFacturesComponent,
    TableCountComponent,
    AcordMarcIncasolComponent,
    // modules
    DirectivesModule,
    PipesModule,
    DropdownModule,
    MultiSelectModule,
    InputTextareaModule,
    CheckboxModule,
    DialogModule,
    EditorModule,
    SelectButtonModule,
    HelpButtonModule,
    RadioButtonModule,
    DadesBasiquesComponentsModule,
    TooltipModule,
    TranslateModule,
    CriterisCercaComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    PipesModule,
    DialogModule,
    TooltipModule,
    CalendarModule,
    ConfirmDialogModule,
    NumberInputModule,
    CheckboxModule,
    MultiSelectModule,
    EditorModule,
    SelectButtonModule,
    TableModule,
    RadioButtonModule,
    // dades expedient modules
    PanelModule,
    InputTextareaModule,
    DropdownModule,
    TreeModule,
    RadioButtonModule,
    // documents opcionals modules
    FileUploadModule,
    HelpButtonModule,
    HttpClientModule,
    TranslateModule
  ],
  providers: [
    // services
    CentreGestorService,
    DadaMestraService,
    FormDataService,
    PartidaPressupostariaService,
    PortaSignaturesService,
    PoblacioService,
    UsuariValidadorService,
    FormService,
    LicitadorService,
    DadesBasiquesMapperService,
    CopiaAutenticaService,
    FacturaService,
    BustiaService,
    FaseService,
    ActuacionsContractualsService,
    TramitsService,
    UsuariService,
    DefinicioIntegracionsService,
    DocumentService,
    PartidesOfertesService,
    TipusIvaService,
    TramitacioMassivaService,
    DocumentacioService,
    OfertaService,
    CertificadoService,
    // resolvers
    FormDataResolve,
    FormMassiveDataResolve
  ],
  entryComponents: [
    Dialog
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule
    }
  }
}
