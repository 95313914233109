import "reflect-metadata";
import {CustomUtils} from "../../../shared/utils/custom.utils";
import {MapHandler} from "./classes/abstract/map-handler";
import {MapHandlerFactory} from "./factories/map-handler.factory";
import {Mapping} from "./classes/mapping";

// TODO: Maybe convert mappings into decorators
export function Mapper(mappings: Array<Mapping>, cb?: (obj: object) => object): MethodDecorator {
  return function (target: any, key: string | symbol, descriptor: PropertyDescriptor): PropertyDescriptor {

    if (CustomUtils.isUndefinedOrNull(descriptor)) {
      descriptor = Object.getOwnPropertyDescriptor(target, key);
    }

    const _ref: any = descriptor.value;

    descriptor.value = function (...args: any[]): object {
      let result: object = _ref.apply(this, args);

      const handler: MapHandler = MapHandlerFactory.new(result);

      result = handler.map(mappings);

      if (cb) {
        result = cb(result);
      }

      return result;
    };

    return descriptor;
  };
}
