import {Component, Input, OnDestroy} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {GeecFormGroup} from '../../../core/directives/reactive-forms/geec-form-group';
import {GeecFormBuilder} from '../../../core/directives/reactive-forms/geec-form-builder';
import {AppConstants} from '../../../app.constants';
import {FormUtils} from "../../utils/form-utils";
import {CofinancatsSharedFormValidatorModel} from "../../models/forms/dades-basiques/dades-basiques-shared-form-validator.model";
import {CofinancamentsSharedFormModel} from "../../models/forms/dades-basiques/dades-basiques-shared-form.model";
import {GeecValidators} from "../../validators/geec-validators";
import {NumberUtils} from "../../utils/number-utils";
import {TableColumn, TableColumnType} from '../../interfaces/table-column';
import {TableUtils} from '../../utils/table-utils/table-utils';
import {ReactiveFormValidator} from "../../models/forms/reactive-form-validator";
import {FormService} from "../../../core/services/form.service";
import {FrontDTO} from "../../models/forms/front-dto.model";
import {ActivatedRoute} from "@angular/router";
import {FormDefinition} from "../../models/forms/geec-form-dto.model";
import {NotificationsService, Severity} from "../../../core/services/notifications/notifications.service";
import {Subject, Subscription} from "rxjs";
import {SubscriptionUtils} from "../../utils/subscription-utils";
import {takeUntil} from "rxjs/operators";
import {CustomUtils} from "../../utils/custom.utils";
import {createArray} from "../../utils/creation/create-array";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'geec-cofinancats',
  templateUrl: './cofinancats.component.html'
})
export class CofinancatsComponent implements OnDestroy {

  @Input() readOnly: boolean;
  @Input() expedientId: number = null;

  // table
  columns: TableColumn[];
  columnsSelect: TableColumn[];

  // dialog
  displayCofinancamentDialog: boolean = false;
  cofinancamentForm: GeecFormGroup;
  selectedCofinancamentIndex: number;
  isNewCofinancament: boolean = false;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  /** Corticon definition */
  @Input() get templateValidations(): CofinancatsSharedFormValidatorModel {
    return this._templateValidations;
  }

  set templateValidations(templateValidations: CofinancatsSharedFormValidatorModel) {
    if (templateValidations && !this._templateValidations) {
      TableUtils.updateTableColumnOptions(this.columnsSelect, templateValidations.cofinancaments);
    }
    this._templateValidations = templateValidations;
  }

  /** The parent form group */
  @Input() get parentFormGroup(): GeecFormGroup {
    return this._parentFormGroup;
  }

  set parentFormGroup(parentFormGroup: GeecFormGroup) {
    this._parentFormGroup = parentFormGroup;
    SubscriptionUtils.unsuscribeIfNeeded(this._cofinancatsControlSubscription);
    if (this.cofinancatsForm) {
      this._cofinancatsControlSubscription = this.cofinancatsForm.get("cofinancaments").valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(() => this._calculateSums());
    }
  }

  get cofinancatsForm(): FormGroup {
    return this.parentFormGroup.get("cofinancats") as FormGroup;
  }

  private _cofinancatsControlSubscription: Subscription;
  private _parentFormGroup: GeecFormGroup;
  private _templateValidations: CofinancatsSharedFormValidatorModel;
  private _ngUnsubscribe: Subject<void> = new Subject<void>();

  traducciones: any = {};

  constructor(private fb: GeecFormBuilder, private formService: FormService, private route: ActivatedRoute, private notificationsService: NotificationsService,
              private _translate: TranslateService) {
    this.traducirColumnas();
    this._translate.onLangChange.subscribe(() => {
      this.traducirColumnas();
      this._loadColumnOptions();
    })
    this._loadColumnOptions();
    this._createCofinancamentForm();
    this._subscribeChangesTipusFons();
  }

  private traducir(tag: string) : string{
    let ruta = 'confinancats' // FIXME: ERROR DE CONFINANÇATS -> CO FINANÇATS
    return this._translate.instant(ruta + '.' + tag);
  }

  private traducirColumnas(){
    this.traducciones = {
      vigenciaDesde: this.traducir('column-vigencia-desde'),
      vigenciaFinsA: this.traducir('column-vigencia-hasta'),
      tipusFons: this.traducir('column-tipo-fondo'),
      percentatge: this.traducir('column-porcentaje'),
      importLicitacion: this.traducir('column-importe-licitacion'),
      importAdjudicacio: this.traducir('column-importe-adjudicacion'),
      imputacio: this.traducir('column-imputacion'),
      observacions: this.traducir('column-observaciones'),
      programa: this.traducir('column-programa'),
      concepte: this.traducir('column-concepto'),
      valor: this.traducir('column-valor'),
    }
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
    SubscriptionUtils.unsuscribeIfNeeded(this._cofinancatsControlSubscription);
  }

  public addCofinancament(): void {
    this.isNewCofinancament = true;
    this.displayCofinancamentDialog = true;
  }

  public selectCofinancament(cofinancament: CofinancamentsSharedFormModel, rowIndex: number): void {
    this.isNewCofinancament = false;
    this.selectedCofinancamentIndex = rowIndex;
    FormUtils.setFormValues(this.cofinancamentForm, cofinancament);
    this.triggerValueChanges(() => this.displayCofinancamentDialog = true);
  }

  public saveCofinancament(): void {
    if (this.isNewCofinancament && this.cofinancamentForm.pristine || this._isEmptyCofinancament(this.cofinancamentForm.value)) {
      this.notificationsService.notify(Severity.ERROR, this.traducir('notificacion-formulario-invalido'), this.traducir('notificacion-formulario-invalido-detalle'));
    } else if(this.cofinancamentForm.get("tipusFons").value !== null &&  this.cofinancamentForm.get('observacions').value == null){
      this.notificationsService.notify(Severity.ERROR, this.traducir('notificacion-formulario-invalido'), this.traducir('notificacion-formulario-falta_observaciones'));
    } else if (FormUtils.isControlValid(this.cofinancamentForm)) {
      const cofinancaments: CofinancamentsSharedFormModel[] = createArray(this.cofinancatsForm.get("cofinancaments").value);
      if (this.isNewCofinancament) {
        cofinancaments.push(this.cofinancamentForm.getRawValue());
      } else {
        cofinancaments[this.selectedCofinancamentIndex] = this.cofinancamentForm.getRawValue();
      }
      this.cofinancatsForm.get("cofinancaments").setValue(cofinancaments);
      this.displayCofinancamentDialog = false;
    } else {
      this.notificationsService.notify(Severity.ERROR, this.traducir('notificacion-formulario-invalido'), this.traducir('notificacion-formulario-error'));
    }
  }

  private _isEmptyCofinancament(cofinancament: CofinancamentsSharedFormModel): boolean {
    return (<any>Object).values(cofinancament).every((value: any) => CustomUtils.isUndefinedOrNull(value));
  }

  public deleteCofinancament(rowIndex: number): void {
    const cofinancaments: CofinancamentsSharedFormModel[] = createArray(this.cofinancatsForm.get("cofinancaments").value);
    cofinancaments.splice(rowIndex, 1);
    this.cofinancatsForm.get("cofinancaments").setValue(cofinancaments);
  }

  public closeCofinancamentDialog(): void {
    this.cofinancamentForm.reset();
  }

  triggerValueChanges(cb?: () => void): void {
    const frontDto: FrontDTO = new FrontDTO(this.expedientId, this.route.snapshot.params['taskId'], this.route.snapshot.params['idTaskable'],
      {cofinancaments: this.cofinancamentForm.getRawValue()});
    this.formService.getHybridFormChildRules('DadesBasiques', 'dadesBasiquesCofinancats', frontDto, 'changeChild').subscribe((response: FormDefinition) => {
      if (response && response.fields) {
        const validations: ReactiveFormValidator = new ReactiveFormValidator(response.fields, false, this._translate);
        console.log(validations);
        FormUtils.updateFormValues(<FormGroup>this.cofinancamentForm, validations);
        if (cb) {
          cb();
        }
      }
    });
  }

  private _loadColumnOptions(): void {
    this.columnsSelect = [
      {field: "vigenciaDesde", header: this.traducciones.vigenciaDesde, width: "10em", type: TableColumnType.TEXT},
      {field: "vigenciaFins", header: this.traducciones.vigenciaFinsA, width: "10em", type: TableColumnType.TEXT},
      {field: "tipusFons", header: this.traducciones.tipusFons, width: "15em", type: TableColumnType.SELECT},
      {field: "percent", header: this.traducciones.percentatge, width: "11em", type: TableColumnType.DECIMAL},
      {
        field: "importPrevistLicitacio",
        header: this.traducciones.importLicitacion,
        width: "13em",
        type: TableColumnType.DECIMAL
      },
      {
        field: "importPrevistAdjudicacio",
        header: this.traducciones.importAdjudicacio,
        width: "15em",
        type: TableColumnType.DECIMAL
      },
      {field: "imputacio", header: this.traducciones.imputacio, width: "15em", type: TableColumnType.SELECT},
      {field: "indicador", header: this.traducciones.indicador, width: "15em", type: TableColumnType.SELECT},
      {field: "observacions", header: this.traducciones.observacions, width: "15em", type: TableColumnType.TEXT},
      {field: "programa", header: this.traducciones.programa, width: "16em", type: TableColumnType.SELECT},
      {field: "concepte", header: this.traducciones.concepte, width: "12em", type: TableColumnType.SELECT},
      {field: "valor", header: this.traducciones.valor, width: "12em", type: TableColumnType.SELECT},
    ];
    // select all columns by default
    this.columns = this.columnsSelect;
  }

  private _createCofinancamentForm(): void {
    const {min, max} = AppConstants.importRange;
    this.cofinancamentForm = this.fb.group({
      id: [null],
      vigenciaDesde: [null, GeecValidators.year],
      vigenciaFins: [null, GeecValidators.year],
      tipusFons: [null],
      programa: [null],
      percent: [null, GeecValidators.range(1, 100)],
      importPrevistLicitacio: [null, GeecValidators.range(min, max)],
      importPrevistAdjudicacio: [null, GeecValidators.range(min, max)],
      imputacio: [null],
      indicador: [null],
      observacions: [null, ],
      concepte: [null],
      valor: [null]
    });
  }

  private _calculateSums(): void {
    let totalNetLicitat: number = 0;
    let importPrevistLicitacioGlobal: number = 0;
    let importPrevistAdjudicacioGlobal: number = 0;
    let financamentGlobal: number = 0;

    const cofinancaments: CofinancamentsSharedFormModel[] = createArray(this.cofinancatsForm.get("cofinancaments").value);
    if (CustomUtils.isArrayNotEmpty(cofinancaments)) {
      cofinancaments.forEach((cofinancament: CofinancamentsSharedFormModel) => {
        if (CustomUtils.isDefined(cofinancament.importPrevistLicitacio)) {
          importPrevistLicitacioGlobal += cofinancament.importPrevistLicitacio;
          if (CustomUtils.isDefined(cofinancament.percent)) {
            totalNetLicitat += NumberUtils.findPercentualValue(cofinancament.importPrevistLicitacio, cofinancament.percent);
            financamentGlobal = NumberUtils.findPercentage(importPrevistLicitacioGlobal, totalNetLicitat);
          }
        }

        if (CustomUtils.isDefined(cofinancament.importPrevistAdjudicacio)) {
          importPrevistAdjudicacioGlobal += cofinancament.importPrevistAdjudicacio;
        }
      });
    }

    this.cofinancatsForm.get('financamentGlobal').setValue(NumberUtils.round(financamentGlobal, 2));
    this.cofinancatsForm.get('importPrevistLicitacioGlobal').setValue(NumberUtils.round(importPrevistLicitacioGlobal, 2));
    this.cofinancatsForm.get('importPrevistAdjudicacioGlobal').setValue(NumberUtils.round(importPrevistAdjudicacioGlobal, 2));
  }

   private _subscribeChangesTipusFons(): void{
    this.cofinancamentForm.get("tipusFons").valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((tipusFons: number) => {
      if(tipusFons !== null){
        this.updateValidators();
      }
    });
  }

  public updateValidators(){
    this.cofinancamentForm.get('observacions').setValidators([Validators.required, Validators.minLength(20), Validators.maxLength(1000)]);
    this.cofinancamentForm.updateValueAndValidity();
  }

}
