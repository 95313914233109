import {TaskLabelModel} from "./task-label.model";

export class RetornTasca {
  motiuRetorn: string;
  descripcioRetorn: string;
}

export class Tasca {
  id: string;
  keyTask: string;
  nom: string;
  assignee: string;
  dataExpiracio: Date;
  /** descripcio = ruta frontend **/
  descripcio: string;
  dto: string;
  expedientId: number;
  taskeableId: number;
  expedientNumero: string;
  expedientExercici: number;
  expedientTitol: string;
  procedimentId: number;
  procedimentNom: string;
  unitatsPromotoresNoms: string[];
  motiuRetorn: string;
  descripcioRetorn: string;
  retornat: boolean;
  gestorExpedient: string;
  dataCreacio: Date;
  numeroSubexpedient: string;
  exerciciSubexpedient: number;
  taskStatus: string;
  readOnly: boolean;
  taskLabels: TaskLabelModel[];
  sistemaAdjudicacioId: number;
  taskeableType: string;

  constructor(id?: string, expedientId?: number, taskableId?: number, nom?: string, descripcio?: string, dto?: string) {
    if (id) {
      this.id = id;
    }

    if (expedientId) {
      this.expedientId = expedientId;
    }

    if (taskableId) {
      this.taskeableId = taskableId;
    }

    if (nom) {
      this.nom = nom;
    }

    if (descripcio) {
      this.descripcio = descripcio;
    }

    if (dto) {
      this.dto = dto;
    }
  }
}
