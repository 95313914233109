import * as vkbeautify from 'vkbeautify';
import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'prettyPrint'
})
export class PrettyPrintPipe implements PipeTransform {
  transform(value: string): string {
    let _value: string = value;

    if (_value.startsWith('<')) {
      _value = vkbeautify.xml(value);
    } else if (_value.startsWith('{') || _value.startsWith('[')) {
      _value = vkbeautify.json(value);
    }

    return _value;
  }
}
