import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConstants} from '../../../app.constants';
import {ControllerResponse} from '../../models/forms/controller-response.model';
import {Tasca} from '../../models/bustia/tasca.model';

export interface ReoberturaTascaResponse {
  tascaFrontDTO: Tasca;
}

@Injectable()
export class WorkflowService {

  constructor(private http: HttpClient) {
  }

  public sendReobrirTasca(taskId: string): Observable<ControllerResponse<ReoberturaTascaResponse>> {
    return this.http.post<ControllerResponse<ReoberturaTascaResponse>>(`${AppConstants.urlWorkflow}/reopen/${taskId}`, null);
  }

  public getReadonlyTasca(taskId: string): Observable<ControllerResponse<boolean>> {
    return this.http.post<ControllerResponse<boolean>>(`${AppConstants.urlWorkflow}/readOnly/${taskId}`, null);
  }

  public initTraspas(id: number): Observable<ControllerResponse<string>> {
    return this.http.post<ControllerResponse<string>>(`${AppConstants.urlDocumentComptableSenseIntegracio}/traspas/${id}`, null);
  }
}
