import {PreloadingStrategy, Route} from "@angular/router";
import {Observable} from "rxjs/internal/Observable";
import {of} from "rxjs/internal/observable/of";


export class GeecPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: Function): Observable<any> {
    if (route.data && route.data.preload) {
      return of(load);
    } else {
      return of(null);
    }
  }
}
