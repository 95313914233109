import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {HelpButtonComponent} from "./help-button.component";
import {CommonModule} from "@angular/common";
import {TooltipModule} from 'primeng/tooltip';
import {DomHandler} from 'primeng/api';

@NgModule({
  declarations: [
    HelpButtonComponent
  ],
  imports: [
    CommonModule,
    TooltipModule
  ],
  providers: [
    DomHandler
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HelpButtonModule {
}
