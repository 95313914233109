import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConstants} from '../../../app.constants';
import {GeecFormDTOModel} from "../../models/forms/geec-form-dto.model";
import {Tasca} from '../../models/bustia/tasca.model';
import {ControllerResponse} from "../../models/forms/controller-response.model";
import {LooseObject} from "../../models/forms/reactive-form-validator";

@Injectable()
export class FormDataService {

  constructor(private http: HttpClient) {
  }

  getFormData(tasca: Tasca): Observable<ControllerResponse<GeecFormDTOModel>> {
    const paramsMap: LooseObject<string> = {};
    if (tasca.readOnly) {
      paramsMap['readOnly'] = "true";
    }

    return this.http.get<ControllerResponse<GeecFormDTOModel>>(`${AppConstants.formsCollection}/${tasca.dto}/${tasca.id}`,
      {params: new HttpParams({fromObject: paramsMap})});
  }

  getFormDataReadOnly(dto: string, taskableId: string): Observable<ControllerResponse<GeecFormDTOModel>> {
    return this.http.get<ControllerResponse<GeecFormDTOModel>>(AppConstants.formsCollection + "/" + dto + "/byTaskeable/" + taskableId);
  }

}
