import {Tasca} from "../../shared/models/bustia/tasca.model";
import {Page} from "../../shared/interfaces/paging/page";

export class BustiaCountModel {
  tasquesBustia: Page<Tasca>;
  countTotalTasquesBustia: number;

  constructor (tasquesBustia: Page<Tasca>, countTotalTasquesBustia: number) {
    this.tasquesBustia = tasquesBustia;
    this.countTotalTasquesBustia = countTotalTasquesBustia;
  }
}
