import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import * as Bowser from "bowser/es5";
import {environment} from "../../environments/environment";
import {EnvironmentType} from "../shared/globals";

@Injectable({
  providedIn: 'root'
})
export class ApplicationInitService {

  public canLogin: boolean = false;
  public isBrowserSupported: boolean = true;
  public isBrowserOutdated: boolean = false;

  constructor(private http: HttpClient) {
  }

  public initializeApp(): Promise<any> {
    return new Promise((resolve: Function, reject: Function) => {

      const isPortable: boolean = window.navigator.userAgent.includes("geec2portable");
      const isOldPortable: boolean = window.navigator.userAgent.includes("geec-sap-portal");
      if (isPortable || isOldPortable) {
        // check portable version
        this.http.get(`assets/portable-version.json`).subscribe(
          (response: { minVersion: string, version: string }) => {
            let portableRegex: RegExpExecArray;
            let portableVersion: string;

            if (isPortable) {
              portableRegex = /.*geec2portable\/(.*?) .*/.exec(window.navigator.userAgent);
            } else {
              portableRegex = /.*geec-sap-portal\/(.*?) .*/.exec(window.navigator.userAgent);
            }

            if (portableRegex && portableRegex[1]) {
              portableVersion = portableRegex[1];
            }

            if (portableVersion) {
              this.canLogin = true;
            } else {
              this.isBrowserSupported = false;
            }
            resolve();
          });
      } else {
        this.canLogin = true;
        resolve();
      }

      if (environment.type === EnvironmentType.PRE) {
        this.http.get(environment.urlEnvironmentJson).subscribe(
          (response: { isPre: boolean }) => {
            environment.isPre = response.isPre;
          });
      }

      // Para AST se compila siempre el entorno DES, asi que, no hay que tener en cuenta el resto de entornos de AST(PRE, FOR, PRO)
      // if (environment.type === EnvironmentType.DES) {
      //   this.http.get(environment.urlEnvironmentJson).subscribe(
      //     (response: { urlIframe: string }) => {
      //       environment.urlIframe = response.urlIframe;
      //     });
      // }

      if (environment.type === EnvironmentType.DES) {
        this.http.get(environment.urlEnvironmentJson).subscribe(
          (response: { showLogin: boolean }) => {
            environment.showLogin = response.showLogin;
          });
      }

      if (environment.type === EnvironmentType.DES) {
        this.http.get(environment.urlEnvironmentJson).subscribe(
          (response: { validateCodiExpedient: boolean }) => {
            environment.validateCodiExpedient = response.validateCodiExpedient;
          });
      }

    });
  }

}
