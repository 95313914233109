import {Pipe, PipeTransform} from '@angular/core';
import {NumberUtils} from "../../utils/number-utils";

@Pipe({
  name: 'numberDecimal',
  pure: true
})
export class NumberDecimalPipe implements PipeTransform {

  transform(value: number, minimumFractionDigits: number = 2): string {
    return NumberUtils.formatImport(value, minimumFractionDigits);
  }

}
