import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {AcordMarc} from "../../models/expedient/acord-marc.model";
import {AppConstants} from "../../../app.constants";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ControllerResponse} from "../../models/forms/controller-response.model";
import {CustomUtils} from "../../utils/custom.utils";

@Injectable({
  providedIn: 'root'
})
export class ExpedientIncasolService {

  constructor(private _http: HttpClient) {
  }

  public crearExpedientIncasol(idExpedient: number, acordMarcId?: number, acordMarcNom?: string): Observable<ControllerResponse<string>> {
    let httpParameters: HttpParams = new HttpParams();

    if (CustomUtils.isDefined(acordMarcId)) {
      httpParameters = httpParameters.set("acordMarcId", acordMarcId.toString());
    }

    if (CustomUtils.isDefined(acordMarcNom)) {
      httpParameters = httpParameters.set("acordMarcNom", acordMarcNom);
    }

    return this._http.post<ControllerResponse<string>>(`${AppConstants.urlExpedientIncasol}/${idExpedient}`, null, {params: httpParameters});
  }

}
