import {Injectable} from "@angular/core";
import {AbstractControl, AsyncValidatorFn, FormArray, FormBuilder, ValidatorFn} from "@angular/forms";
import {GeecFormGroup} from "./geec-form-group";
import {LooseObject} from "../../../shared/models/forms/reactive-form-validator";
import {GeecFormControl} from "./geec-form-control";
import {GeecFormArray} from "./geec-form-array";

@Injectable()
export class GeecFormBuilder extends FormBuilder {

  constructor() {
    super();
  }

  public dadesExpedientGroup(): GeecFormGroup {
    return this.group({
      id: [null],
      lots: [null],
      numero: [null],
      titol: [null],
      nuts: [null],
      modalitat: [null],
      subtipoContrato: [null],
      objecte: [null],
      procediment: [null],
      tipusContracte: [null],
      unitatsPromotores: [[]],
      unitatTramitadora: [null],
      tipusTramitacio: [null],
      totalLots: [null],
      teMesa: [null],
      etiquetaLot: [null],
      numeroLot: [null],
      tramitacioSimplificada: [null],
      subExpedients: [null],
      sistemaAdjudicacio: [null],
      acordMarcTransversal: [null],
      tipusBasatAcordMarcPermesos: [[]],
      tipusBasatSistemaDinamicAdquisicioPermesos: [[]],
      lotsAdjudicacio: [null],
      estatLotsAdjudicacio: [null],
      acordMarcOrigenId: [null],
      acordMarcOrigen: [{value: null, disabled: true}],
      dataNoOfertasGlic: [null],
      publicidad: [null],
      directivaAplicacion: [null],
      contractacioCentralitzada: [null]
    });
  }

  /**
   * Instanciates a new GeecFormGroup.
   * @param {LooseObject<any>} controlsConfig
   * @param {LooseObject<any>} extra
   * @returns {GeecFormGroup}
   */
  public group(controlsConfig: LooseObject<any>, extra: LooseObject<any> | null = null): GeecFormGroup {
    const controls = this._reduceControls(controlsConfig);
    const validator: ValidatorFn = extra != null ? extra['validator'] : null;
    const asyncValidator: AsyncValidatorFn = extra != null ? extra['asyncValidator'] : null;
    return new GeecFormGroup(controls, validator, asyncValidator);
  }

  public array(
    controlsConfig: any[], validator?: ValidatorFn | ValidatorFn[] | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
    createGroupFn?: () => GeecFormGroup): FormArray {
    const controls = controlsConfig.map(c => this._createControl(c));
    return new GeecFormArray(controls, validator, asyncValidator, createGroupFn);
  }

  /**
   * Instanciates a new GeecFormControl.
   * @param {Object} formState
   * @param {ValidatorFn | ValidatorFn[]} validator
   * @param {AsyncValidatorFn | AsyncValidatorFn[]} asyncValidator
   * @param {string[]} specificRequired
   * @returns {GeecFormControl}
   */
  public control(formState: Object,
                 validator?: ValidatorFn | ValidatorFn[] | null,
                 asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
                 specificRequired?: string[] | null): GeecFormControl {

    return new GeecFormControl(formState, validator, asyncValidator, specificRequired);
  }

  /** @internal */
  _reduceControls(controlsConfig: LooseObject<any>): LooseObject<AbstractControl> {
    const controls: LooseObject<AbstractControl> = {};
    Object.keys(controlsConfig).forEach(controlName => {
      controls[controlName] = this._createControl(controlsConfig[controlName]);
    });
    return controls;
  }

  /** @internal */
  _createControl(controlConfig: any): GeecFormControl {
    if (this._isExtendingAbstractControl(controlConfig)) {

      return controlConfig;

    } else if (Array.isArray(controlConfig)) {

      const value = controlConfig[0];
      const validator: ValidatorFn = controlConfig.length > 1 ? controlConfig[1] : null;
      const asyncValidator: AsyncValidatorFn = controlConfig.length > 2 ? controlConfig[2] : null;
      const specificRequired: string[] = controlConfig.length > 3 ? controlConfig[3] : null;

      return this.control(value, validator, asyncValidator, specificRequired);

    } else {

      return this.control(controlConfig);
    }
  }

  /** @internal */
  _isExtendingAbstractControl(control: any) {
    return control instanceof FormArray ||
      control instanceof GeecFormControl ||
      control instanceof GeecFormGroup;
  }

}
