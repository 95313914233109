import {OperationHandler} from "../../abstract/operation-handler";
import {Mapping} from "../../mapping";
import {CustomUtils} from "../../../../../../shared/utils/custom.utils";

export class InstantiationHandler extends OperationHandler {

  private readonly _constructorFunction: Function;

  constructor(mapping: Mapping) {
    super(mapping);
    this._constructorFunction = this.mapping.type.constructor;
  }

  public operate(target: object): PropertyDescriptor {

    const propDescriptor: PropertyDescriptor = {};

    if (this.mapping.type.params === 'SELF' && target === null) {
      propDescriptor.value = target;
    } else {
      propDescriptor.value = this._fillResultData(target);
    }

    return propDescriptor;

  }

  private _fillResultData(data: object): object {

    let _result: any;

    if (Array.isArray(data)) {

      _result = data.map((obj: any) => {
        return this._fillResultData(obj);
      });

    } else {

      let _args: any = this.mapping.type.params || [];
      if (_args === 'SELF') {
        _args = [data];
      }

      _result = this._objectHandler(Reflect.construct(this._constructorFunction, [..._args]), data);
    }

    return _result;
  }

  private _objectHandler(model: any, data: any): any {
    if (model !== null) {
      const properties: Array<string> = Object.keys(model);

      if (properties.length > 0) {
        properties.forEach((prop: string) => {
          if (model[prop] && Object.keys(model[prop]).length > 0) {
            this._objectHandler(model[prop], data[prop]);
          } else {
            if (CustomUtils.isDefined(data[prop])) {
              model[prop] = data[prop];
            }
          }
        });
      }

    } else {
      model = data;
    }

    return model;
  }

}
