import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {AppConstants} from "../../../app.constants";
import {FacturaDocumentsModel} from "../../models/factura/factura-documents.model";
import {DadesBasiquesFacturaModel} from "../../models/factura/dades-basiques-factura.model";
import {ControllerResponse, ResponseMessage} from "../../models/forms/controller-response.model";
import {Mapper} from "../../../core/decorators/mapper/mapper.decorator";
import {Mapping} from "../../../core/decorators/mapper/classes/mapping";
import {FacturaDocumentsConfig} from "../../standalone/components/factura-documents/factura-documents.component";
import {ExpedientNumeroModel} from "../../models/expedient/expedient-numero.model";
import {LooseObject} from "../../models/forms/reactive-form-validator";
import {Page} from "../../interfaces/paging/page";


@Injectable()
export class FacturaService {

  constructor(private http: HttpClient) {
  }

  @Mapper([
    new Mapping('dadesFactura.dataFactura', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('dadesFactura.dataRegistreFactura', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('dadesFactura.dataRegistreAdministratiu', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('dadesFactura.dataLliuramentServeiInici', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('dadesFactura.dataLliuramentServeiFi', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('dadesFactura.dataRebuig', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('facturesRegistrades.liniesFacturesRegistrades.dataDocumentFactura', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('facturesRegistrades.liniesFacturesRegistrades.dataComptabilitzacioDocumentFactura', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('facturesRegistrades.liniesFacturesRegistrades.dataPagamentFactura', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('facturesRegistrades.liniesFacturesRegistrades.dataRegistreFactura', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('facturesRegistrades.liniesFacturesRegistrades.dataLliuramentIniciFactura', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('facturesRegistrades.liniesFacturesRegistrades.dataLliuramentFiFactura', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('facturesRegistrades.liniesFacturesRegistrades.dataAcceptacioFactura', null, {
      constructor: Date,
      params: 'SELF'
    }),
  ])
  static parseFacturaModel(dadesBasiquesFactura: DadesBasiquesFacturaModel): DadesBasiquesFacturaModel {
    return dadesBasiquesFactura;
  }

  /**
   * @param {number} facturaGefactId
   * @returns {Observable<FacturaDocumentsModel>}
   */
  getDocumentsFactura(facturaGefactId: number): Observable<FacturaDocumentsModel> {
    return this.http.get<FacturaDocumentsModel>(`${AppConstants.urlFacturesGEFACT}/documents/${facturaGefactId}`);
  }

  /**
   * @param {number} idFactura
   * @returns {Observable<any>}
   */
  retornarFactura(idFactura: number): Observable<ControllerResponse<string>> {
    return this.http.get<ControllerResponse<string>>(`${AppConstants.urlFactures}/retornar/${idFactura}`);
  }

  reassignarFactura(idBustiaFactura: number, idUnitatPromotora: number): Observable<ControllerResponse<ResponseMessage>> {
    return this.http.post<ControllerResponse<ResponseMessage>>(`${AppConstants.urlFacturesGEFACT}/reassignar/${idBustiaFactura}`, {
      idUnitatPromotora
    });
  }

  rebutjarFactura(idBustiaFactura: number, idMotiu: number, motiuRebuig: string): Observable<ControllerResponse<ResponseMessage>> {
    return this.http.post<ControllerResponse<ResponseMessage>>(`${AppConstants.urlFacturesGEFACT}/rebutjar/${idBustiaFactura}`, {
      idMotiu, motiuRebuig
    });
  }

  veureDocument(idDocumentExtern: string, config: FacturaDocumentsConfig): void {
    if (config.download) {
      window.open(`${AppConstants.urlFacturesGEFACT}/documents/file/${idDocumentExtern}/${config.sistemaOrigen}/${config.facturaElectronica}?download=${config.download}`, '_blank');
    } else {
      window.open(`${AppConstants.urlFacturesGEFACT}/documents/file/${idDocumentExtern}/${config.sistemaOrigen}/${config.facturaElectronica}`, '_blank');
    }
  }

  veureAnnex(idAnnexExtern: string, sistemaOrigen: string, esElectronica: boolean): void {
    window.open(`${AppConstants.urlFacturesGEFACT}/documents/annex/${idAnnexExtern}/${sistemaOrigen}/${esElectronica}`, '_blank');
  }

  getOpcionsProcessar(idFactura: number): Observable<ExpedientNumeroModel[]> {
    return this.http.get<ExpedientNumeroModel[]>(`${AppConstants.urlFacturesGEFACT}/processar/${idFactura}`);
  }

  processarFactura(idBustiaFactura: number, idExpedient: number): Observable<ControllerResponse<ResponseMessage>> {
    const paramsMap: LooseObject<string> = {"idExpedient": idExpedient.toString()};
    const httpParams: HttpParams = new HttpParams({fromObject: paramsMap});

    return this.http.post<ControllerResponse<ResponseMessage>>(`${AppConstants.urlFacturesGEFACT}/processar/${idBustiaFactura}`, null, {params: httpParams});
  }

  getAllFactures(): Observable<Page<FacturaDocumentsModel>> {
    return this.http.get<Page<FacturaDocumentsModel>>(`${AppConstants.urlLlistats}/BustiaFacturesReport/executeQuery?page=0&size=20`);
  }

  editTipusCertificacio(idFactura: number, idTipusCertificacio: number, idExpedient: number): Observable<ControllerResponse<string>> {
    const paramsMap: LooseObject<string> = {"idTipusCertificacio": idTipusCertificacio.toString(), "idExpedient": idExpedient.toString()};
    const httpParams: HttpParams = new HttpParams({fromObject: paramsMap});
    return this.http.post<ControllerResponse<string>>(`${AppConstants.urlFactures}/editTipusCertificacio/${idFactura}`, null, {params: httpParams});
  }

  editDataRecepcioLiquidacio(idExpedient: number, idFacturaGEFACT: number, dataRecepcioDataLiquidacio: LooseObject<Date>): Observable<boolean> {
    return this.http.post<boolean>(`${AppConstants.urlFacturesGEFACT}/editDataRecepcioLiquidacio/${idExpedient}/${idFacturaGEFACT}`, dataRecepcioDataLiquidacio);
  }
}
