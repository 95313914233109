import {NumberInputComponent} from './number-input.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';



@NgModule({
  imports: [
    CommonModule,
    TooltipModule
  ],
  exports: [NumberInputComponent],
  declarations: [NumberInputComponent]
})
export class NumberInputModule {
}
