import {PropertyTree} from "./property-tree";

interface MappingType {
  constructor: Function;
  params?: Array<any> | 'SELF';
}

export enum MappingAction {
  Instantiation = 'INSTANTIATION',
  Conversion = 'CONVERSION',
  Mixed = 'MIXED'
}

export class Mapping {

  public target: PropertyTree;
  public source: PropertyTree;
  public minPath: PropertyTree;
  public type: MappingType;
  public action: MappingAction;
  public valid: boolean = true;

  constructor(target: string | 'SELF', source?: string, type?: MappingType)
  constructor(target: string | 'SELF', source: string, type: MappingType) {

    if (target !== 'SELF') {
      this.target = new PropertyTree(target);
    }

    if (source) {
      this.source = new PropertyTree(source);
      this.action = MappingAction.Conversion;
    }

    if (type) {
      this.type = type;
      this.action = MappingAction.Instantiation;

      if (this.source) {
        this.action = MappingAction.Mixed;
      }
    }

    if (!this.source && !this.type) {
      this.valid = false;
      throw new Error(`Mapping "${this.target.value}" instance needs either a source or a type. None provided.`);
    }
  }

  public backtrack(obj: object): void {
    this.minPath = this.target.backtrack(obj);

    if (!this.minPath || this._isRouteUndefined(obj)) {
      this.valid = false;
    }
  }

  private _isRouteUndefined(obj: object): boolean {
    let result: boolean = true;

    const minPathValue = obj[this.minPath.value];
    const fullRouteValue = obj[this.target.value];

    if (minPathValue && !fullRouteValue) {
      if (Array.isArray(minPathValue)) {
        result = false;
      }
    }

    return result;
  }

}
