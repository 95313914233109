import {Component, Input, OnInit} from '@angular/core';
import {GeecFormGroup} from "../../../core/directives/reactive-forms/geec-form-group";
import {CustomUtils} from "../../utils/custom.utils";
import {BehaviorSubject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'geec-criteris-cerca',
  templateUrl: './criteris-cerca.component.html',
  styleUrls: ['./criteris-cerca.component.scss']
})
export class CriterisCercaComponent implements OnInit {

  @Input() formGroup: GeecFormGroup;
  @Input() bsUpdateCriterisCerca: BehaviorSubject<null>;
  @Input() mapCriteris: Map<string, string>;

  textFiltresActual: string;

  constructor(private _translate: TranslateService) {
  }

  private traducir(tag: string) : string{
    let ruta = 'criteris-cerca';
    return this._translate.instant(ruta + '.' + tag);
  }

  ngOnInit() {
    this.bsUpdateCriterisCerca.subscribe(() => {
      if (CustomUtils.isDefined(this.mapCriteris)) {
        this._updateTextFiltresAplicats();
      }
    });
  }

  private _updateTextFiltresAplicats(): void {
    let textFiltres: string = "";
    let algunFiltre: boolean = false;
    Object.keys(this.formGroup.controls).forEach((key: string) => {
      let value: any = this.formGroup.get(key).value;
      if (CustomUtils.isDefined(value) && CustomUtils.isNotEmpty(value) && !CustomUtils.isArrayWithNullObjects(value)) {
        if (!textFiltres.includes(this.mapCriteris.get(key))) {
          textFiltres += this.mapCriteris.get(key) + " / ";
        }
        algunFiltre = true;
      }
    });

    if (algunFiltre) {
      textFiltres = textFiltres.substr(0, textFiltres.length - 3);
    } else {
      textFiltres += this.traducir('no-filtros');
    }
    this.textFiltresActual = textFiltres;
  }
}
