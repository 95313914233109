import {Component} from '@angular/core';
import {MessageService} from 'primeng/components/common/messageservice';
import {NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router, ActivatedRoute} from '@angular/router';
import {LoadingIndicatorService} from './core/services/loading-indicator/loading-indicator.service';
import {AuthService} from './shared/services/auth/auth.service';
import {CustomRouterReuseService} from './core/services/custom-router-reuse/custom-router-reuse.service';
import {Usuari} from "./shared/models/usuari/usuari.model";
import {ApplicationInitService} from "./application-init/application-init.service";
import {CustomUtils} from "./shared/utils/custom.utils";
import {EnvironmentUtils} from "../environments/environment.utils";
import { Title } from '@angular/platform-browser';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'geec-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})
export class AppComponent {

  menuActive: boolean = true;
  loading: boolean = false;

  constructor(private router: Router, private loadingIndicatorService: LoadingIndicatorService,
              private usuariService: AuthService, private customRouterReuseService: CustomRouterReuseService,
              private initService: ApplicationInitService, private titleService: Title, private activatedroute:ActivatedRoute, private _translate: TranslateService) {
    this.loadingIndicatorService.loading$.subscribe((loading) => {
      // TODO: Change this line for CustomUtils.nextTick
      setTimeout(() => {
        // hack to prevent ExpressionChangedAfterItHasBeenCheckedError
        this.loading = loading;
      });
    });




    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.customRouterReuseService.setCurrentRoute(window.location.pathname);
        CustomUtils.scrollToTop();

        // Sets the document title in the router events of type NavigationEnd
        this.setDocumentTitle();
      } else if (evt instanceof RouteConfigLoadStart) {
        this.loadingIndicatorService.onStartedLazyLoad(evt.route.path);
      } else if (evt instanceof RouteConfigLoadEnd) {
        this.loadingIndicatorService.onFinishedLazyLoad(evt.route.path);
      }
    });

    if (!this.initService.isBrowserSupported) {
      this.router.navigateByUrl("navegador-no-suportat");
    } else if (this.initService.isBrowserOutdated) {
      this.router.navigateByUrl("navegador-obsolet");
    }
  }

  get usuariActual(): Usuari {
    return this.usuariService.usuari;
  }

  onMenuButtonClick(): void {
    this.menuActive = !this.menuActive;
  }

  ngOnInit() {
    // Per entorns de PRE i PRO carrega conf script de appdynamics
    // Referència https://stackoverflow.com/questions/60088684/how-to-change-variable-value-in-index-html-based-on-the-environment-in-angular
    // Per versió més nova agular també es podria haver utilitzat https://stackoverflow.com/questions/59324204/how-to-replace-index-html-in-angular-8-3-depending-on-environment
    if (EnvironmentUtils.isPRE || EnvironmentUtils.isPRO) {
      // TODO: PEr ara s'ha posat hardcoded al index.html ja que sino no ho agafava bé.
      // Si acaba sent sistema definitiu es tindra que investigar com reactivar això.
      // this.loadAppDynamicsScript();
    }
  }

  private loadAppDynamicsScript() {
    const script = document.createElement('script');
    const tag = EnvironmentUtils.isPRE?'EC-AAB.HUW':'EC-AAB.HUW';
    script.type = 'text/javascript';
    script.innerHTML = `
        window['adrum-start-time']= new Date().getTime();
    (function(config) {
      config.appKey = '${tag}';
      config.adrumExtUrlHttp = 'http://cdn.appdynamics.com';
      config.adrumExtUrlHttps = 'https://cdn.appdynamics.com';
      config.beaconUrlHttp = 'http://fra-col.eum-appdynamics.com';
      config.beaconUrlHttps = 'https://fra-col.eum-appdynamics.com';
      config.xd = {enable : true};
      config.spa = {
        "spa2": true
      };
    })(window['adrum-config'] || (window['adrum-config'] = {}));`;

    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(script);
    }
    console.log("Appdynamics script loaded");
  }

  /**
   * Set the document title from routing titles
   */
  setDocumentTitle(): void {
    // Assign current route
    const rt = this.getCurrentRoute();

    // Gets data from current route
    rt.data.subscribe(data => {
      // Gets the translated values, the main one is the same for every route and then
      // the route specific title obtained from the route date
      this._translate.get(["title.main", data.title]).subscribe((res) => {
        // Sets the document title
        this.titleService.setTitle(res["title.main"] + " | " + res[data.title]);
      });
    });
  }

  getCurrentRoute():ActivatedRoute {
    return this.getRouteChild(this.activatedroute);
  }

  getRouteChild(activatedroute: ActivatedRoute):ActivatedRoute {
    if (activatedroute.firstChild) {
      return this.getRouteChild(activatedroute.firstChild);
    } else {
      return activatedroute;
    }

  }
}

