import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppConstants} from '../../../app.constants';
import {Observable} from 'rxjs';
import {ControllerResponse} from '../../models/forms/controller-response.model';
import {PortasignaturesFormModel} from "../../../documentacio/models/portasignatures-form.model";
import {LooseObject} from "../../models/forms/reactive-form-validator";
import {DocumentacioFormModel} from "../../../documentacio/models/documentacio-form.model";

@Injectable()
export class PortaSignaturesService {
  constructor(private http: HttpClient) {
  }

  enviarPortaSignatures(taskId: string, formDTO: Object): Observable<ControllerResponse<DocumentacioFormModel>> {
    return this.http.post<ControllerResponse<DocumentacioFormModel>>(`${AppConstants.urlPortaSignatures}/${taskId}`, formDTO);
  }

  getPortaSignaturesData(taskId: string): Observable<LooseObject<PortasignaturesFormModel>> {
    return this.http.get<LooseObject<PortasignaturesFormModel>>(`${AppConstants.urlPortaSignatures}/${taskId}`);
  }

  public actualitzarEstat(csv: string, taskId: string): Observable<ControllerResponse<DocumentacioFormModel>> {
    return this.http.get<ControllerResponse<DocumentacioFormModel>>(`${AppConstants.urlPortasignatures}/actualizar/peticion/${taskId}/${csv}`);
  }

}
