import {AbstractControl, AbstractControlOptions, AsyncValidatorFn, FormArray, ValidatorFn} from "@angular/forms";
import {GeecFormGroup} from "./geec-form-group";

export class GeecFormArray extends FormArray {

  // function to create the form groups automatically on setValue
  public createGroupFn: () => GeecFormGroup;

  constructor(
    public controls: AbstractControl[],
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
    createGroupFn?: () => GeecFormGroup) {
    super(controls, validatorOrOpts, asyncValidator);
    if (createGroupFn) {
      this.createGroupFn = createGroupFn;
    }
  }

}
