import {SelectItemData} from '../models/forms/geec-form-dto.model';

export interface TableColumn {
  header: string;
  field: string;
  width?: string;
  type?: TableColumnType;
  options?: SelectItemData[];
  metadata?: any;
  styleClass?: string;
}

export enum TableColumnType {
  TEXT = "TEXT",
  DECIMAL = "DECIMAL",
  INTEGER = "INTEGER",
  DATE = "DATE",
  TIMESTAMP = "TIMESTAMP",
  BOOLEAN = "BOOLEAN",
  BOOLEAN_ALT = "BOOLEAN_ALT",
  BOOLEAN_TEXT = "BOOLEAN_TEXT",
  SELECT = "SELECT",
  AUTOCOMPLETE = "AUTOCOMPLETE",
  URL = "URL",
  BOOLEAN_POSITIU_NEGATIU = "POSITIU_NEGATIU"
}
