import {LooseObject} from "../../shared/models/forms/reactive-form-validator";
import {TramitacioNodeAction} from "./model";
import {TramitacioNode} from "./tramitacio-node";

export class TramitacioDocumentSistemaExternNode extends TramitacioNode {


  constructor(backendJSON: LooseObject<any>) {
    super(backendJSON);
    this.icon = 'fa fa-file';
    this.selectable = true;
    this.actions.push({key: TramitacioNodeAction.obrirDocumentSistemaExtern, labelButton: 'Mostrar document', iconButton: 'fa fa-eye'});
  }

}
