import {Injectable} from '@angular/core';
import {AppConstants} from '../../../app.constants';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subject} from 'rxjs';
import {ControllerResponse} from '../../models/forms/controller-response.model';
import {PermisUsuari, Usuari} from '../../models/usuari/usuari.model';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {tap, timeout} from "rxjs/operators";
import {CustomUtils} from '../../utils/custom.utils';
import {NoticeBoardService} from "../../../core/services/notice-board/notice-board.service";
import {RolsUnitatModel} from "../../models/usuari/rols-unitat.model";
import {UnitatsEnum} from "../../../core/services/unitat/unitat.service";
import {CriterioSubjetivoFormValidatorModel} from '../../standalone/components/criterio-subjetivo/models/criterio-subjetivo-form-validator.model';
import {CriterioObjetivoFormValidatorModel} from '../../standalone/components/criterio-objetivo/models/criterio-objetivo-form-validator.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /** Flag to prevent multiple calls when using multiples guards that require the current user. */
  private _loading: boolean = false;
  /** The current user. */
  private _currentUsuari: Usuari;
  /** Subject to subscribe when waiting for current user request to complete. */
  private _currentUsuari$: Subject<Usuari> = new Subject<Usuari>();

  private _currentCriterioSubjetivo: CriterioSubjetivoFormValidatorModel;
  private _currentCriterioObjetivo: CriterioObjetivoFormValidatorModel;

  get usuari(): Usuari {
    return this._currentUsuari;
  }

  get criterioSubjetivo(): CriterioSubjetivoFormValidatorModel {
    return this._currentCriterioSubjetivo;
  }

  get criterioObjetivo(): CriterioObjetivoFormValidatorModel {
    return this._currentCriterioObjetivo;
  }

  get currentUsuari$(): Observable<Usuari> {
    return this._currentUsuari$.asObservable();
  }

  constructor(private http: HttpClient, private router: Router, private noticeBoardService: NoticeBoardService) {
  }

  public getCurrentUsuari(config: { load: boolean } = {load: true}): Observable<Usuari> {
    if (this.usuari) {
      return of(this.usuari);
    } else {
      if (config.load && !this._loading) {
        this._loading = true;
        return this.http.get<Usuari>(`${AppConstants.urlGetLogin}/current`).pipe(tap((usuari: Usuari) => {
          // Propagate the new user to the subscribed components.
          if (usuari == null) {
            if (environment.loginConSSO) {
              window.location.href = "/gestorb/login";
            } else {
              window.location.href = "/login";
            }
          } else {
            this._setCurrentUsuari(usuari);
            if (usuari.hasUrgentNotices) {
              this.noticeBoardService.openNoticeBoard(true);
            }
          }
        }, () => {
        }, () => this._loading = false), timeout(1000000));
      } else {
        return this.currentUsuari$;
      }
    }
  }

  getUsersNif(): Observable<Usuari[]> {
    return this.http.get<Usuari[]>(`${AppConstants.urlGetAdmin}/users`);
  }

  getAdminUser(): Observable<Usuari> {
    return this.http.get<Usuari>(`${AppConstants.urlGetAdmin}/user`);
  }

  login(nif: string): Observable<ControllerResponse<string>> {
    return this.http.post<ControllerResponse<string>>(`${AppConstants.urlGetAdmin}/login/${nif}`, null);
  }

  logout() {
    if (environment.loginConSSO) {
      window.location.href = environment.urlBaseLogout + encodeURIComponent(AppConstants.logoutURLBack);
    } else {
      return this.http.post<any>(`${AppConstants.urlGetAdmin}/logout`, null).subscribe(() => {
        this._setCurrentUsuari(null);
        this.router.navigate(['/login']);
      });
    }
  }

  usuariTePermisos(permisos: PermisUsuari[], onlyOneNeeded: boolean = false): boolean {
    if (this.usuari && CustomUtils.HOPAID(this.usuari, 'permisos')) {
      if (onlyOneNeeded) {
        return permisos.some((permis: PermisUsuari) => this.usuari.permisos.indexOf(permis) >= 0);
      } else {
        return permisos.every((permis: PermisUsuari) => this.usuari.permisos.indexOf(permis) >= 0);
      }
    }
    return false;
  }

  usuariIncasol(): boolean {
    return this.usuari && this.usuari.rolsUnitat.some((rolsUnitat: RolsUnitatModel) => {
      return rolsUnitat.unitat.id === UnitatsEnum.ICSOL;
    });
  }

  private _setCurrentUsuari(usuari: Usuari) {
    this._currentUsuari = usuari;
    this._currentUsuari$.next(this._currentUsuari);
  }

  loginAlternativo(nif: string) {
    return this.http.post(`${AppConstants.urlGetAdminToken}/login/${nif}`, '', {responseType: 'text'});
  }

  loginToken(token: string) {
    return this.http.post(`${AppConstants.urlGetAdminToken}/loginAlternativo/${token}`, '', {responseType: 'text'});
  }
}
