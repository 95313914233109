import {TreeNode} from "primeng/api";
import {CustomUtils} from "../../utils/custom.utils";
import {UnitatTreeModel} from "../../../core/services/unitat/unitat.service";

export class UnitatTreeNode implements TreeNode {
  children: UnitatTreeNode[];
  collapsedIcon: any;
  data: UnitatTreeModel;
  draggable: boolean;
  droppable: boolean;
  expanded: boolean;
  expandedIcon: any;
  icon: any;
  label: string;
  leaf: boolean;
  parent: TreeNode;
  partialSelected: boolean;
  selectable: boolean;
  styleClass: string;
  type: string;

  constructor(unitat: UnitatTreeModel, children: Array<UnitatTreeNode>, selectableNode: boolean, isTaskPage: boolean) {
    this.children = children;
    this.leaf = !CustomUtils.isArrayNotEmpty(children);
    this.label = unitat.nom;
    this.data = unitat;
    this.expandedIcon = this.leaf ? "fa fa-file-o" : "fa fa-folder-open";
    this.collapsedIcon = this.leaf ? "fa fa-file-o" : "fa fa-folder";
    // prevent node selection if unitat is inactive in tasks
    this.selectable = selectableNode && (!isTaskPage || (isTaskPage && unitat.isActive));
  }

}
