import {TramitacioNode} from "./tramitacio-node";
import {LooseObject} from "../../shared/models/forms/reactive-form-validator";
import {EstatManament} from "./model";

export class TramitacioManamentNode extends TramitacioNode {

  public estat: EstatManament;

  constructor(backendJSON: LooseObject<any>) {
    super(backendJSON);
    this.icon = 'fa fa-money';
    this.estat = backendJSON.estat;
    switch (this.estat) {
      case EstatManament.COMPTABILITZAT:
        this.styleClass = "complete";
        break;
      case EstatManament.SUPRIMIT:
        this.styleClass = "document-anulat";
        break;
      default:
        this.styleClass = "assigned";
        break;
    }
  }
}
