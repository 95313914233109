import {AsyncValidatorFn, FormControl, ValidatorFn, Validators} from "@angular/forms";
import {LooseObject} from "../../../shared/models/forms/reactive-form-validator";
import {GeecFormGroup} from "./geec-form-group";
import {coerceToValidator} from "./shared";
import {GeecValidators} from "../../../shared/validators/geec-validators";
import {BehaviorSubject} from "rxjs";
import {AbstractControlOptions} from '@angular/forms/src/model';
import {FormField, ValuesField} from "../../../shared/models/forms/geec-form-dto.model";
import {CustomUtils} from "../../../shared/utils/custom.utils";
import {HelpText} from "../../../shared/components/help-button/models/help-text.model";

interface SetValueOptions {
  onlySelf?: boolean;
  emitEvent?: boolean;
  emitModelToViewChange?: boolean;
  emitViewToModelChange?: boolean;
}

type ValidatorFnExtractor = (...args: Array<any>) => ValidatorFn;

export class GeecFormControl extends FormControl {

  public specificRequired: string[] | null;
  /** @internal **/
  private _validatorsMap: LooseObject<GeecValidators> = {
    "MaxLengthValidator": Validators.maxLength,
    "MinLengthValidator": Validators.minLength,
    "RequiredValidator": Validators.required,
    "RangeValidator": GeecValidators.range,
    "NifValidator": GeecValidators.nif,
    "YearValidator": GeecValidators.year,
    "NoWhitespaceRequiredValidator": GeecValidators.noWhitespaceRequired,
    "PostalCodeValidator": GeecValidators.postalCode,
    "MinArray": GeecValidators.minArray,
    "MinLowercaseValidator": GeecValidators.minLowercase,
    "EmailValidator": Validators.email,
    "NoWhitespaceValidator": GeecValidators.noWhitespaces,
    "NoTrailingOrLeadingWhitespaceValidator": GeecValidators.initEndNoWhitespace
  };

  constructor(formState: any = null,
              validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
              asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
              specificRequired?: string[] | null) {

    super(formState, validatorOrOpts, asyncValidator);
    this.specificRequired = specificRequired;
  }

  private _helpText: BehaviorSubject<HelpText> = new BehaviorSubject<HelpText>(null);

  get helpText(): HelpText {
    return this._helpText.getValue();
  }

  set helpText(value: HelpText) {
    this._helpText.next(value);
  }

  get helpText$(): BehaviorSubject<HelpText> {
    return this._helpText;
  }

  private static _hasToOverrideValue(values: Array<ValuesField>): boolean {
    // value has changed in server, update control
    return CustomUtils.isArrayNotEmpty(values) && CustomUtils.isDefined(values[0].value);
  }

  public setValue(value: any, options: SetValueOptions = {}): void {
    super.setValue.apply(this, arguments);
    (this.parent as GeecFormGroup).updateSpecificRequiredStructure();
  }

  public update(validator: FormField): void {

    this.validator = null;
    if (CustomUtils.HOPAID(validator, 'validations')) {
      validator.validations.forEach(item => {
        let _validator: ValidatorFn;
        if (item.parameters === undefined || item.parameters == null) {
          item.parameters = [];
        }
        if (item.parameters.length > 0) {
          let _fn: ValidatorFnExtractor = <ValidatorFnExtractor>this._validatorsMap[item.validation];
          if (_fn) {
            _validator = _fn(...item.parameters);
          }
        } else {
          _validator = <ValidatorFn>this._validatorsMap[item.validation];
        }
        if (_validator) {
          this.addValidators(_validator);
        }
      });
    }

    if (CustomUtils.isDefined(validator.helpText)) {
      this.helpText = validator.helpText;
    }

    if (validator.mandatoryToSave) {
      this.setSpecificRequired(['save']);
    }

    if (!validator.visible || !validator.enabled) {
      this.disable({onlySelf: true, emitEvent: false});
    } else {
      this.enable({onlySelf: true, emitEvent: false});
    }

    if (GeecFormControl._hasToOverrideValue(validator.values)) {
      this.setValue(validator.values[0].value);
    } else if (this._hasDefaultValue(validator)) {
      this.setValue(validator.defaultValue);
    } else {
      this.updateValueAndValidity();
    }

  }

  public setSpecificRequired(specificArr: string[]): void {
    this.specificRequired = specificArr;
    (this.parent as GeecFormGroup).updateSpecificRequiredStructure();
  }

  public isEmptyInputValue(): boolean {
    return this.value == null || this.value.length === 0;
  }

  public addValidators(newValidator: ValidatorFn | ValidatorFn[] | null): void {
    this.validator = Validators.compose([this.validator, coerceToValidator(newValidator)]);
  }

  private _hasDefaultValue(validator: FormField): boolean {
    return CustomUtils.isDefined(validator.defaultValue) &&
      ((CustomUtils.isUndefinedOrNull(this.value) || (this.value === "")) && (!this.dirty || validator.mandatory));
  }

}
