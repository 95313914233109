export enum TipusIva {
  IVA_N0 = 0,
  IVA_N4 = 3
}

export class TipusIvaModel {
  id: number;
  nom: string;
  value: number;
  deduible: boolean;
  iniciVigencia: Date;
  fiVigencia: Date;
}
