import {Component, OnInit} from '@angular/core';
import {NoticeService, OfflineNotice} from "../../services/notice-board/notice.service";

@Component({
  selector: 'geec-unavailable-app',
  templateUrl: './unavailable-app.component.html'
})
export class UnavailableAppComponent implements OnInit {

  public offlineNotice: OfflineNotice;

  constructor(private noticeService: NoticeService) {
  }

  ngOnInit(): void {
    this.noticeService.getOfflineNotice().subscribe((notice: OfflineNotice) => {
      this.offlineNotice = notice;
    });
  }

}
