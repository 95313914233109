import {Injectable} from "@angular/core";
import {GeecFormBuilder} from "../../../../core/directives/reactive-forms/geec-form-builder";
import {GeecFormGroup} from "../../../../core/directives/reactive-forms/geec-form-group";

@Injectable()
export class DatesContracteService {

  constructor(private _gfb: GeecFormBuilder) {
  }

  buildForm(): GeecFormGroup {
    return this._gfb.group({
      acordOrgan: [null],
      dataAcordOrgan: [null],
      dataAdjudicacio: [null],
      dataFormalitzacio: [null],
      dataFiscalitzacio: [null]
    });
  }

  buildFormNm(): GeecFormGroup {
    return this._gfb.group({
      acordOrgan: [null],
      dataAcordOrgan: [null],
      dataDesert: [null],
      dataAdjudicacio: [null],
      dataFormalitzacio: [null],
      dataIniciComput: [null],
      dataIncoacio: [null],
      dataFiscalitzacio: [null],
      dataAprovExpedient: [null],
      dataNotificacio: [null],
      dataAcordExecucio: [null],
      dataInvitacioLicitadors: [null]
    });
  }
}
