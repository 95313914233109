import {MapHandler} from "../classes/abstract/map-handler";
import {Observable} from "rxjs";
import {ObservableMapHandler} from "../classes/handlers/observable-map.handler";
import {DefaultMapHandler} from "../classes/handlers/default-map.handler";

export class MapHandlerFactory {
  public static new(target: any): MapHandler {
    switch (target.constructor) {
      case Observable:
        return new ObservableMapHandler(target, MapHandlerFactory.new);
      default:
        return new DefaultMapHandler(target);
    }
  }
}
