import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs/internal/Observable";
import {DefinicioIntegracionsFrontDTO} from "../../models/definicio-integracions.model";
import {Injectable} from "@angular/core";
import {AppConstants} from "../../../app.constants";

@Injectable()
export class DefinicioIntegracionsService {

  constructor(private http: HttpClient) {
  }

  /**
   * Gets all integracio enums
   * @returns {Observable<Array<DefinicioIntegracionsFrontDTO>>}
   */
  public getAll(): Observable<Array<DefinicioIntegracionsFrontDTO>> {
    return this.http.get<Array<DefinicioIntegracionsFrontDTO>>(AppConstants.urlDefinicioIntegracions);
  }

  /**
   * Gets all estats enum or filters them by a string
   * @param {string} text
   * @returns {Observable<Array<DefinicioIntegracionsFrontDTO>>}
   */
  public getEstats(text: string): Observable<Array<DefinicioIntegracionsFrontDTO>> {
    let params: HttpParams = new HttpParams({fromObject: {like: text}});
    return this.http.get<Array<DefinicioIntegracionsFrontDTO>>(`${AppConstants.urlDefinicioIntegracions}/estat`, {params: params});
  }

  /**
   * Gets all service enum or filters them by a string
   * @param {string} text
   * @returns {Observable<Array<DefinicioIntegracionsFrontDTO>>}
   */
  public getServeis(text: string): Observable<Array<DefinicioIntegracionsFrontDTO>> {
    let params: HttpParams = new HttpParams({fromObject: {like: text}});
    return this.http.get<Array<DefinicioIntegracionsFrontDTO>>(`${AppConstants.urlDefinicioIntegracions}/service`, {params: params});
  }
}
