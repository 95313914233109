import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CustomRouterReuseService} from '../../../core/services/custom-router-reuse/custom-router-reuse.service';
import {TascaService} from '../../../core/services/tasca/tasca.service';
import {DocumentsOpcionalsSharedFormValidatorModel} from "../../models/forms/documents-opcionals/documents-opcionals-form-validator.model";
import {ExecuteChildMethod} from '../../services/utils/common';
import {CustomUtils} from '../../utils/custom.utils';
import {HttpErrorResponse} from '@angular/common/http';
import {ControllerResponse} from '../../models/forms/controller-response.model';
import {Panel} from "primeng/panel";
import {Tasca} from "../../models/bustia/tasca.model";
import {CreateDocumentOpcionalFrontDTO} from "../../../documentacio/documentacio.service";
import {ReactiveFormValidator} from "../../models/forms/reactive-form-validator";
import {DocumentService} from "../../services/document/document.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'geec-documents-opcionals',
  templateUrl: './documents-opcionals.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsOpcionalsComponent implements OnChanges {

  @Input() formValidators: ReactiveFormValidator | any;

  @Input() noSave: boolean = false;

  @Input() tasca: Tasca;

  @Input() titol: string = this.traducir('titulo-defecto');

  @Output() onNewDocumentOpcional: EventEmitter<ExecuteChildMethod>;

  @Output() onError: EventEmitter<HttpErrorResponse>;

  @ViewChild('panelDocsOpcionals')
  public panelDocsOpcionals: Panel;

  selectedTipusDocument: number;

  public templateValidators: DocumentsOpcionalsSharedFormValidatorModel;

  constructor(private router: Router, private bustiaService: TascaService, private route: ActivatedRoute,
              private customRouterReuseService: CustomRouterReuseService, private documentService: DocumentService,
              private _translate: TranslateService) {
    this.onNewDocumentOpcional = new EventEmitter<ExecuteChildMethod>();
    this.onError = new EventEmitter<HttpErrorResponse>();
  }

  private traducir(tag: string) : string{
    let ruta = 'documents-opcionals';
    return this._translate.instant(ruta + '.' + tag);
  }

  // TODO: Passar a reactive model
  ngOnChanges(changes) {
    if (changes.hasOwnProperty('formValidators') && CustomUtils.isDefined(changes.formValidators)) {
      if (CustomUtils.isDefined(this.formValidators) && this.formValidators.hasOwnProperty('documentsOpcionals')) {
        this.templateValidators = this.formValidators['documentsOpcionals'];
      }
    }
  }

  addDocumentOpcional(): void {
    if (this.noSave) {
      this.openDocumentOpcional();
    } else {
      this.onNewDocumentOpcional.emit(<ExecuteChildMethod>{
        component: "documentsOpcionalsComponent",
        method: "openDocumentOpcional"
      });
    }
  }

  openDocumentOpcional(): void {
    this.documentService.createTascaDocumentOpcional(this.selectedTipusDocument, this.route.snapshot.params['taskId']).subscribe((res: ControllerResponse<CreateDocumentOpcionalFrontDTO>) => {
        const routeCommands: any[] = this.customRouterReuseService.prepareToNavigate(
          ["/documentacio",
            this.route.snapshot.params['idTaskable'],
            res.data['tascaFrontDTO'].id
          ], this.tasca);
        this.bustiaService.selectTasca(res.data['tascaFrontDTO']);
        this.router.navigate(routeCommands);
      },
      (err: HttpErrorResponse) => {
        this.onError.emit(err);
      });
  }

  expandOrCollapse(event: Event): void {
    if (this.panelDocsOpcionals.collapsed) {
      this.panelDocsOpcionals.expand(event);
    } else {
      this.panelDocsOpcionals.collapse(event);
    }
  }
}
