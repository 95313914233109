import {Mapping, MappingAction} from "../classes/mapping";
import {OperationHandler} from "../classes/abstract/operation-handler";
import {InstantiationHandler} from "../classes/handlers/operation/instantiation.handler";
import {ConversionHandler} from "../classes/handlers/operation/conversion.handler";
import {MixedHandler} from "../classes/handlers/operation/mixed.handler";

export class OperationHandlerFactory {
  public static new(mapping: Mapping): OperationHandler {
    switch (mapping.action) {
      case MappingAction.Instantiation:
        return new InstantiationHandler(mapping);
      case MappingAction.Conversion:
        return new ConversionHandler(mapping);
      case MappingAction.Mixed:
        return new MixedHandler(mapping);
      default:
        return null;
    }
  }
}
