import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {Tasca} from '../../../shared/models/bustia/tasca.model';
import {CustomUtils} from '../../../shared/utils/custom.utils';
import {ActivatedRouteSnapshot} from "@angular/router";

export interface NavigateBackConfig {
  route: any[];
  tasca: Tasca;
}

interface StoredRoute {
  route: string;
  componentName: string;
  tasca: Tasca;
  data: any;
  isStaticComponent: boolean;
}

@Injectable()
export class CustomRouterReuseService {

  // metadata from last reused route
  retrievedRouteData: any = null;

  // saved routes
  public savedRoutes: StoredRoute[] = [];

  // when navigating with nextAndNew the current route will not be stored and the previous ones will not be cleared
  public nextAndNew: boolean = false;

  private hasRoutesStored: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private saveNextComponent: boolean = false;
  private retrieveNextComponent: boolean = false;

  private currentRoute: string;

  private tascaToStore: Tasca = null;
  private dataToStore: any = null;

  constructor() {
  }

  get routesStored(): Observable<boolean> {
    return this.hasRoutesStored.asObservable();
  }

  get isRouteBeingReused(): boolean {
    return this.retrieveNextComponent;
  }

  goBack(): NavigateBackConfig {
    let navigateConfig: NavigateBackConfig = null;
    if (CustomUtils.isArrayNotEmpty(this.savedRoutes)) {
      this.markComponentToRetrieve(true);
      const savedRoute: StoredRoute = this.savedRoutes[this.savedRoutes.length - 1];
      navigateConfig = {
        route: [savedRoute.route],
        tasca: savedRoute.tasca
      };
    }
    return navigateConfig;
  }

  prepareToNavigate(commands: any[], tasca: Tasca = null, data: any = null): any[] {
    this.markComponentToSave(true);
    this.dataToStore = data;
    this.tascaToStore = tasca;
    return commands;
  }

  setCurrentRoute(route: string): void {
    this.currentRoute = route.replace("/gestorf", "");
  }

  getCurrentRoute(): string {
    return this.currentRoute;
  }

  markComponentToSave(save: boolean): void {
    this.saveNextComponent = save;
  }

  markComponentToRetrieve(retrieve: boolean): void {
    this.retrieveNextComponent = retrieve;
  }

  componentMustBeSaved(route: ActivatedRouteSnapshot): boolean {
    return this.saveNextComponent || this.isStaticComponent(route);
  }

  componentMustBeRetrieved(route: ActivatedRouteSnapshot): boolean {
    return this.retrieveNextComponent || this.isStaticComponent(route);
  }

  isNormalNavigation(route: ActivatedRouteSnapshot): boolean {
    return (!this.componentMustBeRetrieved(route) && !this.componentMustBeSaved(route) && !this.nextAndNew);
  }

  clearStoredRoutes(): void {
    this.savedRoutes = this.savedRoutes.filter((storedRoute: StoredRoute) => storedRoute.isStaticComponent);
    this.retrievedRouteData = null;
    this.updateHasRoutesStored();
  }

  addRouteToQueue(route: string, componentName: string, isStaticComponent: boolean) {
    this.savedRoutes.push({
      route: route,
      componentName: componentName,
      tasca: this.tascaToStore,
      data: this.dataToStore,
      isStaticComponent: isStaticComponent
    });
    this.tascaToStore = null;
    this.dataToStore = null;
    this.updateHasRoutesStored();
  }

  popRouteFromQueue() {
    let route: StoredRoute = this.savedRoutes.pop();
    this.retrievedRouteData = route.data;
    this.updateHasRoutesStored();
  }

  isStaticComponent(route: ActivatedRouteSnapshot): boolean {
    return (route && CustomUtils.HOPAID(route, ['data', 'isStaticComponent'], {returnValue: true}));
  }

  private updateHasRoutesStored() {
    let hasRoutesStored: boolean = false;
    if (Array.isArray(this.savedRoutes)) {
      hasRoutesStored = (this.savedRoutes.findIndex((storedRoute: StoredRoute) => !storedRoute.isStaticComponent) !== -1);
    }
    this.hasRoutesStored.next(hasRoutesStored);
  }

}
