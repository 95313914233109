import {Pipe, PipeTransform} from '@angular/core';
import {CustomUtils} from "../../utils/custom.utils";

@Pipe({
  name: 'arrayFirst',
  pure: true
})
export class ArrayFirstPipe implements PipeTransform {

  transform(value: any, fieldLabel?: string): any {
    if (CustomUtils.isArrayNotEmpty(value)) {
      return fieldLabel ? value[0][fieldLabel] || null : value[0];
    }
    return null;
  }

}
