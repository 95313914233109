import {Injectable} from "@angular/core";
import {SelectItem} from "primeng/api";
import {DadaMestra, DadaMestraService, DadaMestraType} from "../../../services/dada-mestra/dada-mestra.service";
import {CustomUtils} from "../../../utils/custom.utils";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ContratacionEstrategica} from "../../../models/indicadors/contratacion-estrategica";

@Injectable({
  providedIn: 'root'
})
export class DadesExecucioControllerService {

  constructor(private _dadaMestraService: DadaMestraService) {
  }

  public getMotivoNoAdjudicado(): Observable<SelectItem[]> {
    return this._dadaMestraService.getOptions(DadaMestraType.MOTIVO_NO_ADJUDICADO).pipe(map((data: DadaMestra[]) => {
      return CustomUtils.toSelectItemArray(data);
    }, (err: any) => console.error(err)));
  }
}
