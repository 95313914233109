import {Injectable} from '@angular/core';
import {TableColumn, TableColumnType} from "../../interfaces/table-column";
import {GeecFormGroup} from "../../../core/directives/reactive-forms/geec-form-group";
import {GeecFormBuilder} from "../../../core/directives/reactive-forms/geec-form-builder";
import {PartidesOfertesFormValidationModel} from "../../standalone/components/partides/models/partides-ofertes-form-validator.model";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class PartidesOfertesService {

  traducciones: any = {};
  constructor(private _gfb: GeecFormBuilder,
              private _translate: TranslateService) {
    this.traducirColumnas();
    this._translate.onLangChange.subscribe(() =>{
      this.traducirColumnas();
    })
  }

  private traducir(tag: string) : string{
    let ruta = 'partides-ofertes';
    return this._translate.instant(ruta + '.' + tag);
  }

  private traducirColumnas() {
    this.traducciones = {
      partidaPressupostaria: this.traducir('column-partida-presupostaria'),
      centreCost: this.traducir('column-centro-coste'),
      financamentAfectat: this.traducir('column-financiamiento-afectado'),
      any: this.traducir('column-anyo'),
      tipusIva: this.traducir('column-tipo-IVA'),
      importAdjudicacio: this.traducir('column-importe-adjudicacion'),
      importAdjudicacioIva: this.traducir('column-importe-adjudicacion-IVA'),
      importLicitacio: this.traducir('column-importe-licitacion'),
      importLicitacioIva: this.traducir('column-importe-licitacion-IVA'),
      oferta: this.traducir('column-oferta'),
      nomAdjudicatari: this.traducir('column-nombre-adjudicatario'),
      nifAdjudicatari: this.traducir('column-nif-adjudicatario'),
      codiPEP: this.traducir('column-codigo-PEP'),
      codiTerritori: this.traducir('column-codigo-territorio')
    };
  }

  public buildForm(): GeecFormGroup {
    return this._gfb.group({
      id: [null],
      partidaPressupostaria: [null],
      centreGestor: [null],
      financamentAfectat: [null],
      any: [null],
      tipusIva: [null],
      importAdjudicacio: [null],
      importAdjudicacioIva: [null],
      importLicitacio: [null],
      importLicitacioIva: [null],
      oferta: [null],
      nomAdjudicatari: [null],
      nifAdjudicatari: [null],
      codiPEP: [null],
      codiTerritori: [null]
    });
  }

  public getColumns(templateValidations: PartidesOfertesFormValidationModel): TableColumn[] {
    const columns = [
      {field: "partidaPressupostaria", header: this.traducciones.partidaPressupostaria, width: "15em", type: TableColumnType.TEXT},
      {field: "centreGestor", header: this.traducciones.centreCost, width: "10em", type: TableColumnType.TEXT},
      {field: "financamentAfectat", header: this.traducciones.financamentAfectat, width: "16em", type: TableColumnType.TEXT},
      {field: "any", header: this.traducciones.any, width: "5em", type: TableColumnType.INTEGER},
      {field: "tipusIva", header: this.traducciones.tipusIva, width: "13em", type: TableColumnType.SELECT},
      {field: "importAdjudicacio", header: this.traducciones.importAdjudicacio, width: "12em", type: TableColumnType.DECIMAL},
      {field: "importAdjudicacioIva", header: this.traducciones.importAdjudicacioIva, width: "16em", type: TableColumnType.DECIMAL},
      {field: "importLicitacio", header: this.traducciones.importLicitacio, width: "12em", type: TableColumnType.DECIMAL},
      {field: "importLicitacioIva", header: this.traducciones.importLicitacioIva, width: "16em", type: TableColumnType.DECIMAL},
      {field: "oferta", header: this.traducciones.oferta, width: "8em", type: TableColumnType.TEXT},
      {field: "nomAdjudicatari", header: this.traducciones.nomAdjudicatari, width: "11em", type: TableColumnType.TEXT},
      {field: "nifAdjudicatari", header: this.traducciones.nifAdjudicatari, width: "11em", type: TableColumnType.TEXT},
      {field: "codiPEP", header: this.traducciones.codiPEP, width: "8em", type: TableColumnType.TEXT},
      {field: "codiTerritori", header: this.traducciones.codiTerritori, width: "10em", type: TableColumnType.TEXT},
    ];

    return columns.filter((column: TableColumn) => templateValidations[column.field] && templateValidations[column.field].visible);
  }
}
