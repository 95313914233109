import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'geec-unsupported-browser',
  templateUrl: './unsupported-browser.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnsupportedBrowserComponent {

}
