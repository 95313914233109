/* tslint:disable:component-selector no-access-missing-member use-host-property-decorator*/
import {ChangeDetectorRef, Component, ElementRef, forwardRef, Input, IterableDiffers, Renderer2} from "@angular/core";
import {ObjectUtils} from "primeng/components/utils/objectutils";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {CustomUtils} from "../../utils/custom.utils";
import {DomHandler} from 'primeng/api';
import {AutoComplete} from 'primeng/autocomplete';

export const AUTOCOMPLETE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AutocompleteComponent),
  multi: true
};

@Component({
  selector: 'p-autoComplete',
  templateUrl: './autocomplete.component.html',
  providers: [DomHandler, ObjectUtils, AUTOCOMPLETE_VALUE_ACCESSOR]
})
export class AutocompleteComponent extends AutoComplete {

  @Input() valueField: string;

  constructor(public el: ElementRef, public domHandler: DomHandler, public renderer: Renderer2,
              public objectUtils: ObjectUtils, public cd: ChangeDetectorRef, public differs: IterableDiffers) {

    super(el, domHandler, renderer, objectUtils, cd, differs);
  }

  /**
   * @override
   * Handles click in dropdown button.
   * @param {Event} event
   */
  public handleDropdownClick(event: Event): void {
    if (this.panelVisible) {
      this.hide();
    } else {
      this.clearValue();
      super.handleDropdownClick(event);
    }
  }

  selectItem(option: any, focus: boolean = true): void {
    if (this.multiple) {
      this.multiInputEL.nativeElement.value = '';
      this.value = this.value || [];
      if (!this.isSelected(option)) {
        this.value = [...this.value, this.valueField ? this.objectUtils.resolveFieldData(option, this.valueField) : option];
        this.onModelChange(this.value);
      }
    } else {
      this.inputEL.nativeElement.value = this.field ? this.objectUtils.resolveFieldData(option, this.field) || '' : option;
      this.value = this.valueField ? this.objectUtils.resolveFieldData(option, this.valueField) : option;
      this.onModelChange(this.value);
    }

    this.onSelect.emit(option);
    this.updateFilledState();

    if (focus) {
      this.focusInput();
    }
  }

  updateInputField(): void {
    if (!this.valueField || !this.field || !CustomUtils.isArrayNotEmpty(this.suggestions)) {
      super.updateInputField();
    } else {
      let option: any = this.suggestions.find(sug => this.objectUtils.resolveFieldData(sug, this.valueField) === this.value);
      let formattedValue: string = this.objectUtils.resolveFieldData(option, this.field);

      this.inputFieldValue = formattedValue;

      if (this.inputEL && this.inputEL.nativeElement) {
        this.inputEL.nativeElement.value = formattedValue;
      }
      this.updateFilledState();
    }
  }

  onInputChange(event: Event): void {
    if (this.forceSelection && !this.suggestions) {
      if (this.multiple) {
        this.multiInputEL.nativeElement.value = '';
      } else {
        this.value = null;
        this.inputEL.nativeElement.value = '';
      }
      this.onModelChange(this.value);
    } else {
      super.onInputChange(event);
    }
  }

  clearValue(): void {
    if (this.multiple) {
      this.multiInputEL.nativeElement.value = '';
    } else {
      this.value = null;
      this.inputEL.nativeElement.value = '';
    }
    this.onClear.emit(event);
    this.onModelChange(this.value);
  }

}
