import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AppConstants} from '../../../app.constants';
import {AutoCompleteConfig} from '../utils/auto-complete-config';
import {CustomUtils} from '../../utils/custom.utils';
import {Observable} from 'rxjs';
import {SelectOptionCodiModel} from '../../models/select-option-codi.model';
import {LooseObject} from "../../models/forms/reactive-form-validator";
import {Ecofin} from "../../models/expedient/expedient.model";

@Injectable()
export class CentreGestorService {

  constructor(private http: HttpClient) {
  }

  getCentre(id: number): Observable<SelectOptionCodiModel> {
    return this.http.get<SelectOptionCodiModel>(AppConstants.urlGetCentresGestors + "/" + id);
  }

  getCentresGestors(searchText: string, integracioOrigenId?: Ecofin): Observable<SelectOptionCodiModel[]> {
    let config: AutoCompleteConfig = {
      page: 0,
      size: 20,
      loading: false
    };

    let paramsMap: LooseObject<string> = {};
    if (searchText) {
      paramsMap["like"] = searchText;
    }
    if (config) {
      paramsMap = AutoCompleteConfig.loadConfig(paramsMap, config);
    }
    if (integracioOrigenId) {
      paramsMap["ecofin"] = integracioOrigenId.toString();
    }

    let params: HttpParams = new HttpParams({fromObject: paramsMap});
    if (config && !CustomUtils.isUndefinedOrNull(config.loading) && !config.loading) {
      return this.http.get<SelectOptionCodiModel[]>(AppConstants.urlGetCentresGestors, {
        headers: new HttpHeaders().set('no-load', 'false'),
        params: params,
      });
    } else {
      return this.http.get<SelectOptionCodiModel[]>(AppConstants.urlGetCentresGestors, {
        params: params
      });
    }
  }

}
