import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {FormDataService} from '../../shared/services/form-data/form-data.service';
import {TascaService} from '../services/tasca/tasca.service';
import {Tasca} from '../../shared/models/bustia/tasca.model';
import {GeecFormDTOModel, Taskable} from '../../shared/models/forms/geec-form-dto.model';
import {Observable} from 'rxjs';
import {CustomUtils} from '../../shared/utils/custom.utils';

import {CustomRouterReuseService} from '../services/custom-router-reuse/custom-router-reuse.service';
import {ControllerResponse} from "../../shared/models/forms/controller-response.model";
import {DTOMap} from "../core-routing.module";
import {of} from "rxjs/internal/observable/of";
import {flatMap} from "rxjs/operators";
import {AppConstants} from "../../app.constants";


@Injectable()
export class FormDataResolve implements Resolve<ControllerResponse<GeecFormDTOModel>> {

  constructor(private formDataService: FormDataService, private tascaService: TascaService,
              private router: Router, private routerReuseService: CustomRouterReuseService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<ControllerResponse<GeecFormDTOModel>> {
    if (this.routerReuseService.isRouteBeingReused) {
      return of(null);
    }

    const idTasca: string = route.params['taskId'];
    const idTaskable: string = route.params['idTaskable'];
    if (CustomUtils.isDefined(idTaskable)) {
      if (idTasca) {
        let selectedTasca: Tasca = this.tascaService.getSelectedTasca();
        if (!selectedTasca) {
          // acces per URL directe
          return <Observable<ControllerResponse<GeecFormDTOModel>>>this.tascaService.getTasca(idTasca).pipe(flatMap((tasca: Tasca) => {
            if (CustomUtils.isDefined(tasca.taskeableId) && (tasca.taskeableId.toString() === idTaskable)) {
              this.tascaService.selectTasca(tasca);
              selectedTasca = tasca;
              return this.formDataService.getFormData(selectedTasca);
            } else {
              this.router.navigateByUrl(AppConstants.routeBustia);
            }
          }));
        } else {
          return this.formDataService.getFormData(selectedTasca);
        }
      } else {
        const dto: string = DTOMap.get(route.parent.routeConfig.path);
        return this.formDataService.getFormDataReadOnly(dto, idTaskable);
      }
    } else {
      this.router.navigateByUrl(AppConstants.routeBustia);
    }
  }
}
