import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ErrorPageComponent} from './components/error-page/error-page.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {AuthGuard} from './guards/auth/auth.guard';
import {DevelopmentGuard} from './guards/development/development.guard';
import {NotAuthorizedComponent} from './components/not-authorized/not-authorized.component';
import {GeecPreloadingStrategy} from "./geec-preloading-strategy";
import {PermisUsuari} from "../shared/models/usuari/usuari.model";
import {PermissionGuard} from "./guards/permission/permission.guard";
import {UnsupportedBrowserComponent} from "./components/unsupported-browser/unsupported-browser.component";
import {OutdatedBrowserComponent} from "./components/outdated-browser/outdated-browser.component";
import {UnavailableAppComponent} from "./components/unavailable-app/unavailable-app.component";
import {AccessDeniedComponent} from "./components/access-denied/access-denied.component";
import { ErrorLoginComponent } from '../shared/standalone/error-login/error-login.component';
import {UnauthorizedComponent} from "./components/unauthorized/unauthorized.component";

export const DTOMap: Map<string, string> = new Map<string, string>([
  ['dades-basiques', 'DadesBasiques'],
  ['dades-basiques-nm', 'DadesBasiques'],
  ['dades-basiques-factura', 'DadesBasiquesFactura'],
  ['dades-basiques-sbem', 'DadesBasiquesSbem'],
  ['dades-basiques-raju', 'DadesBasiquesRaju'],
  ['dades-basiques-modificacio', 'DadesBasiquesModificacio'],
  ['dades-basiques-prorroga', 'DadesBasiquesProrroga'],
  ['dades-basiques-veri', 'DadesBasiquesVeri'],
  ['dades-basiques-ecom', 'DadesBasiquesEcom'],
  ['dades-basiques-sboc', 'DadesBasiquesSboc'],
  ['dades-basiques-document-comptable', 'DadesBasiquesDocumentComptable']
]);

/**
 * Route format
 *
 * {
 *   path: '[Path]',
 *   component: [Component],
 *   data: {
 *      // This property will determine the document title (see app.component.ts setDocumentTitle function)
 *      title: "title.[Property]",
 *      ...
 *   },
 *   ...
 * }
 *
*/
const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'bustia',
    pathMatch: 'full'
  },
  {
    path: 'error-login',
    component: ErrorLoginComponent,
    data: {title: "title.error"}
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {title: "title.error"}
  },
  {
    path: 'no-autoritzat',
    component: NotAuthorizedComponent,
    data: {title: "title.no-autoritzat"}
  },
  {
    path: 'navegador-no-suportat',
    component: UnsupportedBrowserComponent,
    data: {title: "title.navegador-no-suportat"}
  },
  {
    path: 'navegador-obsolet',
    component: OutdatedBrowserComponent,
    data: {title: "title.navegador-obsolet"}
  },
  {
    path: 'no-disponible',
    component: UnavailableAppComponent,
    data: {title: "title.no-disponible"}
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
    data: {title: "title.access-denied"}
  },
  {
    path: 'login',
    loadChildren: '../login/login.module#LoginModule',
    canActivate: [DevelopmentGuard],
    data: {preload: false, title: "title.login"},
  },
  {
    path: 'loginAlternativo',
    loadChildren: '../login-alternativo/login-alternativo.module#LoginAlternativoModule',
    canActivate: [DevelopmentGuard],
    data: {preload: false, title: "title.login"},
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    data: {title: "title.unauthorized"}
  },
  {
    path: 'bustia',
    loadChildren: '../bustia/bustia.module#BustiaModule',
    canActivate: [AuthGuard],
    data: {preload: true, isStaticComponent: true, title: "title.bustia"}
  },
  {
    path: 'dades-basiques',
    loadChildren: '../dades-basiques/dades-basiques.module#DadesBasiquesModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.dades-basiques"},
  },
  {
    path: 'dades-basiques-nm',
    loadChildren: '../dades-basiques-nm/dades-basiques-nm.module#DadesBasiquesNmModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.dades-basiques-nm"},
  },
  {
    path: 'dades-basiques-factura',
    loadChildren: '../factures/factures.module#FacturesModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.dades-basiques-factura"},
  },
  {
    path: 'dades-basiques-sbem',
    loadChildren: '../actuacions-contractuals/dades-basiques-sbem/dades-basiques-sbem.module#DadesBasiquesSbemModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.dades-basiques-sbem"},
  },
  {
    path: 'dades-basiques-raju',
    loadChildren: '../actuacions-contractuals/dades-basiques-raju/dades-basiques-raju.module#DadesBasiquesRajuModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.dades-basiques-raju"},
  },
  {
    path: 'dades-basiques-document-comptable',
    loadChildren: '../dades-basiques-document-comptable/dades-basiques-document-comptable.module#DadesBasiquesDocumentComptableModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.dades-basiques-document-comptable"},
  },
  {
    path: 'dades-basiques-ecom',
    loadChildren: '../ecom/dades-basiques-ecom/dades-basiques-ecom.module#DadesBasiquesEcomModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.dades-basiques-ecom"},
  },
  {
    path: 'document-comptable',
    loadChildren: '../document-comptable/document-comptable.module#DocumentComptableModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.document-comptable"},
  },
  {
    path: 'estat-document-comptable',
    loadChildren: '../estat-document-comptable/estat-document-comptable.module#EstatDocumentComptableModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.estat-document-comptable"},
  },
  {
    path: 'documentacio',
    loadChildren: '../documentacio/documentacio.module#DocumentacioModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.documentacio"},
  },
  {
    path: 'publicacio-pscp',
    loadChildren: '../publicacio-pscp/publicacio-pscp.module#PublicacioPscpModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.publicacio-pscp"},
  },
  {
    path: 'publicacio-glic',
    loadChildren: '../publicacio-glic/publicacio-glic.module#PublicacioGlicModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.publicacio-glic"},
  },
  {
    path: 'arbre-tramitacio',
    loadChildren: '../arbre-tramitacio/arbre-tramitacio.module#ArbreTramitacioModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.arbre-tramitacio"},
  },
  {
    path: 'nou-expedient',
    loadChildren: '../nou-expedient/nou-expedient.module#NouExpedientModule',
    canActivate: [AuthGuard, PermissionGuard],
    data: {preload: false, permissionsNeeded: [PermisUsuari.CREAR_EXPEDIENT], title: "title.nou-expedient"},
  },
  {
    path: 'encargos-medios-propios',
    loadChildren: '../encargos-medios-propios/encargos-medios-propios.module#EncargosMediosPropiosModule',
    canActivate: [AuthGuard, PermissionGuard],
    data: {preload: false, permissionsNeeded: [PermisUsuari.CREAR_EXPEDIENT], title: "title.encargos-medios-propios"},
  },
  {
    path: 'llistat-expedients',
    loadChildren: '../llistat-expedients/llistat-expedients.module#LlistatExpedientsModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.llistat-expedients"},
  },
  {
    path: 'usuaris',
    loadChildren: '../usuaris/usuaris.module#UsuarisModule',
    canActivate: [AuthGuard, PermissionGuard],
    data: {preload: false, permissionsNeeded: [PermisUsuari.MANTENIMENT_USUARIS], title: "title.usuaris"},
  },
  {
    path: 'manteniment-unitats',
    loadChildren: '../manteniment-unitats/manteniment-unitats.module#MantenimentUnitatsModule',
    canActivate: [AuthGuard, PermissionGuard],
    data: {preload: false, permissionsNeeded: [PermisUsuari.TASQUES_ADMINISTRADOR_ORGAN], title: "title.manteniment-unitats"},
  },
  {
    path: 'manteniment-ajuda',
    loadChildren: '../manteniment-ajuda/manteniment-ajuda.module#MantenimentAjudaModule',
    canActivate: [AuthGuard, PermissionGuard],
    data: {preload: false, permissionsNeeded: [PermisUsuari.MANTENIMENT_AJUDA], title: "title.manteniment-ajuda"},
  },
  {
    path: 'gestor-avisos',
    loadChildren: '../notice-list/notice.module#NoticeModule',
    canActivate: [AuthGuard, PermissionGuard],
    data: {preload: false, permissionsNeeded: [PermisUsuari.GESTOR_AVISOS], title: "title.gestor-avisos"},
  },
  {
    path: 'llistats',
    loadChildren: '../llistats/listings.module#ListingsModule',
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      preload: false,
      permissionsByRoute: {
        BustiaFacturesReport: {permissionsNeeded: [PermisUsuari.BUSTIA_FACTURES]},
        ConsolaIntegracionsReport: {permissionsNeeded: [PermisUsuari.SUPER_ADMIN]},
        ConsolaPortaSignaturesReport: {permissionsNeeded: [PermisUsuari.SUPER_ADMIN]},
        // LiquidacioExpedientsMenorsReport: {permissionsNeeded: [PermisUsuari.LLISTAT_LIQUIDACIO]}
      }
      , title: "title.llistats"
    },
  },
  {
    path: 'llistats-extincio-liquidacio',
    loadChildren: '../llistats-extincio-liquidacio/llistats-extincio-liquidacio.module#LlistatsExtincioLiquidacioModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.llistats-extincio-liquidacio"},
  },
  {
    path: 'cercador-actuacions-contractuals',
    loadChildren: '../actuacions-contractuals/cercador-actuacions-contractuals/cercador-actuacions-contractuals.module#CercadorActuacionsContractualsModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.cercador-actuacions-contractuals"},
  },
  {
    path: 'consulta-rapida-expedient',
    loadChildren: '../consulta-rapida/consulta-rapida.module#ConsultaRapidaModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.consulta-rapida-expedient"},
  },
  {
    path: 'meses',
    loadChildren: '../meses/meses.module#MesesModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.meses"},
  },
  {
    path: 'estat-ajustament',
    loadChildren: '../estat-ajustament/estat-ajustament.module#EstatAjustamentModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.estat-ajustament"},
  },
  {
    path: 'mestre-licitadors',
    loadChildren: '../mestre-licitadors/mestre-licitadors.module#MestreLicitadorsModule',
    canActivate: [AuthGuard, PermissionGuard],
    data: {preload: false, permissionsNeeded: [PermisUsuari.CONSULTA_LICITADOR], title: "title.mestre-licitadors"}
  },
  {
    path: 'recepcio-ofertes',
    loadChildren: '../recepcio-ofertes/recepcio-ofertes.module#RecepcioOfertesModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.recepcio-ofertes"}
  },
  {
    path: 'ofertes',
    loadChildren: '../ofertes/ofertes.module#OfertesModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.ofertes"}
  },
  {
    path: 'resolucio-adjudicacio',
    loadChildren: '../resolucio-adjudicacio/resolucio-adjudicacio.module#ResolucioAdjudicacioModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.resolucio-adjudicacio"}
  },
  {
    path: 'desfer-adjudicacio',
    loadChildren: '../desfer-adjudicacio/desfer-adjudicacio.module#DesferAdjudicacioModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.desfer-adjudicacio"}
  },
  {
    path: 'nova-fase',
    loadChildren: '../nova-fase/nova-fase.module#NovaFaseModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.nova-fase"}
  },
  {
    path: 'invitar-licitadors',
    loadChildren: '../invitar-licitadors/invitar-licitadors.module#InvitarLicitadorsModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.invitar-licitadors"}
  },
  {
    path: 'fases-negociacio',
    loadChildren: '../fases-negociacio/fases-negociacio.module#FasesNegociacioModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.login"}
  },
  {
    path: 'formalitzacio-acceptacio',
    loadChildren: '../formalitzacio-acceptacio/formalitzacio-acceptacio.module#FormalitzacioAcceptacioModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.formalitzacio-acceptacio"}
  },
  {
    path: 'formalitzacio-sin-formalitzacio',
    loadChildren: '../formalitzacio-sin-formalitzacio/formalitzacio-sin-formalitzacio.module#FormalitzacioSinFormalitzacioModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.formalitzacio-sin-formalitzacio"}
  },
  {
    path: 'enviament-correu',
    loadChildren: '../enviament-correu/enviament-correu.module#EnviamentCorreuModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.enviament-correu"}
  },
  {
    path: 'informe-fiscal-integracio',
    loadChildren: '../informe-fiscal-integracio/informe-fiscal-integracio.module#InformeFiscalIntegracioModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.informe-fiscal-integracio"}
  },
  {
    path: 'dades-basiques-modificacio',
    loadChildren: '../actuacions-contractuals/modificacio/dades-basiques-modificacio/dades-basiques-modificacio.module#DadesBasiquesModificacioModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.dades-basiques-modificacio"},
  },
  {
    path: 'alegacions-contractista',
    loadChildren: '../actuacions-contractuals/modificacio/alegacions-contractista/alegacions-contractista.module#AlegacionsContractistaModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.alegacions-contractista"},
  },
  {
    path: 'dades-basiques-prorroga',
    loadChildren: '../actuacions-contractuals/prorroga/dades-basiques-prorroga/dades-basiques-prorroga.module#DadesBasiquesProrrogaModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.dades-basiques-prorroga"},
  },
  {
    path: 'dades-basiques-veri',
    loadChildren: '../actuacions-contractuals/veri/dades-basiques-veri/dades-basiques-veri.module#DadesBasiquesVeriModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.dades-basiques-veri"},
  },
  {
    path: 'enviament-rpc',
    loadChildren: '../enviament-rpc/enviament-rpc.module#EnviamentRpcModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.enviament-rpc"}
  },
  {
    path: 'envio-snt',
    loadChildren: '../envio-snt/envio-snt.module#EnvioSntModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.envio-snt"}
  },
  {
    path: 'comunicacion-scc',
    loadChildren: '../comunicacion-scc/comunicacion-scc.module#ComunicacionSccModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.comunicacion-scc"}
  },
  {
    path: 'retorn-factura',
    loadChildren: '../retorn-factura/retorn-factura.module#RetornFacturaModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.retorn-factura"}
  },
  {
    path: 'cercador-ecom',
    loadChildren: '../ecom/cercador-ecom/cercador-ecom.module#CercadorEcomModule',
    canActivate: [AuthGuard, PermissionGuard],
    data: {preload: false, permissionsNeeded: [PermisUsuari.TASQUES_ADMINISTRADOR_ORGAN], title: "title.cercador-ecom"}
  },
  {
    path: 'documentacio-massiva',
    loadChildren: '../documentacio-massiva/documentacio-massiva.module#DocumentacioMassivaModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.documentacio-massiva"}
  },
  {
    path: 'integracio-comptable',
    loadChildren: '../integracio-comptable/integracio-comptable.module#IntegracioComptableModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.integracio-comptable"}
  },
  {
    path: 'codis-integracio-unitat',
    loadChildren: '../codis-integracio/codis-integracio.module#CodisIntegracioModule',
    canActivate: [AuthGuard, PermissionGuard],
    data: {preload: false, permissionsNeeded: [PermisUsuari.SUPER_ADMIN], title: "title.codis-integracio-unitat"}
  },
  {
    path: 'dades-extincio-liquidacio',
    loadChildren: '../dades-extincio-liquidacio/dades-extincio-liquidacio.module#DadesExtincioLiquidacioModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.dades-extincio-liquidacio"},
  },
  {
    path: 'missatge',
    loadChildren: '../missatge/missatge.module#MissatgeModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.missatge"},
  },
  {
    path: 'document-comptable-sense-integracio',
    loadChildren: '../document-comptable-sense-integracio/document-comptable-sense-integracio.module#DocumentComptableSenseIntegracioModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.document-comptable-sense-integracio"},
  },
  {
    path: 'dades-traspas-sense-integracio',
    loadChildren: '../dades-traspas-sense-integracio/dades-traspas-sense-integracio.module#DadesTraspasSenseIntegracioModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.dades-traspas-sense-integracio"},
  },
  {
    path: 'task-document-comptable-ecofin',
    loadChildren: '../task-document-comptable-ecofin/task-document-comptable-ecofin.module#TaskDocumentComptableEcofinModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.task-document-comptable-ecofin"},
  },
  {
    path: 'dades-basiques-sboc',
    loadChildren: '../actuacions-contractuals/dades-basiques-sboc/dades-basiques-sboc.module#DadesBasiquesSbocModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.dades-basiques-sboc"},
  },
  {
    path: 'consulta-documents-ecofin',
    loadChildren: '../consulta-documents-ecofin/consulta-documents-ecofin.module#ConsultaDocumentsEcofinModule',
    canActivate: [AuthGuard, PermissionGuard],
    data: {preload: false, permissionsNeeded: [PermisUsuari.SUPER_ADMIN]}
  },
  {
    path: 'notificacio-noadjudicataris',
    loadChildren: '../notificacio-noadjudicataris/notificacio-noadjudicataris.module#NotificacioNoadjudicatarisModule',
    canActivate: [AuthGuard],
    data: {preload: false, title: "title.consulta-documents-ecofin"},

  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {title: "title.page-not-found"}
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {preloadingStrategy: GeecPreloadingStrategy}),
  ],
  exports: [RouterModule]
})
export class CoreRoutingModule {
}
