import {version} from './version';
import { EnvironmentType } from '../app/shared/globals';

export const environment = {
  type: EnvironmentType.INT,
  production: false,
  version: version,
  offlineNoticeUrl: "./assets/motd/notice.json",
  api: `${window.location.origin}/gestorb`,
  isPre: false,
  urlEnvironmentJson: 'assets/entorn.json',
  loadTreeChildrenByDefault: true,
  urlBaseLogout: "https://sso-cerbero-pre.cm-pre.jccm.es/auth/realms/usuarios/protocol/openid-connect/logout?redirect_uri=",
  showLogin: true,
  validateCodiExpedient: false,
  loginConSSO: true
};
