import {Injectable} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {environment} from "../environments/environment";
import {Ecofin} from "./shared/models/expedient/expedient.model";
import {TipusTermini} from "./shared/interfaces/expedient/tipus-termini";

export const CACHE_ITEM_KEY_CENTRES_GESTORS = "centres-gestors";
export const CACHE_ITEM_VERSION_CENTRES_GESTORS = "1.0";
export const CACHE_ITEM_KEY_PARTIDES_PRESSUPOSTARIES = "partides-pressupostaries";
export const CACHE_ITEM_VERSION_PARTIDES_PRESSUPOSTARIES = "1.0";

export enum TaskKey {
  // TODO: Posar la resta de tasques aquí
  T1 = "T1",
  T2 = "T2",
  T3 = "T3",
  NO04A = "NO04A",
  T_SNT = "T_SNT",
  CT01C = "CT01C",
  CT01D = "CT01D",
  IN02 = "IN02",
  RE10 = "RE10",
  IN03 = "IN03",
  IN62 = "IN62",
  N_T9_FN = "N_T9_FN",
  T5 = "T5",
  IN18 = "IN18",
  T_GARANTIES = "T_GARANTIES",
  T_LOTS_ADJ = "T_LOTS_ADJ",
  O_T13_ADJ = "O_T13_ADJ",
  MOD_T1 = "MOD_T1",
  RAJU_EXT_T1 = "RAJU_EXT_T1",
  AJU_RAJU = "AJU_RAJU",
  AJU_MOD = "AJU_MOD",
  PRO_T1 = "PRO_T1",
  MOD_GARANTIES = "MOD_GARANTIES",
  PRO_T5 = "PRO_T5",
  PRO_IN05A = "PRO_IN05A",
  IN18MA = "IN18MA",
  MOD_T5 = "MOD_T5",
  BAM_T1 = "BAM_T1",
  BAM_T2 = "BAM_T2",
  BAM_T3 = "BAM_T3",
  PRO_T2 = "PRO_T2",
  IN26 = "IN26",
  AJU_SBEM = "AJU_SBEM",
  AJU_SBEM_ECOFIN = "AJU_SBEM_ECOFIN",
  RE13 = "RE13",
  R_REC_SOL = "R_REC_SOL",
  R_AVA_SOL = "R_AVA_SOL",
  O_T9 = "O_T9",
  O_T10 = "O_T10",
  N_T10 = "N_T10",
  N_T14 = "N_T14",
  CE20 = "CE20",
  MOD_IN05A = "MOD_IN05A",
  EXTLQ_T1 = "EXTLQ_T1",
  EXTLQ_T2 = "EXTLQ_T2",
  LIQ_T3 = "LIQ_T3",
  LIQ_T4 = "LIQ_T4",
  EXTLQ_RE36 = "EXTLQ_RE36",
  EXTLQ_IN18MA = "EXTLQ_IN18MA",
  EXTLQ_IN63 = "EXTLQ_IN63",
  EXTLQ_IN64 = "EXTLQ_IN64",
  RE36 = "RE36",
  NO74 = "NO74",
  O_T14 = "O_T14",
  O_T15 = "O_T15",
  RE06 = "RE06",
  T24 = "T24",
  RAD_D_BAM = "RAD_D_BAM",
  NO04B = "NO04B",
  BAM_NO04A = "BAM_NO04A",
  RAD_D_BAM_RO = "RAD_D_BAM_RO",
  SBOC_T3 = "SBOC_T3",
  RAD_R_1_ECOFIN = "RAD_R_1_ECOFIN",
  RAD_D_1_ECOFIN = "RAD_D_1_ECOFIN",
  RAD_D_MOD_ECOFIN = "RAD_D_MOD_ECOFIN",
  RAD_D_PRO_ECOFIN = "RAD_D_PRO_ECOFIN",
  RAD_R_MOD_ECOFIN = "RAD_R_MOD_ECOFIN",
  RAD_R_PRO_ECOFIN = "RAD_R_PRO_ECOFIN",
  ADD_O_SDA_T13 = "ADD_O_SDA_T13",
  ADD_O_SDA_T10 = "ADD_O_SDA_T10",
  ADD_O_SDA_T9 = "ADD_O_SDA_T9",
  OB18 = "OB18",
  DC_EXT_T1 = "DC_EXT_T1",
  T99 = "T99",
  T_RPC = "T_RPC",
  PRO_T7 = "PRO_T7",
  SBEM_T3 = "SBEM_T3",
  EM_RPC = "EM_RPC",
  IN55 = "IN55",
  IN56 = "IN56",
  RAD_R_1 = "RAD_R_1",
  RAD_A_1 = "RAD_A_1",
  C_T8 = "C_T8",
  C_T9 = "C_T9",
  C_T10 = "C_T10",
  N_T9 = "N_T9"
}

export const massiveTaskNameMap = new Map<string, string>();
// Documentació
massiveTaskNameMap.set(TaskKey.O_T14, "Requeriment documentació");
massiveTaskNameMap.set(TaskKey.O_T15, "Verificació documentació");
massiveTaskNameMap.set(TaskKey.NO04A, "Notificació resolució adjudicatari/a");
massiveTaskNameMap.set(TaskKey.BAM_NO04A, "Notificació resolució adjudicatari/a");
massiveTaskNameMap.set(TaskKey.CT01C, "Formalització contracte");
massiveTaskNameMap.set(TaskKey.NO04B, "Notificació resolució no adjudicatari(s)");
massiveTaskNameMap.set(TaskKey.RE06, "Resolució adjudicació");
massiveTaskNameMap.set(TaskKey.T99, "Certificaciones, facturas y otros");

// Dades bàsiques
massiveTaskNameMap.set(TaskKey.T_GARANTIES, "Informar garanties");
massiveTaskNameMap.set(TaskKey.T24, "Informar import adjudicació");


@Injectable()
export class AppConstants {
  static privateBaseUrl = `${environment.api}/privateapi`;
  static publicBaseUrl = `${environment.api}/api`;
  static errorPage: string = 'error';
  static maximumCachedObjects: number = 10;
  static tipusIvaMultiOptionId: number = 3;
  static importRange: { min: number, max: number } = {min: 0, max: 999999999999999.99};

  static CURRENCY_DEFAULT_VALUE: number = 58; // EUR
  static COUNTRY_DEFAULT_VALUE: number = 65; // Espanya

  static TIPUS_DOCUMENT_NIF: number = 542;
  static TIPUS_DOCUMENT_CIF: number = 543;
  static TIPUS_DOCUMENT_NIE: number = 545;
  static TIPUS_DOCUMENT_PASSAPORT: number = 546;
  static TIPUS_DOCUMENT_UTE: number = 11162;

  static TIPUS_PERSONA_FISICA: number = 11629;
  static TIPUS_PERSONA_JURIDICA: number = 11630;

  static CARREC_PRESIDENT: number = 487;
  static CARREC_SECRETARI: number = 488;
  static CARREC_VOCAL: number = 491;

  static ASSISTENT_REAL_TITULAR: number = 492;
  static ASSISTENT_REAL_SUPLENT: number = 493;
  static ASSISTENT_REAL_SUPLENT_2: number = 495;
  static ASSISTENT_NO_ASSISTENT: number = 494;

  static loginURLBack: string = `${AppConstants.privateBaseUrl}/redirect`;
  static logoutURLBack: string = `${AppConstants.publicBaseUrl}/account/logout`;
  static urlGetTasques: string = `${AppConstants.privateBaseUrl}/tasques`;
  static urlGetTasquesGlic: string = `${AppConstants.privateBaseUrl}/tasques/glic`;
  static urlGetExpedients: string = `${AppConstants.privateBaseUrl}/expedients`;
  static urlGetUnitats: string = `${AppConstants.privateBaseUrl}/unitats`;
  static urlGetDadesUnitats: string = `${AppConstants.urlGetUnitats}/dades`;
  static urlGetVariablesPlantilla: string = `${AppConstants.privateBaseUrl}/variables-plantilla/`;
  static urlDocuments: string = `${AppConstants.privateBaseUrl}/documents`;
  static urlgetDadesMestres: string = `${AppConstants.privateBaseUrl}/dadesMestres`;
  static urlGetUsuaris: string = `${AppConstants.privateBaseUrl}/usuaris`;
  static urlGetCriterioSubjetivo: string = `${AppConstants.privateBaseUrl}/criterioSubjetivo`;
  static urlGetCriterioObjetivo: string = `${AppConstants.privateBaseUrl}/criterioObjetivo`;
  static urlGetCriterioExclusionLicitador: string = `${AppConstants.privateBaseUrl}/criterioExclusionLicitador`;
  static urlGetVehiculosLimpios: string = `${AppConstants.privateBaseUrl}/vehiculosLimpios`;
  static urlGetLogin: string = `${AppConstants.publicBaseUrl}/login`;
  static urlGetAdmin: string = `${AppConstants.privateBaseUrl}/admin`;
  static urlGetAdminToken: string = `${AppConstants.publicBaseUrl}/adminToken`;
  static urlGetAdminTokenPrivate: string = `${AppConstants.privateBaseUrl}/adminToken`;
  static urlWebdav: string = `${AppConstants.publicBaseUrl}/webdav`;
  static urlGetTenderTree: string = `${AppConstants.privateBaseUrl}/tenderTree`;
  static urlGetRols: string = `${AppConstants.privateBaseUrl}/rols`;
  static formsCollection: string = `${AppConstants.privateBaseUrl}/forms`;
  static urlGetPartidesPressupostaries = `${AppConstants.privateBaseUrl}/partidesPressupostaries`;
  static urlGetCentresGestors: string = `${AppConstants.privateBaseUrl}/centresGestors`;
  static urlGetLicitadors: string = `${AppConstants.privateBaseUrl}/licitadors`;
  static urlGetPoblacions: string = `${AppConstants.privateBaseUrl}/poblacions`;
  static urlReassignarTasca: string = `${AppConstants.privateBaseUrl}/reassignarTasca`;
  static urlUserPreferences: string = `${AppConstants.privateBaseUrl}/user-preferences`;
  static urlLlistats: string = `${AppConstants.privateBaseUrl}/reports`;
  static urlFactures: string = `${AppConstants.privateBaseUrl}/factures`;
  static urlFormDictionaryCollection: string = `${AppConstants.privateBaseUrl}/formDictionary`;
  static urlActuacionsContractuals: string = `${AppConstants.privateBaseUrl}/actuacionsContractuals`;
  static urlFacturesGEFACT: string = `${AppConstants.privateBaseUrl}/facturesGEFACT`;
  static urlExpedientsActuals: string = `${AppConstants.privateBaseUrl}/expedientsActuals`;
  static urlTaskCacheCollection: string = `${AppConstants.privateBaseUrl}/expedient-task-cache`;
  static urlConsultaRapida: string = `${AppConstants.privateBaseUrl}/consulta-rapida`;
  static urlDocumentComptable: string = `${AppConstants.privateBaseUrl}/documentComptable`;
  static urlGetFases: string = `${AppConstants.privateBaseUrl}/fases`;
  static urlMeses: string = `${AppConstants.privateBaseUrl}/meses`;
  static urlMembresData: string = `${AppConstants.privateBaseUrl}/membresData`;
  static urlEstatAjustament: string = `${AppConstants.privateBaseUrl}/ajustament`;
  static urlTramits: string = `${AppConstants.privateBaseUrl}/tramits`;
  static urlDefinicioIntegracions: string = `${AppConstants.privateBaseUrl}/definicioIntegracions`;
  static urlSeleccioResolucioAdjudicacio: string = `${AppConstants.privateBaseUrl}/seleccioResolucioAdjudicacio`;
  static urlPropostaAdjudicacio: string = `${AppConstants.privateBaseUrl}/propostaAdjudicacio`;
  static urlLotsAdjudicacio: string = `${AppConstants.privateBaseUrl}/lotsAdjudicacio`;
  static urlDesert: string = `${AppConstants.privateBaseUrl}/desert`;
  static urlNovaFase: string = `${AppConstants.privateBaseUrl}/novaFase`;
  static urlFasesNegociacio: string = `${AppConstants.privateBaseUrl}/fasesNegociacio`;
  static urlDocumentSistemaExtern: string = `${AppConstants.privateBaseUrl}/documentSistemaExtern`;
  static urlPublicacioPscp: string = `${AppConstants.privateBaseUrl}/publicacio-pscp`;
  static urlPublicacioPlacsp: string = `${AppConstants.privateBaseUrl}/publicacion-placsp`;
  static urlPublicacioGlic: string = `${AppConstants.privateBaseUrl}/glic`;
  static urlEnums: string = `${AppConstants.privateBaseUrl}/enums`;
  static urlRaju: string = `${AppConstants.privateBaseUrl}/raju`;
  static urlModificacio: string = `${AppConstants.privateBaseUrl}/modificacio`;
  static urlTaskeable: string = `${AppConstants.privateBaseUrl}/taskeable`;
  static urlEnviarSNT: string = `${AppConstants.privateBaseUrl}/notificacionElectronica/notificar`;
  static urlEnviarComunicacionSNT: string = `${AppConstants.privateBaseUrl}/notificacionElectronica/comunicar`;
  static urlBasats: string = `${AppConstants.privateBaseUrl}/basats`;
  static urlOfertes: string = `${AppConstants.privateBaseUrl}/ofertes`;
  static urlNotice: string = `${AppConstants.privateBaseUrl}/notice`;
  static urlSbem: string = `${AppConstants.privateBaseUrl}/sbem`;
  static urlTaskLabel: string = `${AppConstants.privateBaseUrl}/tasklabel`;
  static urlTipusIva: string = `${AppConstants.privateBaseUrl}/tipusIvas`;
  static urlEcom: string = `${AppConstants.privateBaseUrl}/ecom`;
  static urlTramitacioMassiva: string = `${AppConstants.privateBaseUrl}/tramitacio-massiva`;
  static urlIntegracioComptable: string = `${AppConstants.privateBaseUrl}/integracio-comptable`;
  static urlCodiIntegracioUnitat: string = `${AppConstants.privateBaseUrl}/codi-integracio-unitat`;
  static urlBarrat: string = `${AppConstants.privateBaseUrl}/barrat`;
  static urlLiquidacioExtincio: string = `${AppConstants.privateBaseUrl}/liquidacioExtincio`;
  static urlAlegacionsContractista: string = `${AppConstants.privateBaseUrl}/alegacionsContractista`;
  static urlExpedientIncasol: string = `${AppConstants.privateBaseUrl}/expedientIncasol`;
  static urlPdf: string = `${AppConstants.privateBaseUrl}/pdf`;
  static urlDocumentComptableSenseIntegracio: string = `${AppConstants.privateBaseUrl}/documentComptableSenseIntegracio`;
  static urlDocumentComptableEcofin: string = `${AppConstants.privateBaseUrl}/documentComptableEcofin`;
  static urlSboc: string = `${AppConstants.privateBaseUrl}/sboc`;
  static urlRemesa: string = `${AppConstants.privateBaseUrl}/remesa`;
  static urlGetEntitats: string = `${AppConstants.privateBaseUrl}/entitat_peticionaria`;
  static urlConsultaComptable: string = `${AppConstants.privateBaseUrl}/consulta_comptable`;
  static urlEstatLotSDA: string = `${AppConstants.privateBaseUrl}/estatLotSDA`;
  static urlSDAEspecific: string = `${AppConstants.privateBaseUrl}/sdaEspecific`;
  static urlNotificacioNoAdjudicataris: string = `${AppConstants.privateBaseUrl}/notificacioNoAdjudicataris`;
  static urlAjustamentEcofin: string = `${AppConstants.privateBaseUrl}/ajustamentEcofin`;
  static urlGetSubcontratista: string = `${AppConstants.privateBaseUrl}/subcontratista`;
  static urlRcon: string = `${AppConstants.privateBaseUrl}/rcon/create-contrato`;
  static urlRconIncidencia: string = `${AppConstants.privateBaseUrl}/rcon/create-incidencia`;
  static urlRconIncidenciaModificacion: string = `${AppConstants.privateBaseUrl}/rcon/create-incidencia-modificacion`;
  static urlRconIncidenciaSBEM: string = `${AppConstants.privateBaseUrl}/rcon/create-incidencia-sbem`;
  static urlComunicarSCC: string = `${AppConstants.privateBaseUrl}/comunicarCatalogo`;
  static urlLoadExpedientsSCC: string = `${AppConstants.privateBaseUrl}/loadExpedients`;
  static urlLoadDetailSCC: string = `${AppConstants.privateBaseUrl}/loadExpedientDetail`;
  static urlModificarSCC: string = `${AppConstants.privateBaseUrl}/modifyExpedientScc`;
  static urlProrrogaSCC: string = `${AppConstants.privateBaseUrl}/prorrogaExpedientScc`;
  static urlMailSoporte: string = `${AppConstants.publicBaseUrl}/soporte/mail`;
  static urlTelefonoSoporte: string = `${AppConstants.publicBaseUrl}/soporte/telefono`;
  static urlHorarioSoporte: string = `${AppConstants.publicBaseUrl}/soporte/horario`;
  static urlRlic: string = `${AppConstants.privateBaseUrl}/rlic`;
  static urlVersionBack: string = `${AppConstants.publicBaseUrl}/info/version`;
  static urlOmisionGlic: string = `${AppConstants.privateBaseUrl}/omisionGlic`;

  static urlDerivadosConsultaSCC: string = `${AppConstants.privateBaseUrl}/derivadosConsultaScc`;
  static urlDerivadosPublicacionSCC: string = `${AppConstants.privateBaseUrl}/derivadosPublicacionScc`;

 static urlDerivadosPropuestaSCC: string = `${AppConstants.privateBaseUrl}/derivadosPropuestaCcc`;
  static formJsonFiles: string = `geec_model/target/test-classes/geec_model/formDTOjson/`;

  static booleanOptions: SelectItem[] = [{value: true, label: "Sí"}, {value: false, label: "No"}];
  static tipoContratoOptions: SelectItem[] = [{value: "A", label: "A"}, {value: "R", label: "R"}];
  static tipoContratoOptions2: SelectItem[] = [{value: "D", label: "D"}];
  static tipoContratoOptions3: SelectItem[] = [{value: "R", label: "R"}];
  static tipoContratoOptions4: SelectItem[] = [{value: "A", label: "A"}];
  static anualidadesOptions: SelectItem[] = [{value: 1, label: "1"}, {value: 2, label: "2"}, {value: 3, label: "3"}, {value: 4, label: "4"},
    {value: 5, label: "5"}, {value: 6, label: "6"}, {value: 7, label: "7"}, {value: 8, label: "8"}, {value: 9, label: "9"}, {value: 10, label: "10"},
    {value: 11, label: "11"}, {value: 12, label: "12"}, {value: 13, label: "13"}, {value: 14, label: "14"}, {value: 15, label: "15"}, {value: 15, label: "15"},
    {value: 16, label: "16"}, {value: 17, label: "17"}, {value: 18, label: "18"}, {value: 19, label: "19"}, {value: 20, label: "20"}];
  static tipoPlazoOptions: SelectItem[] = [{value: TipusTermini.DIES, label: "Días"}, {value: TipusTermini.MESOS, label: "Meses"}, {value: TipusTermini.ANYS, label: "Años"}];
  static instrumentoPublicidadOptions: SelectItem[] = [{value: 40800, label: "Ninguno"}, {value: 40801, label: "PLACSP"}, {value: 40802, label: "PLACSP + DOUE"}];
  static entidadOptions: SelectItem[] = [{value: 11187, label: "CET"}, {value: 11188, label: "Empresas de Inserción (EI)"}, {value: 11191, label: "Otros"}];

  static ecofinOptions: SelectItem[] = [{value: Ecofin.GECAT, label: Ecofin.GECAT.toString()}];

  // Formulari 3
  static urlGetUsuarisValidadors: string = `${AppConstants.privateBaseUrl}/usuarisValidadors`;
  static urlPortaSignatures: string = `${AppConstants.privateBaseUrl}/portaSignatures`;
  static urlPortasignatures: string = `${AppConstants.privateBaseUrl}/portasignatures`;

  // Retorn endpoint
  static urlRetorn: string = `${AppConstants.privateBaseUrl}/retorn`;

  // Reobir tasca
  static urlWorkflow: string = `${AppConstants.privateBaseUrl}/workflow`;

  static acceptedMimeTypes: string = ".pdf,.doc,.docx,.msg,.xml,.jpg,.jpeg,.xls,.xlsx,.rtf,.xps,.bmp,.png,.zip,.txt,.bin,";
  static acceptedMimeTypesCaseSensitive: string = AppConstants.acceptedMimeTypes.toUpperCase() +
    AppConstants.acceptedMimeTypes.substr(0, AppConstants.acceptedMimeTypes.length - 1);
  static acceptedSTDMimeTypes: string[] = ["doc", "docx", "xls", "xlsx"];
  static acceptedSTDMimeTypesCaseSensitive: string[] = AppConstants.acceptedSTDMimeTypes.map((mt: string) => mt.toUpperCase()).concat(AppConstants.acceptedSTDMimeTypes);
  static routeBustia: string = "/bustia";
  static notCurrentLicitadorMessage: string = "Actualment aquest licitador té dades actualitzades.";

  static TASK_KEY: typeof TaskKey = TaskKey;

  static ORGANISME_MASTER: number = 1;
  static ORGANISME_CTTI: number = 6000;

  static ESTAT_OFERTA_ADMESA: number = 409;

  static tasquesNoTramitacioMassiva: string[] = ["estat-document-comptable", "enviament-correu", "informe-fiscal-integracio", "enviament-rpc",
    "publicacio-pscp", "publicacio-glic", "meses", "ofertes", "recepcio-ofertes", "dades-basiques-factura", "dades-basiques-modificacio", "dades-basiques-sbem",
    "dades-basiques-raju", "dades-basiques-prorroga", "invitar-licitadors", "nova-fase", "estat-ajustament", "formalitzacio-acceptacio",
    "integracio-comptable", "task-document-comptable-ecofin", "comunicacion-scc"];

  static PDF_EXTENSION: string = '.pdf';
  static SENSE_INTEGRACIO_COMPTABLE: number = 11617;
  static AMB_FISCALITZACIO_PREVIA: number = 11619;
  static EN_PROCES_ANULACIO: number = 11676;
  static LIQUIDACIO_MASSIVA_MENORS_REPORT_NAME: string = "LiquidacioExpedientsMenorsReport";

  static URL_TEST_PRE: string = "preproduccio.geectest.intranet.gencat.cat";
}

export enum UnitatPromotora {
  INCASOL = 18000
}
