import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AppConstants} from '../../../app.constants';
import {TramitacioNode} from "../../../arbre-tramitacio/classes/tramitacio-node";
import {TramitacioNodeFactory} from "../../../arbre-tramitacio/factories/tramitacio-node.factory";
import {Mapper} from "../../decorators/mapper/mapper.decorator";
import {Mapping} from "../../decorators/mapper/classes/mapping";
import {CustomUtils} from "../../../shared/utils/custom.utils";
import {TramitacioTaskDocumentNode} from "../../../arbre-tramitacio/classes/tramitacio-task-document-node";
import {map} from "rxjs/operators";
import {ControllerResponse} from "../../../shared/models/forms/controller-response.model";
import {DocumentService} from "../../../shared/services/document/document.service";
import {EnvironmentUtils} from "../../../../environments/environment.utils";

@Injectable()
export class TenderTreeService {

  root: TramitacioNode;

  constructor(private http: HttpClient, private _documentService: DocumentService) {
  }

  build(expedientID: number, idFactura: number, loadChildren?: boolean): Observable<Array<TramitacioNode>> {

    let url: string;
    if (idFactura != null) {
      url = `${AppConstants.urlGetTenderTree}/factura/${idFactura}`;
    } else {
      url = `${AppConstants.urlGetTenderTree}/${expedientID}`;
    }

    if (EnvironmentUtils.isLoadTreeChildrenByDefault) {
      loadChildren = EnvironmentUtils.isLoadTreeChildrenByDefault;
    }

    let httpParams: HttpParams = new HttpParams();
    if (CustomUtils.isDefined(loadChildren)) {
      httpParams = httpParams.set('loadChildren', JSON.stringify(loadChildren));
    }

    return this.http.get<Array<TramitacioNode>>(url, {params: httpParams}).pipe(map((res: Array<TramitacioNode>) => {
      // NODE ARREL = el que ve desplegat
      // Busquem el node arrel
      const rootNode = this.findRootNode(res);
      this.root = this._recursiveBuilder(rootNode);

      // Ens quedem amb la posicio del node arrel
      let indexRootNode: number = res.findIndex((node: TramitacioNode) => node === rootNode);

      let otherNodes: Array<TramitacioNode> = [];
      res.splice(indexRootNode, 1);

      res.forEach((node: TramitacioNode) => {
        otherNodes.push(this._recursiveBuilder(node));
      });

      otherNodes.splice(indexRootNode, 0, this.root);
      return otherNodes;
    }));
  }

  @Mapper([
    new Mapping('createDate', null, {constructor: Date, params: 'SELF'}),
    new Mapping('startDate', null, {constructor: Date, params: 'SELF'}),
    new Mapping('endDate', null, {constructor: Date, params: 'SELF'}),
    new Mapping('dueDate', null, {constructor: Date, params: 'SELF'}),
    new Mapping('reopenDate', null, {constructor: Date, params: 'SELF'}),
    new Mapping('lastModified', null, {constructor: Date, params: 'SELF'})
  ])
  public _recursiveBuilder(node: TramitacioNode): TramitacioNode {
    const _node: TramitacioNode = TramitacioNodeFactory.new(node);

    if (CustomUtils.isArrayNotEmpty(node.children)) {

      if (_node.constructor !== TramitacioTaskDocumentNode) {
        _node.children = node.children.map((child: any) => {
          return this._recursiveBuilder(child);
        });
      } else {
        _node.children = node.children;
      }

    }
    return _node;
  }

  private findRootNode(response: Array<TramitacioNode>): TramitacioNode {
    return response.find((node: TramitacioNode) => {
      return CustomUtils.isArrayNotEmpty(node.children);
    });
  }

  public createDocumentOpcionalExpedientSap(idExpedient: number): Observable<ControllerResponse<null>> {
    return this._documentService.createDocumentOpcionalExpedientSap(idExpedient);
  }

  public findOnePartial(sourceId: number, destinationIds?: number[]): Observable<TramitacioNode> {
    let httpParams: HttpParams = new HttpParams();
    if (CustomUtils.isDefined(destinationIds)) {
      httpParams = httpParams.set('destinationIds', JSON.stringify(destinationIds));
    }
    return this.http.get<TramitacioNode>(`${AppConstants.urlGetTenderTree}/partial/${sourceId}`, {params: httpParams});
  }
}
