/* tslint:disable:use-host-property-decorator no-non-null-assertion*/
import {Directive, EventEmitter, forwardRef, Inject, Input, Optional, Output, Self} from '@angular/core';
import {ControlContainer, FormGroup, FormGroupDirective, NG_ASYNC_VALIDATORS, NG_VALIDATORS} from "@angular/forms";

const formDirectiveProvider: any = {
  provide: ControlContainer,
  useExisting: forwardRef(() => GeecFormGroupDirective)
};

@Directive({
  selector: '[geecFormGroup]',
  providers: [formDirectiveProvider],
  host: {
    '(submit)': 'onSubmit($event)',
    '(reset)': 'onReset()'
  },
  exportAs: 'geecForm'
})
export class GeecFormGroupDirective extends FormGroupDirective {

  @Input('geecFormGroup') form: FormGroup = null !;
  @Output() ngSubmit = new EventEmitter();

  constructor(@Optional() @Self() @Inject(NG_VALIDATORS) private validators: any[],
              @Optional() @Self() @Inject(NG_ASYNC_VALIDATORS) private asyncValidators: any[]) {

    super(validators, asyncValidators);

  }

}
