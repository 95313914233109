import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {NotificationsService} from '../../services/notifications/notifications.service';
import {Message} from 'primeng/api';
import {NotificationDialogConfig} from "./notification-dialog-config";

@Component({
  selector: 'geec-notifications',
  templateUrl: './notifications.component.html'
})

export class NotificationsComponent implements OnInit, OnDestroy {

  msgs: Message[] = [];
  subscription: Subscription;

  dialogVisible: boolean;
  dialogHeader: string;
  dialogText: string;
  dialogContentStyle: { [key: string]: string };

  constructor(private notificationsService: NotificationsService) {
  }

  ngOnInit() {
    this._runSubscriptions();
  }

  private _runSubscriptions() {
    this.subscription = this.notificationsService.notificationChange$.subscribe(notification => {
      this.msgs = [];
      this.msgs.push(notification);
    });

    this.notificationsService.dialogText$.subscribe((config: NotificationDialogConfig) => {
      this.dialogHeader = config.header || "";
      this.dialogText = config.text;
      this.dialogContentStyle = config.contentStyle || {'width': '40rem'};
      this.dialogVisible = true;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
