import {TramitacioEstructuralNode} from "./tramitacio-estructural-node";
import {LooseObject} from "../../shared/models/forms/reactive-form-validator";
import {TramitacioNodeAction, TramitacioNodeStates} from "./model";

export class TramitacioExpedientNode extends TramitacioEstructuralNode {
  public numero: string;
  public icon: string = "fa fa-address-card";
  public status: string;
  public motiuBaixa: string;
  public urlVisor: string;
  public clickable: boolean;
  public sapImported: boolean;
  public enProcesAnulacio: boolean;

  constructor(backendJSON: LooseObject<any>) {
    super(backendJSON);
    this.numero = backendJSON.numero;
    this.status = backendJSON.status;
    this.motiuBaixa = backendJSON.motiuBaixa;
    this.urlVisor = backendJSON.urlVisor;
    this.clickable = backendJSON.clickable;
    this.sapImported = backendJSON.sapImported;
    this.enProcesAnulacio = backendJSON.enProcesAnulacio;

    if (this.status) {
      this.styleClass = this.status.toLowerCase();

      switch (this.status) {
        case TramitacioNodeStates.proces_baixa:
        case TramitacioNodeStates.anulat:
          this.icon = "fa fa-times";
          break;
        default:
          break;
      }
    }

    if (this.clickable) {
      this.selectable = true;
      this.actions.push({
        key: TramitacioNodeAction.veureArbre,
        labelButton: 'Ver árbol',
        iconButton: 'fa fa-list'
      });
    }

    if (this.isActiveNode) {
      this.styleClass = 'assigned';
    }
  }

}
