import {Injectable} from '@angular/core';
import {GeecError} from "../../models/geec-error.model";

@Injectable()
export class GeecErrorService {

  private _error: GeecError;

  constructor() {}

  get error(): GeecError {
    return this._error;
  }

  set error(value: GeecError) {
    this._error = value;
  }
}
