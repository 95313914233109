import {InfoLot} from "./info-lot.model";
import { infoPartida } from "./info-partida.model";

export enum Ecofin {
  ICSOL_ID = 612,
  CTTI_ID = 613,
  GECAT_ID = 633,
  SENSE_INTEGRACIO_COMPTABLE_ID = 11617,
  ECOFIN_GECAT_ID = 11636,
  ECOFIN_PANGEA_ID = 11637,
  ICSOL = 'ICSOL',
  CTTI = 'CTTI',
  GECAT = 'GECAT',
  ECOFIN_GECAT = 'ECOFIN_GECAT',
  ECOFIN_PANGEA = 'ECOFIN_PANGEA'
}

export class Expedient {
  id: number;
  numero: string;
  anticipat: boolean;
  exercici: number;
  titol: string;
  objecte: string;
  procedimentId: number;
  tipusContracteId: number;
  subtipusContracteId: number;
  modalitatId: number;
  unitatsPromotoresIds: number[];
  unitatTramitadoraId: number;
  termini: number;
  tempsTermini: number;
  iniciExecucio: Date;
  fiExecucio: Date;
  executionId: string;
  lots: boolean;
  totalLots: number;
  infoLots: InfoLot[];
  partidas: boolean;
  totalPartidas: number;
  infoPartidas: infoPartida[];
  integracioOrigenId: Ecofin;
  sistemaAdjudicacio: number;
  acordMarcTransversal: boolean;
  tipusBasatAcordMarcPermesos: number[];
  tipusBasatSistemaDinamicAdquisicioPermesos: number[];
  lotProrrogatId: number;
  lotOrigenId: number;
  isProrroga: boolean;
  acordMarcOrigenId: number;
  acordMarcOrigen: string;
  dataNoOfertasGlic: Date;
  publicidad: number;
  liquidacion: boolean;
  hasCccDerivados: boolean;
  glicId: number;
  omisionGlic: boolean;
}

