export class TextUtils {

  /** Copies the text into the clipboard.
   * Returns a boolean to check if the text was copied succefully. */
  static copyToClipboard(text: string): boolean {
    if (text) {
      try {
        const listener: (e: ClipboardEvent) => void = (e: ClipboardEvent) => {
          const clipboard: DataTransfer = e.clipboardData || window['clipboardData'];
          e.clipboardData.setData('text/plain', (text));
          e.preventDefault();
        };

        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
        return true;
      } catch (err) {
        console.error(err);
      }
    }
    return false;
  }

}
