import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {AppConstants} from "../../../app.constants";
import {AutoCompleteConfig} from "../../../shared/services/utils/auto-complete-config";
import {LooseObject} from "../../../shared/models/forms/reactive-form-validator";
import {HttpClientUtils} from "../../../shared/services/utils/http-client-utils";
import {CustomUtils} from "../../../shared/utils/custom.utils";
import {map, tap} from "rxjs/operators";
import {UnitatTreeNode} from "../../../shared/models/tree/unitat-tree-node";
import {HttpResponse} from "@angular/common/http/src/response";
import {responseBlobToFile} from "../../../shared/utils/conversion-utils";
import {UnitatSocietatModel} from "../../../consulta-documents-ecofin/models/consulta-documents-ecofin";

export interface UnitatModel {
  id: number;
  nom: string;
  sigles: string;
  organismeId: number;
}

export enum UnitatsEnum {
  ICSOL = 18000
}

export interface UnitatTreeModel extends UnitatModel {
  nomOrganisme: string;
  hasRoles: boolean;
  hasUnitatPromotoraRole: boolean;
  children: Array<UnitatTreeModel>;
  isActive: boolean;
}

export interface SearchUnitatsFilter {
  config?: AutoCompleteConfig;
  filterText?: string;
  onlyOrganismes?: boolean;
  // default search is onlyPromotores = true
  onlyPromotores?: boolean;
}

export class UnitatTreeOptions {
  // only load unitat if user has roles for the specified unitat
  withRol: boolean = false;
  // only load unitat if user has unitat promotora role for the specified unitat
  onlyPromotores: boolean = false;
  // defines if unitat can be selected
  selectableNodes: boolean = true;
  // when the page is a task
  isTaskPage: boolean = false;
  // whether to include inactive unitats
  includeInactive: boolean = false;
  // add unitat if you have organ admin rol
  organUnitats: boolean = false;

  constructor(init?: Partial<UnitatTreeOptions>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class UnitatService {

  private _unitatTree: Array<UnitatTreeModel>;

  private organUnitats: boolean = false;

  constructor(private _http: HttpClient) {
  }

  private static checkActive(options: UnitatTreeOptions, unitat: UnitatTreeModel): boolean {
    return options.includeInactive || (!options.includeInactive && unitat.isActive);
  }

  private static checkWithRol(options: UnitatTreeOptions, unitat: UnitatTreeModel): boolean {
    return !options.withRol || (options.withRol && unitat.hasRoles);
  }

  private static checkOnlyPromotores(options: UnitatTreeOptions, unitat: UnitatTreeModel): boolean {
    return !options.onlyPromotores || (options.onlyPromotores && unitat.hasUnitatPromotoraRole);
  }

  public isTreeLoaded(): boolean {
    return CustomUtils.isDefined(this._unitatTree);
  }

  public findUnitatsByExpedientId(id: number): Observable<Array<UnitatModel>> {
    return this._http.get<Array<UnitatModel>>(`${AppConstants.urlGetUnitats}/expedient/${id}`);
  }

  public searchUnitats(searchFilter: SearchUnitatsFilter): Observable<Array<UnitatModel>> {
    let paramsMap: LooseObject<string> = AutoCompleteConfig.loadConfig({}, searchFilter.config);
    if (searchFilter.filterText && searchFilter.filterText.trim()) {
      paramsMap['like'] = searchFilter.filterText;
    }
    if (CustomUtils.isDefined(searchFilter.onlyOrganismes)) {
      paramsMap['onlyOrganismes'] = searchFilter.onlyOrganismes.toString();
    }
    if (CustomUtils.isDefined(searchFilter.onlyPromotores)) {
      paramsMap['onlyPromotores'] = searchFilter.onlyPromotores.toString();
    }

    return this._http.get<Array<UnitatModel>>(`${AppConstants.urlGetUnitats}/search`, {
      headers: searchFilter.config.loading ? null : HttpClientUtils.NO_LOAD_HTTP_HEADER,
      params: new HttpParams({fromObject: paramsMap})
    });
  }

  public findUnitatInTree(idUnitat: number): Observable<UnitatTreeModel> {
    return this._getUnitatTree().pipe(map((unitatTree: Array<UnitatTreeModel>) => {
      return this._findUnitatInTree(unitatTree, idUnitat);
    }));
  }

  public getUnitatTree(options?: Partial<UnitatTreeOptions>, organId?: number): Observable<Array<UnitatTreeNode>> {
    return this._createUnitatTree(options, organId);
  }

  public invalidateTree(): void {
    this._unitatTree = undefined;
  }

  usuarisEntitatToExcel(idUnitat: number): Observable<File> {
    return this._http.get(`${AppConstants.urlGetUnitats}/exportUsuariDadesUnitatExcel/${idUnitat}`, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res: HttpResponse<Blob>) => responseBlobToFile(res)));
  }

  findUnitatsCataleg(): Observable<UnitatModel[]> {
    return this._http.get<UnitatModel[]>(`${AppConstants.urlGetUnitats}/unitats_cataleg`);
  }

  createUnitatTreeChildren(unitats: Array<UnitatTreeModel> = [], options: UnitatTreeOptions): Array<UnitatTreeNode> {
    return unitats.reduce((arr: Array<UnitatTreeNode>, unitat: UnitatTreeModel) => {
      const childrenNodes: Array<UnitatTreeNode> = this.createUnitatTreeChildren(unitat.children, options);
      if (UnitatService.checkActive(options, unitat) && UnitatService.checkWithRol(options, unitat) && UnitatService.checkOnlyPromotores(options, unitat)) {
        const unitatNode: UnitatTreeNode = new UnitatTreeNode(unitat, childrenNodes, options.selectableNodes, options.isTaskPage);
        arr.push(unitatNode);
      } else if (CustomUtils.isArrayNotEmpty(childrenNodes)) {
        arr.push(...childrenNodes);
      }
      return arr;
    }, []);
  }

  private _findUnitatInTree(unitatTree: Array<UnitatTreeModel>, idUnitat: number): UnitatTreeModel {
    if (CustomUtils.isArrayNotEmpty(unitatTree)) {
      for (let unitat of unitatTree) {
        if (unitat && (unitat.id === idUnitat)) {
          return unitat;
        } else if (CustomUtils.isArrayNotEmpty(unitat.children)) {
          const unitatFound: UnitatTreeModel = this._findUnitatInTree(unitat.children, idUnitat);
          if (unitatFound) {
            return unitatFound;
          }
        }
      }
    }
    return null;
  }

  private _loadUnitatTree(organUnitats: boolean): Observable<Array<UnitatTreeModel>> {
    let paramsMap: LooseObject<string> = {};
    paramsMap["organUnitats"] = String(organUnitats);
    let params: HttpParams = new HttpParams({fromObject: paramsMap});

    return this._http.get<Array<UnitatTreeModel>>(AppConstants.urlGetUnitats, {params: params}).pipe(tap((unitats: Array<UnitatTreeModel>) => {
      this._unitatTree = unitats;
    }));
  }

  private _loadUnitatTreeByOrganId(organId: number): Observable<Array<UnitatTreeModel>> {
    return this._http.get<Array<UnitatTreeModel>>(`${AppConstants.urlGetUnitats}/tree/${organId}`).pipe(tap((unitats: Array<UnitatTreeModel>) => {
      this._unitatTree = unitats;
    }));
  }

  private _createUnitatTree(options?: Partial<UnitatTreeOptions>, organId?: number): Observable<Array<UnitatTreeNode>> {
    return this._getUnitatTree(organId, CustomUtils.isDefined(options) ? options.organUnitats : false).pipe(map((unitats: Array<UnitatTreeModel>) => {
      return this.createUnitatTreeChildren(unitats, new UnitatTreeOptions(options));
    }));
  }

  private _getUnitatTree(organId?: number, organUnitats: boolean = false): Observable<Array<UnitatTreeModel>> {
    if (CustomUtils.isDefined(this._unitatTree) && CustomUtils.isUndefinedOrNull(organId) && this.organUnitats === organUnitats) {
      return of(this._unitatTree);
    } else {
      this.organUnitats = organUnitats;
      return this._loadUnitatTree(organUnitats);
    }
  }

  public _getOrganismesSocietat(like?: string): Observable<Array<UnitatSocietatModel>> {
    let paramsMap: LooseObject<string> = {};
    if (CustomUtils.isDefined(like) && (like !== '')) {
      paramsMap["like"] = like;
    }
    let params: HttpParams = new HttpParams({fromObject: paramsMap});

    return this._http.get<any[]>(AppConstants.urlGetUnitats + "/organismes_societat", {
      params: params
    });
  }
}
