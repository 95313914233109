import {AfterContentInit, AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, ContentChildren, ElementRef,
  EmbeddedViewRef, Input, OnDestroy, QueryList, Renderer2, TemplateRef, ViewContainerRef, ChangeDetectionStrategy} from '@angular/core';
import { GeecTemplateDirective } from '../../directives/geec-template.directive';
import { ComponentFactory } from "@angular/core/src/linker/component_factory";
import { HelpButtonComponent } from "../help-button/help-button.component";

@Component({
  selector: 'geec-content-footer',
  templateUrl: './content-footer.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class ContentFooterComponent implements AfterContentInit, AfterViewInit, OnDestroy {

  @Input() styleClass: string;

  @ContentChildren(GeecTemplateDirective) templates: QueryList<any>;

  private readonly _helpButton: ComponentRef<HelpButtonComponent>;

  view: EmbeddedViewRef<any>;

  leftTemplate: TemplateRef<any>;

  rightTemplate: TemplateRef<any>;

  ngOnInit() { }
  get helpButton(): HelpButtonComponent {
    return this._helpButton.instance;
  }

  constructor(private _viewContainerRef: ViewContainerRef,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _renderer: Renderer2,
    private _elementRef: ElementRef) {
    const factory: ComponentFactory<HelpButtonComponent> = this._componentFactoryResolver.resolveComponentFactory(HelpButtonComponent);
    this._helpButton = this._viewContainerRef.createComponent(factory);
  }

  ngAfterViewInit() {
  }

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'left-content':
          this.leftTemplate = item.template;
          break;
        case 'right-content':
          this.rightTemplate = item.template;
          break;
        default:
          this.rightTemplate = item.template;
          break;
      }
    });
  }

  ngOnDestroy() {
    this.view = null;
  }

}
