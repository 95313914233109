import {CustomUtils} from '../../shared/utils/custom.utils';
import {DadesBasiquesNmFormModel} from "../../dades-basiques-nm/models/dades-basiques-nm-form.model";

export class MapperForm {
  constructor() {
  }

  public mapObject(aux: Object, obj: Object, formModel: Object): any {
    if (obj === null || obj === undefined) {
      Object.assign(aux, null);
    } else {
      let prototype: string = Object.prototype.toString.call(formModel);
      if (prototype === "[object Object]") {
        for (let prop in formModel) {
          if (formModel.hasOwnProperty(prop)) {
            if (CustomUtils.isDefined(formModel[prop]) && Object.keys(formModel[prop]).length > 0) {
              Object.defineProperty(aux, prop, {value: {}});
              aux[prop] = this.mapObject(aux[prop], obj[prop], formModel[prop]);
            } else {
              if ((Object.prototype.toString.call(formModel[prop]) === "[object Date]") && CustomUtils.isDefined(obj[prop])) {
                aux[prop] = new Date(obj[prop]);
              } else {
                aux[prop] = obj[prop];
              }
            }
          }
        }
        // Map pels lots
      } else if (prototype === "[object Array]" && (CustomUtils.isDefined(aux[0]) && aux[0] instanceof DadesBasiquesNmFormModel)) {
        (<DadesBasiquesNmFormModel[]>formModel).forEach((form: DadesBasiquesNmFormModel, index: number) => {
            if (CustomUtils.isDefined(obj[index].dadesBasiques)) {
              for (let prop in form) {
                if (form.hasOwnProperty(prop)) {
                  aux[index][prop] = this.mapObject(aux[index][prop], obj[index].dadesBasiques[prop], form[prop]);
                }
              }
            } else {
              for (let prop in form) {
                if (form.hasOwnProperty(prop)) {
                  aux[index][prop] = this.mapObject(aux[index][prop], obj[index][prop], form[prop]);
                }
              }
            }
          }
        );
      } else {
        aux = obj;
      }
    }

    return aux;
  }
}
