/**
 * It's intended usage is:
 * proxy['prop1.prop2.prop3...']
 * proxy['index.prop1.prop2...']
 * @type {ProxyHandler<object>}
 */
export const proxyHandler = {
  get: function (obj: object, properties: string): any {
    let value: any;
    const props: Array<string> = properties.split('.');
    if (props.length > 1) {
      const prop = props.shift();
      if (obj && prop in obj) {
        return this.get(obj[prop], props.join('.'));
      }
    }

    if (obj) {
      value = Reflect.get(obj, props[0]);
    }

    return value;
  },
  set: function (obj: object, properties: string, value: any): boolean {
    const props: Array<string> = properties.split('.');
    if (props.length > 1) {
      const prop = props.shift();
      if (!(prop in obj)) {
        Reflect.set(obj, prop,  {});
      }

      return this.set(obj[prop], props.join('.'), value);
    }

    return Reflect.set(obj, props[0],  value);
  },
  deleteProperty: function (obj: object, properties: string) {
    const props: Array<string> = properties.split('.');
    if (props.length > 1) {
      const prop = props.shift();
      if (prop in obj) {
        return this.deleteProperty(obj[prop], props.join('.'));
      }
    }

    return Reflect.deleteProperty(obj, props[0]);
  }
};

