import {ReactiveFormBuilder} from "../shared/models/forms/reactive-form-builder";
import {GeecFormGroup} from "../core/directives/reactive-forms/geec-form-group";
import {Observable} from "rxjs";
import {Validators} from "@angular/forms";
import {DocumentExpedient} from "./models/document-expedient.model";
import {ControllerResponse} from "../shared/models/forms/controller-response.model";
import {DocumentacioFormModel} from "./models/documentacio-form.model";
import {AppConstants} from "../app.constants";
import {HttpParams} from "@angular/common/http";
import {DocumentModel} from "../shared/models/documentacio/document.model";
import {Confirmation, ConfirmationService, SelectItem, TreeNode} from "primeng/api";
import {map} from "rxjs/operators";
import {GeecFormDTOModel} from "../shared/models/forms/geec-form-dto.model";
import {DadesSignaturaDadesDocument} from "./models/dades-signatura-dades-document.model";
import {DadesSignaturaPortaSignatures} from "./models/dades-signatura-portasignatures.model";
import {RootInjectorService} from "../core/services/root-injector.service";
import {UsuariValidadorService} from "../shared/services/usuari-validador/usuari-validador.service";
import {DadaMestra, DadaMestraService, DadaMestraType} from "../shared/services/dada-mestra/dada-mestra.service";
import {PortaSignaturesService} from "../shared/services/portasignatures/portasignatures.service";
import {PortasignaturesFormModel} from "./models/portasignatures-form.model";
import {LooseObject} from "../shared/models/forms/reactive-form-validator";
import {Mapper} from "../core/decorators/mapper/mapper.decorator";
import {Mapping} from "../core/decorators/mapper/classes/mapping";
import {CustomUtils} from "../shared/utils/custom.utils";
import {GeecValidators} from "../shared/validators/geec-validators";
import {ActionSubmit} from "../core/services/form.service";
import {Tasca} from "../shared/models/bustia/tasca.model";
import {CopiaAutenticaService} from "../shared/services/copia-autentica/copia-autentica.service";
import {Injectable} from "@angular/core";
import {DocumentacioMassivaFormModel} from "../documentacio-massiva/models/documentacio-massiva-form.model";

export interface CreateDocumentOpcionalFrontDTO {
  tipusDocumentId: number;
}

@Injectable()
export class DocumentacioService extends ReactiveFormBuilder {
  private _usuariValidadorService: UsuariValidadorService;
  private _dadaMestraService: DadaMestraService;
  private _portaSignaturesService: PortaSignaturesService;
  private _confirmationService: ConfirmationService;
  private _copiaAutenticaService: CopiaAutenticaService;

  constructor() {
    super();

    this._usuariValidadorService = RootInjectorService.injector.get<UsuariValidadorService>(UsuariValidadorService);
    this._dadaMestraService = RootInjectorService.injector.get<DadaMestraService>(DadaMestraService);
    this._portaSignaturesService = RootInjectorService.injector.get<PortaSignaturesService>(PortaSignaturesService);
    this._confirmationService = RootInjectorService.injector.get<ConfirmationService>(ConfirmationService);
    this._copiaAutenticaService = RootInjectorService.injector.get<CopiaAutenticaService>(CopiaAutenticaService);
  }

  buildForm(): GeecFormGroup {
    return this._gfb.group({
      dadesDocument: this._gfb.group({
        id: [null],
        selectorDocument: [null],
        codi: [null],
        codiRepositoriExtern: [null],
        descripcio: [null, Validators.required],
        dataCreacio: [null],
        dataUltimaModificacio: [null],
        estatActual: [null],
        estatSeguent: [null],
        registreEntrada: [null],
        registreSortida: [null],
        dataRegistreEntrada: [null],
        dataRegistreSortida: [null],
        eCopia: [null],
        documentEscanejat: [null],
        tipusDocumental: [null],
        dadesSignatures: [null],
        versionsDocument: [null],
        signDocumentServiceId: [null],
        token: [null],
        dadesTaskDocument:  [null],
        extensio: [null]
      })
    });
  }

  submitForm(taskId: string, formDTO: any, action: ActionSubmit, autorize?: boolean): Observable<any> {
    return this._formService.formSubmit("documentacio", taskId, formDTO, action, autorize);
  }

  @Mapper([
    new Mapping('dadesDocument.dataCreacio', null, {constructor: Date, params: 'SELF'}),
    new Mapping('dadesDocument.dataUltimaModificacio', null, {constructor: Date, params: 'SELF'}),
    new Mapping('dadesDocument.dataRegistreEntrada', null, {constructor: Date, params: 'SELF'}),
    new Mapping('dadesDocument.dataRegistreSortida', null, {constructor: Date, params: 'SELF'})
  ])
  convertFormData(obj: object): any {
    return obj;
  }

  saveNewDocument(document: DocumentExpedient, taskId: string): Observable<ControllerResponse<DocumentacioFormModel>> {
    return this._http.post<ControllerResponse<DocumentacioFormModel>>(`${AppConstants.urlDocuments}/${taskId}`, document);
  }

  saveDocumentWithCsv(idTask: string, csv: string, descripcion: string): Observable<ControllerResponse<DocumentacioFormModel>> {
    console.log(`${AppConstants.urlDocuments}/cargar/${idTask}/${csv}`);
    let body = {
      descripcion: descripcion
    };
    return this._http.post<ControllerResponse<DocumentacioFormModel>>(`${AppConstants.urlDocuments}/cargar/${idTask}/${csv}`, body);
  }

  createDocument(taskId: string): Observable<ControllerResponse<DocumentacioFormModel | DocumentacioMassivaFormModel>> {
    return this._http.post<ControllerResponse<DocumentacioFormModel | DocumentacioMassivaFormModel>>(`${AppConstants.urlDocuments}/plantilla/${taskId}`, null);
  }

  blockTask(formName: string, expedientId: number, taskId: string): Observable<any> {
    return this._http.get<any>(`${AppConstants.urlTaskCacheCollection}/blockTask/${formName}/${expedientId}/${taskId}`);
  }

  loadDocumentsExpedient(expedientId: number, parameters?: HttpParams): Observable<DocumentModel[]> {
    return this._http.get<DocumentModel[]>(`${AppConstants.urlDocuments}/expedient/${expedientId}`,
      {params: parameters ? parameters : null});
  }

  loadDocumentsExpedientTree(expedientId: number, parameters?: HttpParams): Observable<TreeNode[]> {
    return this.loadDocumentsExpedient(expedientId, parameters).pipe(map((documents: DocumentModel[]) => {
      return documents.map((document: DocumentModel) => {
        return {
          label: document.nom,
          data: document,
          icon: "fa fa-file-o"
        };
      });
    }));
  }

  esborrarDocument(taskId: string): Observable<ControllerResponse<DocumentacioFormModel>> {
    return this._http.post<ControllerResponse<DocumentacioFormModel>>(`${AppConstants.urlDocuments}/esborrar/${taskId}`, null);
  }

  anularDocument(taskId: string): Observable<ControllerResponse<DocumentacioFormModel>> {
    return this._http.post<ControllerResponse<DocumentacioFormModel>>(`${AppConstants.urlDocuments}/anular/${taskId}`, null);
  }

  formSave(formName: string, taskId: string, formDTO: any): Observable<ControllerResponse<GeecFormDTOModel | Tasca>> {
    return this._formService.formSubmit(formName, taskId, formDTO);
  }

  createFormVSPortaSignatures(vsPortaSignatures: DadesSignaturaDadesDocument | DadesSignaturaPortaSignatures, pantallaPortaSignatures: boolean, accio: number): GeecFormGroup {
    const form: GeecFormGroup = this._gfb.group({
      accio: [vsPortaSignatures ? vsPortaSignatures.accio : accio, Validators.required]
    });

    if (pantallaPortaSignatures) {
      form.addControl("usuarisValidadors", this._gfb.control(vsPortaSignatures ? (<DadesSignaturaPortaSignatures>vsPortaSignatures).usuarisValidadors : null, [Validators.required, GeecValidators.multiSelectOnlyOne]));
      form.addControl("data", this._gfb.control(vsPortaSignatures && (<DadesSignaturaDadesDocument>vsPortaSignatures).data ? new Date((<DadesSignaturaDadesDocument>vsPortaSignatures).data) : null, null));
    } else {
      form.addControl("usuariValidador", this._gfb.control(vsPortaSignatures ? (<DadesSignaturaDadesDocument>vsPortaSignatures).usuariValidador.nom : null, Validators.required));
      form.addControl("data", this._gfb.control(vsPortaSignatures && (<DadesSignaturaDadesDocument>vsPortaSignatures).data ? new Date((<DadesSignaturaDadesDocument>vsPortaSignatures).data) : null, Validators.required));
    }

    return form;
  }

  reassignarTascaUnitatPromotora(taskId: string): Observable<ControllerResponse<boolean>> {
    return this._formService.reassignarTascaUnitatPromotora(taskId);
  }

  desadjudicarFiscalitzacio(taskId: string): Observable<ControllerResponse<boolean>> {
    return this._formService.desadjudicarFiscalitzacio(taskId);
  }

  loadUsuarisValidadors(searchText: string, organisme: number, taskId: string): Observable<any[]> {
    return this._usuariValidadorService.getOptionsWithFilter(searchText, organisme, taskId, {
      page: 0,
      size: 10,
      loading: false
    });
  }

  createPortaSignaturesForm(): GeecFormGroup {
    return this._gfb.group({
      missatge: [null, [Validators.required, Validators.maxLength(3500)]],
      urgent: [false],
      documents: [[]],
      documentsIds: [[]],
      dadesSignatura: [[], [Validators.required, Validators.minLength(1)]]
    });
  }

  loadAccions(): Observable<Array<SelectItem>> {
    return this._dadaMestraService.getOptions(DadaMestraType.ACCIO_VALIDACIO_SIGNATURA).pipe(map(this._selectItemMap));
  }

  loadUserAccions(): Observable<Array<SelectItem>> {
    return this._dadaMestraService.getOptions(DadaMestraType.ESTAT_VALIDACIO_PORTA_SIGNATURES).pipe(map(this._selectItemMap));
  }

  loadEstatsDocument(): Observable<Array<SelectItem>> {
    return this._dadaMestraService.getOptions(DadaMestraType.ESTAT_DOCUMENT).pipe(map(this._selectItemMap));
  }

  loadPortaSignaturesData(taskId: string): Observable<LooseObject<PortasignaturesFormModel>> {
    return this._portaSignaturesService.getPortaSignaturesData(taskId);
  }

  confirmation(confirmation: Confirmation): void {
    this._confirmationService.confirm(confirmation);
  }

  enviarPortaSignatures(taskId: string, obj: object): Observable<ControllerResponse<DocumentacioFormModel>> {
    const dto = {portaSignatures: obj};
    return this._portaSignaturesService.enviarPortaSignatures(taskId, dto);
  }

  noInvitar(taskId: string): Observable<ControllerResponse<boolean>> {
    return this._http.post<ControllerResponse<boolean>>(`${AppConstants.urlGetLicitadors}/noInvitar/${taskId}`, null);
  }

  demanarCopia(idDocumentActiu: number): Observable<ControllerResponse<DocumentacioFormModel>> {
    return this._copiaAutenticaService.getCopiaAutentica(idDocumentActiu);
  }

  potPujarDocument(numExpedient, fileSize: number): Observable<boolean> {
    return this._http.get<boolean>(`${AppConstants.urlDocuments}/potPujarDocument/${numExpedient}/${fileSize}`);
  }

  public actualitzarEstat(csv: string, taskId: string): Observable<ControllerResponse<DocumentacioFormModel>> {
    return this._portaSignaturesService.actualitzarEstat(csv, taskId);
  }

  private _selectItemMap(item: Array<DadaMestra>): Array<SelectItem> {
    return CustomUtils.toSelectItemArray(item);
  }

  novaVersioDocument(taskId: string): Observable<ControllerResponse<DocumentacioFormModel>> {
    return this._http.post<ControllerResponse<DocumentacioFormModel>>(`${AppConstants.urlDocuments}/novaVersioDocument/${taskId}`, null);
  }

  public getCSVLicitadorGLIC(taskId: string, idExpediente: number): Observable<ControllerResponse<DocumentacioFormModel>> {
    return this._http.post<ControllerResponse<DocumentacioFormModel>>(`${AppConstants.urlPublicacioGlic}/recCSVLicitador/${taskId}/${idExpediente}`, null);
  }
}
