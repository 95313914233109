import {HelpText} from "../../components/help-button/models/help-text.model";
import {Expedient} from "../expedient/expedient.model";


export class ValuesField {
  value: any;
  descripcio: null;
}

export class FieldData {
  value: string;
  description: string;
}

export class IvaFieldData extends FieldData {
  percent: number;
  calculable: boolean;
  ordre: number;
}

export class LooseObjectFieldData extends FieldData {
  [key: string]: any;
}

export class SelectItemData {
  label: string;
  value: number;
  data?: any;
}

export class SelectOptionItem {
  label: string;
  value: any;
  index?: number;
}

export class FormField {
  enabled: boolean = null;
  /**
   * @deprecated #Soon this.validations it's gonna be used instead.
   */
  mandatory: boolean = null;
  /**
   * @deprecated #Soon this.validations it's gonna be used instead.
   */
  mandatoryToSave: boolean = null;
  visible: boolean = null;
  name: string = null;
  defaultValue: any = null;
  data: FieldData[] | SelectItemData[] = null;
  values: ValuesField[] = null;
  validations?: FormValidation[] = null;
  expedientOnly: boolean = null;
  displayName: string = null;
  helpText: HelpText = null;

  constructor() {
  }
}

export class FormDefinition {
  formName: string;
  fields: FormField[];
  fieldNames: string[];
  validations: FormValidationDTO[];
}

export class FormValidation {
  validation: string;
  parameters: Array<any>;

  constructor()
  constructor(validation: string, parameters: Array<any>)
  constructor(validation?: string, parameters?: Array<any>) {
    this.validation = validation;
    this.parameters = parameters;
  }
}

export class FormValidationDTO {
  fieldName: string;
  validation: string;
  parameters: Array<any>;
}

export enum TaskableType {
  EXPEDIENT = "Expedient",
  FACTURA = "Factura",
  SBEM = "Sbem",
  RAJU = "Raju",
  MODIFICACIO = "Modificacio",
  VERI = "Veri",
  ECOM = "Ecom",
  SBOC = "Sboc"
}

export interface Taskable {
  exercici: number;
  id: number;
  numero: string;
  type: TaskableType;
}

export class GeecFormDTOModel {
  layout: Object;
  validator: Object;
  definition: FormDefinition;
  expedient: Expedient;
  formDto: Object;
  taskeable: Taskable;
  expedientId: number;
  expedientTaskCacheResponse: {
    expedientTaskCache: {
      creationDate: number;
      formName: string;
      sessionId: string;
      taskId: string;
      userId: string;
    },
    responseCode: TaskUsageState;
  };
  isReopenedByAdmin: boolean;
}

export enum TaskUsageState {
  VOID = "RESPONSE_CODE_VOID",
  OK = "RESPONSE_CODE_OK",
  OTHER_USER = "RESPONSE_CODE_OTHER_USER",
  OTHER_SESSION = "RESPONSE_CODE_OTHER_SESSION",
  OTHER_SESSION_UNLOCK = "RESPONSE_CODE_OTHER_SESSION_UNLOCK"
}
