import {AppComponent} from './app.component';
import {BrowserModule, Title} from '@angular/platform-browser';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule, LOCALE_ID} from '@angular/core';
import {CoreModule} from './core/core.module';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {ApplicationInitService} from "./application-init/application-init.service";
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

export function initialize_application(applicationInitService: ApplicationInitService): () => Promise<any> {
  return () => applicationInitService.initializeApp();
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initialize_application,
      deps: [ApplicationInitService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: "en-US"
    },
    Title
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
