import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Message, SelectItem} from "primeng/api";
import {FacturaService} from "../../../services/factura/factura.service";
import {CustomUtils} from "../../../utils/custom.utils";
import {ControllerResponse, ResponseMessage} from "../../../models/forms/controller-response.model";
import {concat, Observable} from "rxjs";
import {NotificationsService} from "../../../../core/services/notifications/notifications.service";
import {UnitatModel, UnitatService} from "../../../../core/services/unitat/unitat.service";
import {IdentifiedObject, IdentifiedResponse} from "../../../../llistats/listing-detail/listing-detail.component";
import {map, toArray} from "rxjs/operators";


@Component({
  selector: 'geec-reassignar-factures',
  templateUrl: './reassignar-factures.component.html'
})
export class ReassignarFacturesComponent implements OnInit {

  @Input() display: boolean;
  @Input() facturesIdentificades: IdentifiedObject[];
  @Input() idExpedient: number;
  @Output() displayChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() messagesChange: EventEmitter<Message[]> = new EventEmitter<Message[]>();
  @Output() searchBustia: EventEmitter<void> = new EventEmitter<void>();

  unitats: SelectItem[];
  unitatSelected: number;

  constructor(private unitatService: UnitatService, private facturaService: FacturaService, private notificationsService: NotificationsService) {
  }

  ngOnInit() {
    if (CustomUtils.isArrayNotEmpty(this.facturesIdentificades) && this.idExpedient) {
      this.loadData();
    }
  }

  private loadData(): void {
    this.unitatService.findUnitatsByExpedientId(this.idExpedient).subscribe((unitats: UnitatModel[]) => {
      this.unitats = CustomUtils.toSelectItemArray<UnitatModel>(unitats, {
        labelPattern: unitat => `${unitat.nom}`,
        valueExtractor: unitat => unitat.id
      });
    });
  }

  reassignarFactures(): void {
    this.messagesChange.emit(null);
    const calls: Observable<IdentifiedResponse>[] = [];

    this.facturesIdentificades.forEach((bustiaFacturaIdentificada: IdentifiedObject) => {
      let observable: Observable<ControllerResponse<ResponseMessage>> = this.facturaService.reassignarFactura(bustiaFacturaIdentificada.keyField, this.unitatSelected);
      let identifiedObservable: Observable<IdentifiedResponse> = observable.pipe(map((resp: ControllerResponse<ResponseMessage>) => {
        return {identifiedField: bustiaFacturaIdentificada.keyDefinitionField, response: resp};
      }));
      calls.push(identifiedObservable);
    });

    if (CustomUtils.isArrayNotEmpty(calls)) {
      concat(...calls).pipe(toArray()).subscribe((responses: IdentifiedResponse[]) => {
        const messages: Message[] = this.notificationsService.getMessagesAndNotify(this.notificationsService.identifyMessages(responses));
        this.displayChange.emit(false);
        if (CustomUtils.isArrayNotEmpty(messages)) {
          this.messagesChange.emit(messages);
        }
        this.searchBustia.emit();
      });
    }
  }

  onClose(): void {
    this.displayChange.emit(false);
  }
}
