import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AppConstants} from '../../../app.constants';
import {Observable} from 'rxjs';
import {Licitador} from '../../models/licitadors/licitador.model';
import {ControllerResponse} from '../../models/forms/controller-response.model';
import {LooseObject} from "../../models/forms/reactive-form-validator";
import {ImportOfertesLicitadorModel} from "../../models/licitadors/import-ofertes-licitador.model";
import {Page} from '../../interfaces/paging/page';
import {CustomUtils} from "../../utils/custom.utils";

@Injectable()
export class LicitadorService {
  constructor(private http: HttpClient) {
  }

  // paged search (only used in licitador-search)
  searchLicitadors(searchParams: LooseObject<string> = {}): Observable<Page<Licitador>> {
    const params: HttpParams = new HttpParams({fromObject: searchParams});
    return this.http.get<Page<Licitador>>(AppConstants.urlGetLicitadors + "/filter", {
      params: params
    });
  }

  // autocomplete search
  autocompleteLicitadors(searchText: string, invitats: boolean = false, taskableId?: number): Observable<Licitador[]> {
    const searchParams: LooseObject<string> = {};
    searchParams["page"] = JSON.stringify(0);
    searchParams["size"] = JSON.stringify(10);
    searchParams["invitats"] = JSON.stringify(invitats);
    if (searchText) {
      searchParams["like"] = searchText;
    }
    if (CustomUtils.isDefined(taskableId)) {
      searchParams["taskableId"] = JSON.stringify(taskableId);
    }
    const params: HttpParams = new HttpParams({fromObject: searchParams});
    return this.http.get<any>(AppConstants.urlGetLicitadors, {
      headers: new HttpHeaders().set('no-load', 'false'),
      params: params,
    });
  }

  checkAdjudicacions(idLicitador: number, taskId: string): Observable<ControllerResponse<ImportOfertesLicitadorModel>> {
    let mapString: LooseObject<string> = {};
    mapString["taskId"] = taskId;
    const params: HttpParams = new HttpParams({fromObject: mapString});
    return this.http.get<ControllerResponse<ImportOfertesLicitadorModel>>(`${AppConstants.urlGetLicitadors}/${idLicitador}/checkAdjudicacions`, {params: params});
  }

}
