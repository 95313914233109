import { Injectable } from "@angular/core";
import { ReactiveFormBuilder } from "../../models/forms/reactive-form-builder";
import { Observable } from "rxjs";
import { AppConstants } from "../../../app.constants";
import { EnviamentRpcFormModel } from "../../../enviament-rpc/models/enviament-rpc-form.model";
import { GeecFormGroup } from "../../../core/directives/reactive-forms/geec-form-group";
import { ActionSubmit } from "../../../core/services/form.service";


@Injectable()

export class CertificadoService extends ReactiveFormBuilder {
  
  constructor() {
    super();
  }
  
  public buildForm(): GeecFormGroup {
    return null;
  }

  public convertFormData(obj: object) { 
    
  }
  public submitForm(taskId: string, formDTO: any, action: ActionSubmit, autorize?: boolean): Observable<any> {
    return null;
     }

  guardarCertificado(expedientId: number, taskId: string): Observable<EnviamentRpcFormModel> { 
    return this._http.get<EnviamentRpcFormModel>(`${AppConstants.urlRlic}/certificado/${expedientId}/${taskId}`);
  }
}
