import {Injectable} from '@angular/core';
import {MapperForm} from '../../../core/classes/mapper-form';
import {DadesBasiquesNmFormModel} from "../../../dades-basiques-nm/models/dades-basiques-nm-form.model";
import {CustomUtils} from "../../utils/custom.utils";
import {Mapper} from "../../../core/decorators/mapper/mapper.decorator";
import {Mapping} from "../../../core/decorators/mapper/classes/mapping";
import {FasePublicacio} from "../../standalone/components/publicacions/models/fase-publicacio";
import {TipusPublicacio} from "../../standalone/components/publicacions/models/tipus-publicacio";
import {DadesExtincioLiquidacioFormModel} from "../../models/forms/dades-extincio-liquidacio/dades-extincio-liquidacio-form.model";

@Injectable()
export class DadesBasiquesMapperService extends MapperForm {

  constructor() {
    super();
  }

  @Mapper([
    new Mapping('ofertes.dataPresentacio', null, {constructor: Date, params: 'SELF'}),
    new Mapping('ofertes.dataDadesBancaries', null, {constructor: Date, params: 'SELF'}),
    new Mapping('lots.ofertes.dataPresentacio', null, {constructor: Date, params: 'SELF'}),
    new Mapping('lots.ofertes.dataDadesBancaries', null, {constructor: Date, params: 'SELF'}),
    new Mapping('lots.garanties.dataImposicio', null, {constructor: Date, params: "SELF"}),
    new Mapping('lots.garanties.dataVenciment', null, {constructor: Date, params: "SELF"}),
    new Mapping('lots.convocatoria.dataObertura', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('lots.convocatoria.dataLimitRecepcio', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('lots.convocatoria.fechaAperturaAdministrativo', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('lots.convocatoria.fechaAperturaTecnico', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('lots.convocatoria.fechaAperturaEconomico', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('convocatoria.dataObertura', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('convocatoria.dataLimitRecepcio', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('convocatoria.fechaAperturaAdministrativo', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('convocatoria.fechaAperturaTecnico', null, {
      constructor: Date,
      params: 'SELF'
    }),
    new Mapping('convocatoria.fechaAperturaEconomico', null, {
      constructor: Date,
      params: 'SELF'
    }),
    ...DadesBasiquesMapperService.getPublicacionsMappings([FasePublicacio.LICITACIO]),
    ...DadesBasiquesMapperService.getPublicacionsMappings([FasePublicacio.ADJUDICACIO, FasePublicacio.FORMALITZACIO], 'lots.')
  ])
  public toDadesBasiquesNmFormModel(formDto: Object): DadesBasiquesNmFormModel {
    let dadesBasiquesNmFormModel: DadesBasiquesNmFormModel = new DadesBasiquesNmFormModel();
    let _aux: DadesBasiquesNmFormModel = new DadesBasiquesNmFormModel();

    for (let prop in formDto) {
      if (dadesBasiquesNmFormModel.hasOwnProperty(prop)) {
        if (prop === 'lots') {
          if (CustomUtils.isDefined(formDto[prop])) {
            formDto[prop].forEach(() => {
              dadesBasiquesNmFormModel[prop].push(new DadesBasiquesNmFormModel());
              _aux[prop].push(new DadesBasiquesNmFormModel());
            });
          }
        }

        _aux[prop] = this.mapObject(_aux[prop], formDto[prop], dadesBasiquesNmFormModel[prop]);
      }
    }

    return _aux;
  }

  public toDadesExtincioLiquidacioFormModel(formDto: Object): DadesExtincioLiquidacioFormModel {
    let dadesExtincioLiquidacioFormModel: DadesExtincioLiquidacioFormModel = new DadesExtincioLiquidacioFormModel();
    let _aux: DadesExtincioLiquidacioFormModel = new DadesExtincioLiquidacioFormModel();

    for (let prop in formDto) {
      if (dadesExtincioLiquidacioFormModel.hasOwnProperty(prop)) {
        if (prop === 'lots') {
          if (CustomUtils.isDefined(formDto[prop])) {
            formDto[prop].forEach(() => {
              dadesExtincioLiquidacioFormModel[prop].push(new DadesExtincioLiquidacioFormModel());
              _aux[prop].push(new DadesExtincioLiquidacioFormModel());
            });
          }
        }

        _aux[prop] = this.mapObject(_aux[prop], formDto[prop], dadesExtincioLiquidacioFormModel[prop]);
      }
    }
    return _aux;
  }

  public static getPublicacionsMappings(fases: FasePublicacio[], prefix: string = ''): Mapping[] {
    const publicacionsMapping: Mapping[] = [];
    fases.forEach((fase: FasePublicacio) => {
      Object.keys(TipusPublicacio).forEach((tipus: TipusPublicacio) => {
        ['enviamentAnunci', 'publicacioAnunci', 'dataEsmena'].forEach((data: string) => {
          publicacionsMapping.push(new Mapping(`${prefix}publicacions.${fase}.${tipus}.${data}`, null, {
            constructor: Date,
            params: 'SELF'
          }));
        });
      });
    });
    return publicacionsMapping;
  }
}
