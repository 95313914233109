import {SelectOptionCodiModel} from '../models/select-option-codi.model';
import {SelectItem} from 'primeng/api';
import {DadaMestra} from "../services/dada-mestra/dada-mestra.service";
import {TaskKey} from "../../app.constants";
import {FormField, ValuesField} from "../models/forms/geec-form-dto.model";

interface SelectItemPatternExtractor<T> {
  labelPattern?: (o: T) => string;
  valueExtractor?: (o: T) => any;
}

interface HOPAIDOptions {
  returnValue?: boolean;
}

export class CustomUtils {

  public static copy(obj: any): any {
    let copy: any;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" !== typeof obj) {
      return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = CustomUtils.copy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (let attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = CustomUtils.copy(obj[attr]);
        }
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  /**
   * Synchronously pauses the application.
   * @param {number} delay
   */
  public static sleep(delay: number): void {
    const start: number = new Date().getTime();
    while (new Date().getTime() < start + delay) {
    }
  }

  /**
   * Executes a callback on the next digest cycle.
   * @param {() => void} cb
   */
  public static nextTick(cb: () => void, timeout: number = 0): void {
    if (typeof MutationObserver === 'function') {
      const node: Text = document.createTextNode('');
      new MutationObserver(cb).observe(node, {characterData: true});
      node.data = '0';
    } else {
      setTimeout(cb, timeout);
    }
  }

  /**
   * Takes an Array<any> and converts it to a SelectItem[]
   * @param {Array<any>} objArray
   * @param {SelectItemPatternExtractor} [config]
   * @returns {SelectItem[]}
   */
  public static toSelectItemArray<T>(objArray: Array<T>, config: SelectItemPatternExtractor<T> = {
    labelPattern: (o: T) => null,
    valueExtractor: (o: T) => null
  }): SelectItem[] {
    if (CustomUtils.isArrayNotEmpty(objArray)) {
      return objArray.map((item: T) => <SelectItem>Object.create(Object.prototype, {
        label: {value: config.labelPattern(item) || (<any>item).nom},
        value: {value: config.valueExtractor(item) || (<any>item).id}
      }));
    } else {
      return [];
    }
  }

  /**
   * Alias function for hasOwnPropertyAndItsDefined
   * (not intended pun in the name)
   * @param {Object} obj
   * @param {Array<string> | string} prop
   * @param {HOPAIDOptions} options
   * @returns {any}
   * @constructor
   */
  public static HOPAID(obj: Object, prop: Array<string> | string, options: HOPAIDOptions = {}): any {
    if (this.isDefined(obj)) {
      return CustomUtils.hasOwnPropertyAndItsDefined(obj, prop, options);
    }
  }

  /**
   * Deep searching implementation made for "HOPAID" function.
   * @param {object} object
   * @param {Array<string>} properties
   * @param {HOPAIDOptions} options
   * @returns {boolean}
   * @constructor
   */
  private static HOPAIDDeepSearch(object: object, properties: Array<string>, options: HOPAIDOptions): boolean {
    let result: boolean = false;

    if (CustomUtils.isDefined(object)) {
      if (!(properties.length > 1)) {
        result = CustomUtils.hasOwnPropertyAndItsDefined(object, properties.shift(), options);
      }

      let _property: string = properties.shift();
      if (object.hasOwnProperty(_property) || this.hasOwnPropertyAndIsNotEnumerable(object, _property)) {
        result = this.HOPAIDDeepSearch(object[_property], properties, options);
      }
    }

    return result;
  }

  /**
   * Tells if a determinate property of an object is not enumerable
   * @param {object} object
   * @param {string} property
   * @returns {boolean}
   */
  private static hasOwnPropertyAndIsNotEnumerable(object: object, property: string): boolean {
    return property in object && !object.propertyIsEnumerable(property);
  }

  /**
   * Tells if an object has a determinate property and if it has value
   * @param {object} object
   * @param {Array<string> | string} property
   * @param {HOPAIDOptions} options
   * @returns {any}
   */
  public static hasOwnPropertyAndItsDefined(object: object, property: Array<string> | string, options: HOPAIDOptions = {}): any {
    if (!Array.isArray(property)) {
      let result: boolean = (object.hasOwnProperty(property) || this.hasOwnPropertyAndIsNotEnumerable(object, property)) &&
        CustomUtils.isDefined(object[property]);

      if (result && options.returnValue) {
        return object[property];
      } else {
        return result;
      }
    }

    return this.HOPAIDDeepSearch(object, property, options);
  }

  static hasPropertiesDefined(value: any, properties: string[]): boolean {
    return properties.reduce((firstProp: any, secondProp: any) => {
      return ((firstProp === true) || CustomUtils.isDefined(value[firstProp])) && CustomUtils.isDefined(value[secondProp]);
    }, true);
  }

  static isDefined(value): boolean {
    return !CustomUtils.isUndefinedOrNull(value);
  }

  static areDefined(...value: any[]): boolean {
    return value.every((element: any) => CustomUtils.isDefined(element));
  }

  static isEitherDefined(...value: any[]): boolean {
    return value.some((element: any) => CustomUtils.isDefined(element));
  }

  /**
   * Checks if the string or array is not null or empty.
   * @param {string | any[]} value
   * @returns {boolean}
   */
  static isNotEmpty(value: string | any[]): boolean {
    return CustomUtils.isDefined(value) && (value.length !== 0);
  }

  /**
   * Checks if the array is not null or empty.
   * @param {any[]} value
   * @returns {boolean}
   */
  static isArrayNotEmpty(value: any): boolean {
    return CustomUtils.isNotEmpty(value) && Array.isArray(value);
  }

  static isArrayWithNullObjects(value: any): boolean {
    return Array.isArray(value) && CustomUtils.isNotEmpty(value) && !CustomUtils.checkNoNullObjects(value);
  }

  private static checkNoNullObjects(value: any): boolean {
    return value.some(x => x != null);
  }

  static isUndefinedOrNull(value: any): boolean {
    return value === null || value === undefined;
  }

  /**
   * Checks for empty input values.
   * @param value
   * @returns {boolean}
   */
  static isEmptyInputValue(value: any): boolean {
    // we don't check for string here so it also works with arrays
    return CustomUtils.isUndefinedOrNull(value) || Number.isNaN(value) || value.length === 0;
  }

  static getOptionLabel(value: any, options: SelectItem[]): string {
    let option;

    if (CustomUtils.isDefined(value) && CustomUtils.isDefined(options)) {
      option = options.find(item => {
          return CustomUtils.stringifyIfNeeded(item.value) === CustomUtils.stringifyIfNeeded(value);
        }
      );
    }
    return option ? option.label : null;
  }

  static getSelectOptionCodiLabel(id: number, options: SelectOptionCodiModel[]): string {
    const option = options.find(item => {
        return CustomUtils.stringifyIfNeeded(item.id) === CustomUtils.stringifyIfNeeded(id);
      }
    );
    return option ? option.codi : null;
  }

  static getDadaMestraOptionLabel(id: number, options: DadaMestra[]): string {
    const option = options.find((item: DadaMestra) => {
        return CustomUtils.stringifyIfNeeded(item.id) === CustomUtils.stringifyIfNeeded(id);
      }
    );
    return option ? option.nom : null;
  }

  static stringifyIfNeeded(value: any): string {
    return ((typeof value) === "string") ? value : JSON.stringify(value);
  }

  static scrollToTop(): void {
    // For Safari
    document.body.scrollTop = 0;
    // For Chrome, Firefox, IE and Opera
    document.documentElement.scrollTop = 0;
  }

  public static scrollTopById(elementId: string): void {
    const element: HTMLElement = document.getElementById(elementId);
    if (this.isDefined(element)) {
      element.scrollIntoView();
    }
  }


  /**
   * Checks if the object is a function (Taken from Underscore.js)
   * @param object
   * @returns {boolean}
   */
  static isFunction(object: any): boolean {
    return !!(object && object.constructor && object.call && object.apply);
  }

  static isObject(obj: any): boolean {
    return (Object.prototype.toString.call(obj) === "[object Object]");
  }

  static isString(value: any): boolean {
    return Object.prototype.toString.call(value) === "[object String]";
  }

  static isTask(taskKey: string, taskKeys: TaskKey | TaskKey[]): boolean {
    if (this.isArrayNotEmpty(taskKeys)) {
      return (taskKeys as TaskKey[]).includes(TaskKey[taskKey]);
    } else {
      return taskKeys === TaskKey[taskKey];
    }
  }

  static isT1OrModT1(taskKey: string): boolean {
    return CustomUtils.isTask(taskKey, [TaskKey.T1, TaskKey.MOD_T1]);
  }

  static exists<T>(arr: T[], obj: any, compareFn?: (obj1: T, obj2: any) => boolean): boolean {
    if (CustomUtils.isArrayNotEmpty(arr)) {
      return arr.findIndex((i: T) => {
        return compareFn ? compareFn(i, obj) : i === obj;
      }) !== -1;
    }
    return false;
  }

  static isBlob(obj: any): boolean {
    return obj &&
      typeof obj.size === 'number' &&
      typeof obj.type === 'string' &&
      typeof obj.slice === 'function';
  }

  static isFile(obj: any): boolean {
    return CustomUtils.isBlob(obj) &&
      typeof obj.name === 'string' &&
      (typeof obj.lastModifiedDate === 'object' ||
        typeof obj.lastModified === 'number');
  }

  public static updateValue(object: Object, validator: FormField): void {
    Object.keys(object).forEach((key: string) => {
      if (CustomUtils.isDefined(validator[key]) && CustomUtils._hasToOverrideValue(validator[key].values)) {
        object[key] = validator[key].values[0].value;
      }
    });
  }

  private static _hasToOverrideValue(values: Array<ValuesField>): boolean {
    return CustomUtils.isArrayNotEmpty(values) && CustomUtils.isDefined(values[0].value);
  }
}
