import {Injectable} from '@angular/core';
import {Mapping} from "../../core/decorators/mapper/classes/mapping";
import {ControllerResponse} from "../../shared/models/forms/controller-response.model";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Mapper} from "../../core/decorators/mapper/mapper.decorator";
import {AppConstants} from "../../app.constants";
import {Page} from "../../shared/interfaces/paging/page";
import {Usuari, UsuariReduit} from "../../shared/models/usuari/usuari.model";
import {LooseObject} from "../../shared/models/forms/reactive-form-validator";
import {PageableConfig} from "../../core/services/tasca/tasca.service";
import {Observable} from "rxjs/internal/Observable";
import {RolsUnitatModel} from "../../shared/models/usuari/rols-unitat.model";

@Injectable()
export class UsuariService {

  constructor(private http: HttpClient) {
  }

  @Mapper([
    new Mapping('content.dataAlta', null, {constructor: Date, params: 'SELF'}),
    new Mapping('content.dataBaixa', null, {constructor: Date, params: 'SELF'}),
    new Mapping('content.rolsUnitat.rols.dataInici', null, {constructor: Date, params: 'SELF'}),
    new Mapping('content.rolsUnitat.rols.dataFi', null, {constructor: Date, params: 'SELF'}),
  ])
  getUsuarisWithFilter(pageConfig: PageableConfig, paramsMap: LooseObject<string>): Observable<Page<Usuari>> {
    paramsMap["page"] = JSON.stringify(pageConfig.page);
    paramsMap["size"] = JSON.stringify(pageConfig.size);
    return this.http.get<Page<Usuari>>(`${AppConstants.urlGetUsuaris}`, {
      params: new HttpParams({fromObject: paramsMap}),
    });
  }

  getReduceUsuarisWithFilter(pageConfig: PageableConfig, paramsMap: LooseObject<string>): Observable<Page<UsuariReduit>> {
    paramsMap["page"] = JSON.stringify(pageConfig.page);
    paramsMap["size"] = JSON.stringify(pageConfig.size);
    return this.http.get<Page<UsuariReduit>>(`${AppConstants.urlGetUsuaris}/usuaris-obertura`, {
      params: new HttpParams({fromObject: paramsMap}),
    });
  }

  @Mapper([
    new Mapping('dataAlta', null, {constructor: Date, params: 'SELF'}),
    new Mapping('dataBaixa', null, {constructor: Date, params: 'SELF'}),
    new Mapping('rolsUnitat.rols.dataInici', null, {constructor: Date, params: 'SELF'}),
    new Mapping('rolsUnitat.rols.dataFi', null, {constructor: Date, params: 'SELF'}),
    new Mapping('dataUltimAcces', null, {constructor: Date, params: 'SELF'}),
  ])
  findUsuari(idUsuari: number): Observable<Usuari> {
    return this.http.get<Usuari>(`${AppConstants.urlGetUsuaris}/${idUsuari}`);
  }

  createUsuari(usuari: Usuari): Observable<ControllerResponse<Usuari>> {
    return this.http.post<ControllerResponse<Usuari>>(`${AppConstants.urlGetUsuaris}`, usuari);
  }

  updateUsuari(usuari: Usuari): Observable<ControllerResponse<Usuari>> {
    return this.http.put<ControllerResponse<Usuari>>(`${AppConstants.urlGetUsuaris}`, usuari);
  }

  baixaUsuari(usuari: Usuari): Observable<ControllerResponse<Usuari>> {
    return this.http.post<ControllerResponse<Usuari>>(`${AppConstants.urlGetUsuaris}/baixa`, usuari);
  }

  altaUsuari(usuari: Usuari): Observable<ControllerResponse<Usuari>> {
    return this.http.post<ControllerResponse<Usuari>>(`${AppConstants.urlGetUsuaris}/alta`, usuari);
  }

  public getRolesToCopy(idUsuari: number): Observable<RolsUnitatModel[]> {
    return this.http.get<RolsUnitatModel[]>(`${AppConstants.urlGetUsuaris}/rols/${idUsuari}`);
  }

}
