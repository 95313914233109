import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {ExpedientReduitAC} from "../models/expedient-reduit-ac";
import {AppConstants} from "../../app.constants";
import {PageableConfig} from "../../core/services/tasca/tasca.service";
import {LooseObject} from "../../shared/models/forms/reactive-form-validator";
import {AutoCompleteConfig} from "../../shared/services/utils/auto-complete-config";
import {CustomUtils} from "../../shared/utils/custom.utils";
import {ControllerResponse} from "../../shared/models/forms/controller-response.model";
import {Page} from '../../shared/interfaces/paging/page';
import {FieldData, Taskable} from "../../shared/models/forms/geec-form-dto.model";
import {ImportsRajuFormModel} from "../models/dades-basiques-raju/dades-basiques-raju-form.model";
import {DocumentComptableModel} from "../models/dades-basiques-raju/document-comptable.model";
import {map} from "rxjs/operators";
import {Confirmation, ConfirmationService, SelectItem} from "primeng/api";
import {PartidesPressupostariesModificacioFormModel} from "../modificacio/dades-basiques-modificacio/models/dades-basiques-modificacio-form.model";
import {ImportsSbemFormModel} from "../models/dades-basiques-sbem/dades-basiques-sbem-form.model";
import {ModificacioReduitModel} from "../../task-document-comptable-ecofin/models/modificacio-reduit.model";

export class AccioActuacioContractual {
  url: string;
  nom: string;
  enabled: boolean;
}

export enum UrlAccioContractual {
  MODIFICACIO = "modificacio",
  SUBROGACIO = "subrogacio",
  REVISIO_PREUS = "revisio",
  PRORROGA = "prorroga",
  SUSPENSIO = "suspensio",
  RESOLUCIO = "resolucio",
  RAJU = "raju",
  VERI = "verificacio"
}

@Injectable()
export class ActuacionsContractualsService {
  constructor(private http: HttpClient, private _confirmationService: ConfirmationService) {
  }

  getExpedientsReduitsWithFilter(pageConfig: PageableConfig, searchParams: LooseObject<string> = {}, extincioLiquidacio: boolean): Observable<Page<ExpedientReduitAC>> {
    searchParams["page"] = JSON.stringify(pageConfig.page);
    searchParams["size"] = JSON.stringify(pageConfig.size);

    return this.getExpedientsReduits(searchParams, extincioLiquidacio);
  }

  getAccionsDisponibles(idExpedient): Observable<AccioActuacioContractual[]> {
    return this.http.get<AccioActuacioContractual[]>(`${AppConstants.urlActuacionsContractuals}/${idExpedient}`);
  }

  iniciarSubrogacio(idExpedient: number, actuacio: string): Observable<ControllerResponse<boolean>> {
    return this.http.post<ControllerResponse<boolean>>(`${AppConstants.urlActuacionsContractuals}/${actuacio}/${idExpedient}`, null);
  }

  getDocumentAjustarOptions(expedientAjustar: number, tipusDocumentAjustar: number, taskeableId: number): Observable<SelectItem[]> {
    const paramsMap: LooseObject<string> = {};
    if (tipusDocumentAjustar) {
      paramsMap['tipusDoc'] = tipusDocumentAjustar.toString();
    }
    if (taskeableId) {
      paramsMap['ajustTaskeableId'] = taskeableId.toString();
    }
    const httpParams: HttpParams = new HttpParams({fromObject: paramsMap});
    return this.http.get<DocumentComptableModel[]>(`${AppConstants.urlActuacionsContractuals}/documentAjustar/${expedientAjustar}`,
      {params: httpParams}).pipe(map((docAjustarOptions: DocumentComptableModel[]) => {
      return CustomUtils.toSelectItemArray(docAjustarOptions, {
        labelPattern: (d: DocumentComptableModel) => d.codi,
        valueExtractor: (d: DocumentComptableModel) => d.id
      });
    }));
  }

  getExpedientAjustarOptions(idTaskeable: number): Observable<SelectItem[]> {
    return this.http.get<Taskable[]>(`${AppConstants.urlActuacionsContractuals}/expedientAjustar/${idTaskeable}`).pipe(map((options: Taskable[]) => {
      return CustomUtils.toSelectItemArray(options, {labelPattern: (t: Taskable) => t.numero, valueExtractor: (t: Taskable) => t.id});
    }));
  }

  getExpedientAjustarOptionsTaskable(taskeableId: number): Observable<Taskable[]> {
    return this.http.get<Taskable[]>(`${AppConstants.urlActuacionsContractuals}/expedientAjustar/${taskeableId}`);
  }

  getTipusDocumentAjustarOptions(taskeableId: number, isExtincio: boolean = false): Observable<SelectItem[]> {
    const paramsMap: LooseObject<string> = {};
    if (taskeableId) {
      paramsMap['taskeableId'] = JSON.stringify(taskeableId);
    }
    paramsMap['isExtincio'] = JSON.stringify(isExtincio);
    const httpParams: HttpParams = new HttpParams({fromObject: paramsMap});
    return this.http.get<FieldData[]>(`${AppConstants.urlActuacionsContractuals}/tipusDocumentAjustar`, {params: httpParams}).pipe(map((options: FieldData[]) => {
      return CustomUtils.toSelectItemArray(options, {
        labelPattern: (d: FieldData) => d.description,
        valueExtractor: (d: FieldData) => d.value
      });
    }));
  }

  public getImportsRaju(idDocAjustar: number, expedientAjustar: number): Observable<ImportsRajuFormModel[]> {
    const paramsMap: LooseObject<string> = {};
    if (expedientAjustar) {
      paramsMap['expedientAjustar'] = expedientAjustar.toString();
    }
    const httpParams: HttpParams = new HttpParams({fromObject: paramsMap});
    return this.http.get<ImportsRajuFormModel[]>(`${AppConstants.urlDocumentComptable}/partidesPressupostaries/${idDocAjustar}`, {params: httpParams});
  }


  public getDadesEconomiquesMod(modificacioReduit: ModificacioReduitModel): Observable<PartidesPressupostariesModificacioFormModel[]> {
    const paramsMap: LooseObject<string> = {};
    if (CustomUtils.isDefined(modificacioReduit.documentComptableAjustarId)) {
      paramsMap['documentComptableAjustarId'] = modificacioReduit.documentComptableAjustarId.toString();
    }
    return this.http.post<PartidesPressupostariesModificacioFormModel[]>(`${AppConstants.urlModificacio}/getDadesEconomiques`, modificacioReduit);
  }

  public getDadesEconomiquesSbem(documentComptableAjustarId: number): Observable<ImportsSbemFormModel[]> {
    const paramsMap: LooseObject<string> = {};
    if (documentComptableAjustarId) {
      paramsMap['documentComptableAjustarId'] = documentComptableAjustarId.toString();
    }
    return this.http.get<ImportsSbemFormModel[]>(`${AppConstants.urlSbem}/getDadesEconomiques`, {params: paramsMap});
  }

  confirmation(confirmation: Confirmation): void {
    this._confirmationService.confirm(confirmation);
  }

  private getExpedientsReduits(mapString: LooseObject<string>, extincioLiquidacio: boolean, config?: AutoCompleteConfig): Observable<any> {
    let params: HttpParams = new HttpParams({fromObject: mapString});

    if (config && !CustomUtils.isUndefinedOrNull(config.loading) && !config.loading) {
      return this.http.get<any>(AppConstants.urlActuacionsContractuals, {
        headers: new HttpHeaders().set('no-load', 'false'),
        params: params,
      });
    } else {
      if (extincioLiquidacio) {
        return this.http.get<ExpedientReduitAC[]>(config ? AppConstants.urlActuacionsContractuals : AppConstants.urlActuacionsContractuals + "/filterSensePRO", {
          params: params
        });
      } else {
        return this.http.get<ExpedientReduitAC[]>(config ? AppConstants.urlActuacionsContractuals : AppConstants.urlActuacionsContractuals + "/filter", {
          params: params
        });
      }
    }
  }
}
