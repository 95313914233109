import {CustomUtils} from "../../utils/custom.utils";

export abstract class SistemaAdjudicacio {
  static readonly ACORD_MARC: number = 472;
  static readonly DERIVAT_ACORD_MARC: number = 473;
  static readonly SISTEMA_DINAMIC_ADQUISICIO: number = 11157;
  static readonly NO_ACORD_MARC: number = 11195;
  static readonly BASAT_ACORD_MARC: number = 11196;
  static readonly CONTRACTE_CENTRALITZAT: number = 11657;
  static readonly BASAT_ACORD_MARC_CENTRALITZAT = 11658;
  static readonly SDA_ESPECIFIC: number = 11670;

  static isBasatAcordMarc(sistemaAdjudicacio: number): boolean {
    if (CustomUtils.isDefined(sistemaAdjudicacio)) {
      return sistemaAdjudicacio === SistemaAdjudicacio.BASAT_ACORD_MARC || sistemaAdjudicacio === SistemaAdjudicacio.BASAT_ACORD_MARC_CENTRALITZAT;
    }
    return false;
  }

  static isSistemaDinamicAdquisicioEspecific(sistemaAdjudicacio: number): boolean {
    if (CustomUtils.isDefined(sistemaAdjudicacio)) {
      return sistemaAdjudicacio === SistemaAdjudicacio.SDA_ESPECIFIC;
    }
    return false;
  }

  static isContracteCentralitzat(sistemaAdjudicacio: number): boolean {
    if (CustomUtils.isDefined(sistemaAdjudicacio)) {
      return sistemaAdjudicacio === SistemaAdjudicacio.CONTRACTE_CENTRALITZAT || sistemaAdjudicacio === SistemaAdjudicacio.BASAT_ACORD_MARC_CENTRALITZAT;
    }
    return false;
  }
}
