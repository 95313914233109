import {Pipe, PipeTransform} from '@angular/core';
import {CustomUtils} from "../../utils/custom.utils";
import {SelectItem} from "primeng/api";

@Pipe({
  name: 'optionLabel'
})
export class OptionLabelPipe implements PipeTransform {

  transform(value: any, options: SelectItem[]): any {
    if (CustomUtils.isDefined(value) && CustomUtils.isArrayNotEmpty(options)) {
      return CustomUtils.getOptionLabel(value, options);
    } else {
      return null;
    }
  }

}
