import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from "../../../shared/services/auth/auth.service";
import {CustomUtils} from "../../../shared/utils/custom.utils";
import {PermisUsuari} from "../../../shared/models/usuari/usuari.model";
import {LooseObject} from "../../../shared/models/forms/reactive-form-validator";
import {catchError, map} from "rxjs/operators";

interface PermissionGuardConfig {
  permissionsByRoute?: LooseObject<PermisUsuari[]>;
  permissionsNeeded?: PermisUsuari[];
}

@Injectable()
export class PermissionGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.getCurrentUsuari().pipe(map(() => {
      const config: PermissionGuardConfig = next.routeConfig.data;
      if (CustomUtils.isDefined(config.permissionsByRoute)) {
        const foundRoute: string = Object.keys(config.permissionsByRoute).find((r: string) => state.url.indexOf(r) !== -1);
        if (foundRoute) {
          return this.authService.usuariTePermisos(config.permissionsByRoute[foundRoute]['permissionsNeeded']);
        } else {
          return true;
        }
      } else if (CustomUtils.isDefined(config.permissionsNeeded)) {
        return this.authService.usuariTePermisos(config.permissionsNeeded);
      } else {
        return false;
      }
    }), catchError(() => of(false)));
  }

}
