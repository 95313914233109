import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserTableStatesModel} from '../../models/user-table-states.model';
import {Observable} from 'rxjs';
import {BrowserStorageUtils, CacheItemKey} from '../../utils/browser-storage-utils';
import {CacheItem} from '../../models/cache/cache-item.model';
import {CustomUtils} from '../../utils/custom.utils';
import {MapperUtils} from '../../utils/mapper-utils';
import {TableStateModel} from '../../models/table-state.model';

import {UserPreference} from '../../models/cache/user-preference.model';
import {UserPreferenceService} from '../user-preference/user-preference.service';
import {of} from "rxjs/internal/observable/of";
import {map} from "rxjs/operators";

@Injectable()
export class UserTableStateService {

  constructor(private http: HttpClient, private userPreferenceService: UserPreferenceService) {
  }

  /**
   * Checks if {@param userTableStates} is a valid instance of {@link UserTableStatesModel}.
   * @param userTableStates
   * @returns {boolean}
   */
  private static isValidUserTableStates(userTableStates: any): boolean {
    return (MapperUtils.isValidModel(userTableStates, new UserTableStatesModel()) &&
      MapperUtils.isValidModelArray(userTableStates.tableStates, new TableStateModel()));
  }

  /**
   * Retrieves the {@link UserTableStatesModel} from the browser storage.
   * @param {CacheItemKey} cacheItemKey
   * @returns {UserTableStatesModel}
   */
  private static getUserTableStatesFromStorage(cacheItemKey: CacheItemKey): UserTableStatesModel {
    if (cacheItemKey) {
      const cacheItem: CacheItem = BrowserStorageUtils.getCacheItem(cacheItemKey);
      if (MapperUtils.isValidModel(cacheItem, new CacheItem()) && UserTableStateService.isValidUserTableStates(cacheItem.content)) {
        return <UserTableStatesModel>cacheItem.content;
      } else {
        return null;
      }
    } else {
      throw new Error(`missing parameters in getUserTableStatesFromStorage`);
    }
  }

  /**
   * Stores the {@link UserTableStatesModel} in browser storage. If the list of table states is empty removes the storage entry.
   * @param {CacheItemKey} cacheItemKey
   * @param {UserTableStatesModel} userTableStates
   */
  private static saveUserTableStatesInStorage(cacheItemKey: CacheItemKey, userTableStates: UserTableStatesModel): CacheItem {
    if (cacheItemKey && userTableStates) {
      if (CustomUtils.isArrayNotEmpty(userTableStates.tableStates)) {
        return BrowserStorageUtils.setCacheItem(cacheItemKey, cacheItemKey.modelVersion, userTableStates);
      } else {
        BrowserStorageUtils.removeItem(cacheItemKey);
        return null;
      }
    } else {
      throw new Error(`missing parameters in saveUserTableStatesInStorage`);
    }
  }

  /**
   * Retrieves the {@link UserTableStatesModel} for current user. If browser storage does not have it, it will try to get them from the server.
   * @param {CacheItemKey} cacheItemKey
   * @returns {Observable<UserTableStatesModel>}
   */
  getUserTableStates(cacheItemKey: CacheItemKey): Observable<UserTableStatesModel> {
    if (cacheItemKey) {
      let userTableStates: UserTableStatesModel = UserTableStateService.getUserTableStatesFromStorage(cacheItemKey);
      if (userTableStates) {
        return of(userTableStates);
      } else {
        // get from the server
        return this.userPreferenceService.getUserPreference(cacheItemKey.userId, cacheItemKey.key,
          cacheItemKey.modelVersion, cacheItemKey.subKey).pipe(map((userPreference: UserPreference) => {
            // Si el model de l'objecte es vàlid i el "modelVersion" del "userPreference" coincideix amb la "modelVersio" del llistat (cacheItemKey)
          if (MapperUtils.isValidModel(userPreference, new UserPreference()) && userPreference.modelVersion == cacheItemKey.modelVersion) {
            let parsedUserTableStates: UserTableStatesModel = JSON.parse(userPreference.content);
            if (UserTableStateService.isValidUserTableStates(parsedUserTableStates)) {
              UserTableStateService.saveUserTableStatesInStorage(cacheItemKey, parsedUserTableStates);
              return parsedUserTableStates;
            }
          }
          return null;
        }));
      }
    }
    return of(null);
  }

  /**
   * Saves the {@link UserTableStatesModel} in browser storage and server. If the table states list is empty it is deleted instead.
   * @param {UserTableStatesModel} userTableStates
   * @param {CacheItemKey} cacheItemKey
   */
  saveUserTableStates(userTableStates: UserTableStatesModel, cacheItemKey: CacheItemKey): void {
    if (cacheItemKey) {
      let cacheItem: CacheItem = UserTableStateService.saveUserTableStatesInStorage(cacheItemKey, userTableStates);
      if (cacheItem) {
        let userPreferenceToSave: UserPreference = UserPreference.createFromCacheItem(cacheItemKey.userId, cacheItemKey.key, cacheItem, cacheItemKey.subKey);
        this.userPreferenceService.saveUserPreference(userPreferenceToSave).subscribe();
      } else {
        this.userPreferenceService.deleteUserPreference(cacheItemKey.userId, cacheItemKey.key, cacheItemKey.modelVersion, cacheItemKey.subKey).subscribe();
      }
    }
  }

}
