import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {ImportsService} from "./imports/imports.service";
import {ImportsControllerComponent} from "./imports/imports-controller.component";
import {DadesGeneralsControllerComponent} from "./dades-generals/dades-generals-controller.component";
import {IndicadorsControllerComponent} from "./indicadors/indicadors-controller.component";
import {DadesExecucioControllerComponent} from "./dades-execucio/dades-execucio-controller.component";
import {DatesContracteControllerComponent} from "./dates-contracte/dates-contracte-controller.component";
import {DatesContracteService} from "./dates-contracte/dates-contracte.service";
import {IndicadorsControllerService} from "./indicadors/indicadors-controller.service";

@NgModule({
  declarations: [
    ImportsControllerComponent,
    DadesGeneralsControllerComponent,
    IndicadorsControllerComponent,
    DadesExecucioControllerComponent,
    DatesContracteControllerComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    ImportsControllerComponent,
    DadesGeneralsControllerComponent,
    IndicadorsControllerComponent,
    DadesExecucioControllerComponent,
    DatesContracteControllerComponent
  ],
  providers: [
    ImportsService,
    DatesContracteService,
    IndicadorsControllerService
  ]
})
export class DadesBasiquesComponentsModule {
}
