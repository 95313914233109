import {Pipe, PipeTransform} from '@angular/core';
import {coerceBooleanProperty} from '../../utils/coercion/boolean-property';

@Pipe({
  name: 'booleanPositiuNegatiu',
  pure: true
})
export class BooleanPositiuNegatiuPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return coerceBooleanProperty(value) ? 'Positiu' : 'Negatiu';
  }

}
