import {Subscription} from "rxjs";

export class SubscriptionUtils {

  static unsuscribeIfNeeded(sub: Subscription): void {
    if (sub) {
      sub.unsubscribe();
    }
  }

}
