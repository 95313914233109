import {ChangeDetectionStrategy, Component, HostBinding, Input} from "@angular/core";
import {CustomUtils} from "../../shared/utils/custom.utils";

@Component({
  selector: 'geec-table-counter',
  template: `
    <span>{{this._count}} registro/s</span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCountComponent {

  private _count: number = 0;

  @HostBinding('style.display') display: string = 'flex';
  @HostBinding('style.width') width: string = 'max-content';
  @HostBinding('style.font-weight') fontWeight: string = 'bold';
  @HostBinding('style.padding') padding: string = '10px';

  @Input('count') set count(val: number) {
    if (CustomUtils.isDefined(val)) {
      this._count = val;
    }
  }


}

