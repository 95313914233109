import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {GeecErrorService} from "../../../shared/services/geec-error/geec-error.service";
import {environment} from '../../../../environments/environment';
import {EnvironmentType} from "../../../shared/globals";
import {AuthService} from "../../../shared/services/auth/auth.service";
import {TextUtils} from "../../../shared/utils/text-utils";
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';

@Component({
  selector: 'geec-unauthorized',
  styleUrls: ['./unauthorized.component.scss'],
  templateUrl: './unauthorized.component.html'
})

export class UnauthorizedComponent implements AfterViewInit {

  @ViewChild('debugMessage')
  debugMessage: ElementRef;
  errorDate: string;
  browserVersion: string;
  node: string = '';
  message: string = '';
  version: string = '';
  nifUsuari: string;
  traduccions: any;

  constructor(private geecErrorService: GeecErrorService, private _authService: AuthService, private translate: TranslateService) {
    if(geecErrorService.error) {
      this.errorDate = geecErrorService.error.timestamp;
      this.node = geecErrorService.error.node;
      if(environment.type === EnvironmentType.PRE) {
        this.message = btoa(geecErrorService.error.message);
      } else {
        this.message = geecErrorService.error.message;
      }
    }
    this.browserVersion = navigator.appVersion;
    this.version = environment.version;

    if (this._authService.usuari) {
      this.nifUsuari = this._authService.usuari.nif;
    }
    this.initTable();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.initTable();
    });
  }

  public  initTable() {
    this.translate.get('geec-error-page', this.translate.currentLang).subscribe(value => {
      this.traduccions = {
        'fechayhora' : this.translate.instant('geec-error-page.fechayhora'),
        'nif': this.translate.instant('geec-error-page.nif'),
        'endpoint' : this.translate.instant('geec-error-page.endpoint'),
        'mensaje_error': this.translate.instant('geec-error-page.mensaje_error'),

      };
    });
  }

  copiarError(): void {
    TextUtils.copyToClipboard(this.generateErrorText());
  }

  generateErrorText(): string {
    return this.traduccions.fechayhora + this.errorDate + "\r\n" +
      this.traduccions.nif + this.nifUsuari + "\r\n" +
      this.traduccions.endpoint + this.geecErrorService.error.requestUrl + "\r\n" +
      this.traduccions.mensaje_error + this.message;
  }

  ngAfterViewInit() {
    if (this.geecErrorService.error) {
      (<HTMLDivElement>this.debugMessage.nativeElement).innerHTML = `<!-- ${this.geecErrorService.error.debugMessage} -->`;
    }
  }
}
