import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AppConstants} from "../../app.constants";
import {
  FormDefinition,
  FormField,
  FormValidation,
  FormValidationDTO,
  GeecFormDTOModel
} from "../../shared/models/forms/geec-form-dto.model";
import {FrontDTO} from "../../shared/models/forms/front-dto.model";
import {ControllerResponse} from "../../shared/models/forms/controller-response.model";
import {CustomUtils} from "../../shared/utils/custom.utils";
import {map} from "rxjs/operators";
import {ValidationsTriggerType} from "../classes/geec-form.controller";
import {Tasca} from "../../shared/models/bustia/tasca.model";
import {HttpClientUtils} from "../../shared/services/utils/http-client-utils";
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';

export type SendFormMethod = (formName: string, taskId: string, formDTO: any, action: ActionSubmit) => Observable<ControllerResponse<GeecFormDTOModel | Tasca>>;

export enum ActionSubmit {
  NEXT = "next",
  NEXT_AND_NEW = "nextAndNew",
  NEXT_NO_AJ = "nextNoAJ",
  OMIT = "omitir"
}

@Injectable()
export class FormService {

  constructor(private http: HttpClient) {
  }

  private _translate: TranslateService;

  private static fieldNotDefinedOrNotEquals(fieldA: FormField | null, fieldB: FormValidationDTO): boolean {
    return CustomUtils.isUndefinedOrNull(fieldA) || CustomUtils.isDefined(fieldA) && fieldA.name !== fieldB.fieldName;
  }

  public getHybridFormRules(name: string, formObject: FrontDTO, method: ValidationsTriggerType | string, noLoad: boolean = false): Observable<GeecFormDTOModel> {

    let httpParameters: HttpParams = new HttpParams();
    let httpHeaders: HttpHeaders = null;

    if (!formObject.taskId) {
      httpParameters = httpParameters.set("readOnly", "true");
    }
    if (noLoad) {
      httpHeaders = HttpClientUtils.NO_LOAD_HTTP_HEADER;
    }

    return this.http.post<GeecFormDTOModel>(`${AppConstants.formsCollection}/${name}/${method}`, formObject,
      {headers: httpHeaders, params: httpParameters}).pipe(map((res: GeecFormDTOModel) => {
      this.reduceValidations(res);
      return res;
    }));
  }

  reduceValidations(res: GeecFormDTOModel) {
    res.definition.validations.reduce<number>((store: number, currentValue: FormValidationDTO) => {
      let index: number = store;

      if (FormService.fieldNotDefinedOrNotEquals(res.definition.fields[index], currentValue)) {
        index = res.definition.fields.findIndex(field => field.name === currentValue.fieldName);
      }

      if (index === -1) {
        throw Error(this._translate.instant('form-service.indexmenosuno'));
      }

      if (!CustomUtils.HOPAID(res.definition.fields[index], 'validations')) {
        Object.defineProperty(res.definition.fields[index], 'validations', {value: [], enumerable: true});
      }

      res.definition.fields[index].validations.push(new FormValidation(currentValue.validation, currentValue.parameters));

      return index;
    }, 0);
  }

  public getHybridFormChildRules(name: string, childName: string, formModel: Object, method: string): Observable<FormDefinition> {
    return this.http.post<FormDefinition>(`${AppConstants.formsCollection}/${name}/${childName}/${method}`, formModel).pipe(map((res: FormDefinition) => {
      res.validations.reduce<number>((store: number, currentValue: FormValidationDTO) => {
        let index: number = store;

        if (FormService.fieldNotDefinedOrNotEquals(res.fields[index], currentValue)) {
          index = res.fields.findIndex(field => field.name === currentValue.fieldName);
        }

        if (!CustomUtils.HOPAID(res.fields[index], 'validations')) {
          Object.defineProperty(res.fields[index], 'validations', {value: [], enumerable: true});
        }

        res.fields[index].validations.push(new FormValidation(currentValue.validation, currentValue.parameters));

        return index;
      }, 0);

      return res;
    }));
  }

  public formSubmit(formName: string, taskId: string, formDTO: any, action?: ActionSubmit, autoritza?: boolean): Observable<ControllerResponse<GeecFormDTOModel | Tasca>> {
    if (action || autoritza) {
      return this.submitNext(formName, taskId, formDTO, action, autoritza);
    } else {
      return this.submitSave(formName, taskId, formDTO);
    }
  }

  public submitNext(formName: string, taskId: string, formDTO: any, action: ActionSubmit = ActionSubmit.NEXT, autoritza: boolean = false): Observable<ControllerResponse<GeecFormDTOModel | Tasca>> {
    let httpParams: HttpParams = null;
    if (!CustomUtils.isUndefinedOrNull(autoritza)) {
      httpParams = new HttpParams({fromObject: {"autoritza": autoritza.toString()}});
    }
    return this.http.post<ControllerResponse<GeecFormDTOModel | Tasca>>(`${AppConstants.formsCollection}/${formName}/${taskId}/${action}`, formDTO, {params: httpParams});
  }

  public submitSave(formName: string, taskId: string, formDTO: any): Observable<ControllerResponse<GeecFormDTOModel>> {
    return this.http.put<ControllerResponse<GeecFormDTOModel>>(`${AppConstants.formsCollection}/${formName}/${taskId}`, formDTO);
  }

  public reassignarTascaUnitatPromotora(taskId: string): Observable<ControllerResponse<boolean>> {
    return this.http.post<ControllerResponse<boolean>>(`${AppConstants.urlReassignarTasca}/reassignarAdjudicacioPromotora/${taskId}`, null);
  }

  public desadjudicarFiscalitzacio(taskId: string): Observable<ControllerResponse<boolean>> {
    return this.http.post<ControllerResponse<boolean>>(`${AppConstants.urlDocumentComptable}/desadjudicarFiscalitzacio/${taskId}`, null);
  }

  public formUnlock(expedientId: number, taskId: string): Observable<string> {
    return this.http.delete<string>(`${AppConstants.urlTaskCacheCollection}/${expedientId}/${taskId}`);
  }

  public formLock(formDto: string, expedientId: number, taskId: string): Observable<ControllerResponse<void>> {
    return this.http.get<ControllerResponse<void>>(`${AppConstants.urlTaskCacheCollection}/blockTask/${formDto}/${expedientId}/${taskId}`);
  }
}
