export const iconMap: Map<string, string> = new Map<string, string>([
  ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'fa fa-file-word-o'],
  ['application/vnd.openxmlformats-officedocument.wordprocessingml.template', 'fa fa-file-word-o'],
  ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'fa fa-file-excel-o'],
  ['application/pdf', 'fa fa-file-pdf-o'],
  ['application/vnd.ms-outlook', 'fa fa-envelope-o'],
  ['application/zip', 'fa fa-file-archive-o'],
  ['image/jpeg', 'fa fa-file-image-o'],
  ['image/bmp', 'fa fa-file-image-o'],
  ['image/png', 'fa fa-file-image-o']
]);

export interface ValidacioSignatura {
  data: Date;
  nomSignadorNoCodificat: string;
}

export enum TramitacioNodeType {
  expedient = "EXPEDIENT",
  taskForm = "TASK_FORM",
  taskDocument = "TASK_DOCUMENT",
  taskDocumentComptable = "TASK_DOCUMENT_COMPTABLE",
  taskAjustament = "TASK_AJUSTAMENT",
  factura = "FACTURA",
  fase = "FASE",
  documentComptable = "DOCUMENT_COMPTABLE",
  documentGefact = "DOCUMENT_GEFACT",
  taskPscp = "TASK_PSCP",
  taskGlic = "TASK_GLIC",
  documentSistemaExtern = "DOCUMENT_SISTEMA_EXTERN",
  taskEnviarSNT = "TASK_ENVIAR_SNT",
  raju = "RAJU",
  taskEnviamentCorreu = "TASK_ENVIAMENT_CORREU",
  historicEnviamentCorreu = "HISTORIC_ENVIAMENT_CORREU",
  evidenciesPscp = "EVIDENCIES_PSCP",
  modificacio = "MODIFICACIO",
  sbem = "SBEM",
  integracioComptable = "TASK_INTEGRACIO_COMPTABLE",
  taskDocumentComptableSenseIntegracio = "TASK_DOCUMENT_COMPTABLE_SENSE_INTEGRACIO",
  taskDocumentComptableEcofin = "TASK_DOCUMENT_COMPTABLE_ECOFIN",
  manament = "MANAMENT",
  sboc = "SBOC",
  desistimiento = "DESISTIMIENTO",
  renuncia = "RENUNCIA"
}

export enum TramitacioNodeAction {
  reobrir = "reobrir",
  veure = "veure",
  copy = "copy",
  version = "version",
  retornar = "retornar",
  motiu = "motiu",
  deleteOpcional = "deleteOpcional",
  veureDocumentsFactura = "veureDocumentsFactura",
  obrirDocumentSistemaExtern = "obrirDocumentSistemaExtern",
  download = "download",
  anularActuacio = "anularActuacio",
  veureArbre = "veureArbre",
  downloadFacturaGefact = "downloadFacturaGefact",
  traspasSenseIntegracio = "traspasSenseIntegracio"
}

export interface ButtonSettings {
  key: TramitacioNodeAction;
  labelButton: string;
  iconButton: string;
  callbackData?: any;
}

export enum TramitacioNodeStates {
  unassigned = "UNASSIGNED",
  assigned = "ASSIGNED",
  complete = "COMPLETE",
  canceled = "CANCELED",
  proces_baixa = "EN_PROCES_BAIXA",
  anulat = "ANULAT",
  inactive = "INACTIVE",
  en_tramit = "EN_TRAMIT"
}

export enum EstatActuacioContractual {
  EN_TRAMIT = "EN_TRAMIT",
  FINALITZADA = "FINALITZADA",
  ANULADA = "ANULADA"
}

export enum EstatManament {
  PRELIMINAR = 11667,
  COMPTABILITZAT = 11668,
  SUPRIMIT = 11669
}
