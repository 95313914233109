import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Subject} from 'rxjs';
import {AuthService} from '../../../shared/services/auth/auth.service';

import {Usuari} from '../../../shared/models/usuari/usuari.model';
import {takeUntil} from "rxjs/operators";
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'geec-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Output()
  changeMenuActive = new EventEmitter<void>();

  title = 'GECE';
  version = environment.version;
  usuariActual: Usuari;


  constructor(private usuariService: AuthService, private translate: TranslateService) {
    translate.addLangs(['es', 'ca']);
    translate.setDefaultLang('es');
    translate.use('es');
    //const browserLang = translate.getBrowserLang();
    //translate.use(browserLang.match(/es/) ? browserLang : 'es');

  }

  ngOnInit() {
    this.usuariService.currentUsuari$.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((usuari) => {
        this.usuariActual = usuari;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get nomCompletUsuari() {
    if (this.usuariActual.cognoms) {
      return `${this.usuariActual.nom} ${this.usuariActual.cognoms}`;
    } else {
      return this.usuariActual.nom;
    }
  }

  changeMenu() {
    this.changeMenuActive.emit();
  }

  logout() {
    this.usuariService.logout();
  }

}
