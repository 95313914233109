import {LooseObject} from "../../shared/models/forms/reactive-form-validator";
import {TramitacioNode} from "./tramitacio-node";
import {CustomUtils} from "../../shared/utils/custom.utils";
import {format} from "date-fns";

export class TramitacioVersioSignatura extends TramitacioNode {

  constructor(backendJSON: LooseObject<any>) {
    super(backendJSON);
    this.nom = this.getConcatNom(backendJSON);
    this.selectable = false;
    this.icon = "fa fa-pencil";
  }

  getConcatNom(backendJSON: LooseObject<any>): string {
    let concatNom: string = `${backendJSON.nomSignador}`;
    if (backendJSON.accio && backendJSON.accio.id === 522) {
      // TODO: Setejar variable global amb l'idioma al inici de l'aplicació i utilitzar aquí
      concatNom += ` Firma`;
    } else if (backendJSON.accio && backendJSON.accio.id === 523) {
      concatNom += ` Visto bueno`;
    }
    if (backendJSON.estatValidacio && backendJSON.estatValidacio.id === 540) {
      concatNom += ` Firma realizada`;
    } else if (backendJSON.estatValidacio && backendJSON.estatValidacio.id === 541) {
      concatNom += ` Visto bueno realizado`;
    }

    if (backendJSON.data) {
      concatNom += ` ${this.getFormatDataHora(backendJSON.data)}`;
    }
    return concatNom;
  }

  getFormatDataHora(data: number): string {
    if (CustomUtils.isDefined(data)) {
      return format(data, "DD-MM-YYYY HH:mm:ss");
    }
  }
}
