import {Injectable} from '@angular/core';
import {AppConstants, TaskKey} from '../../../app.constants';
import {AutoCompleteConfig} from '../utils/auto-complete-config';
import {CustomUtils} from '../../utils/custom.utils';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SelectOptionCodiModel} from '../../models/select-option-codi.model';
import {LooseObject} from "../../models/forms/reactive-form-validator";
import {TableColumn, TableColumnType} from "../../interfaces/table-column";
import {Ecofin} from "../../models/expedient/expedient.model";
import {GeecFormGroup} from "../../../core/directives/reactive-forms/geec-form-group";
import {GeecFormBuilder} from "../../../core/directives/reactive-forms/geec-form-builder";
import {PartidaPressupostariaFormValidatorModel} from "../../standalone/components/partides/models/partida-pressupostaria-form-validator.model";
import {PartidaPressupostariaFormModel} from "../../standalone/components/partides/models/partida-pressupostaria-form.model";
import {NumberUtils} from "../../utils/number-utils";
import {SelectItemData, TaskableType} from "../../models/forms/geec-form-dto.model";
import {TipusIva} from "../../interfaces/expedient/tipus-iva";
import {TascaInfo} from "../../models/forms/tasca-info";
import {TipusPartidaEcom} from "../../../ecom/dades-basiques-ecom/models/tipus-partida-ecom";
import {ControllerResponse} from "../../models/forms/controller-response.model";
import {DadesEconomiquesSharedFormModel} from "../../models/forms/dades-basiques/dades-basiques-shared-form.model";
import {createArray} from "../../utils/creation/create-array";
import {TranslateService} from "@ngx-translate/core";

export interface PartidaLabels {
  importLicitacio: string;
  importLicitacioIva: string;
  importAdjudicacio: string;
  importAdjudicacioIva: string;
  reserva: string;
  disposicio: string;
  tipusIvaAdjudicacio: string;
  tipusIvaLicitacio: string;
  subtotalLicitacio: string;
  subtotalAdjudicacio: string;
  partidaPressupostaria: string;
}

@Injectable()
export class PartidaPressupostariaService {

  traduccionesColumnas: any = {};
  traduccionesLabels: any = {};
  constructor(private http: HttpClient, private _gfb: GeecFormBuilder,
              private _translate: TranslateService) {
    this.traducirColumnasYLabels();
    this._translate.onLangChange.subscribe(() => {
      this.traducirColumnasYLabels();
    });
  }

  private traducir(tag: string): string {
    let ruta = 'partida-pressupostaria-service';
    return this._translate.instant(ruta + '.' + tag);
  }

  traducirColumnasYLabels() {
    this.traduccionesColumnas = {
      entitatPeticionaria: this.traducir('column-entidad-peticionaria'),
      codiEntitatPeticionaria: this.traducir('column-codigo-entidad-peticionaria'),
      partidaPressupostaria: this.traducir('column-partida-presupostaria'),
      partidaPressupostariaSenseIntegracio: this.traducir('column-partida-presupostaria-sin-integracion'),
      centreGestor: this.traducir('column-centro-gestor'),
      centreGestorSenseIntegracio: this.traducir('column-centro-gestor-sin-integracion'),
      financamentAfectat: this.traducir('column-financiamiento-afectado'),
      compteComptable: this.traducir('column-cuenta-contable'),
      codiPrograma: this.traducir('column-codigo-programa'),
      centreCost: this.traducir('column-centro-coste'),
      servei: this.traducir('column-servicio'),
      conveni: this.traducir('column-convenio'),
      any: this.traducir('column-anyo'),
      importLicitacio: this.traducir('column-importe-licitacion'),
      importAdjudicacio: this.traducir('column-importe-adjudicacion'),
      tipusIvaLicitacio: this.traducir('column-tipo-IVA-licitacion'),
      tipusIvaAdjudicacio: this.traducir('column-tipo-IVA-adjudicacion'),
      IVAImpost: this.traducir('column-IVA-impuesto'),
      importLicitacioIva: this.traducir('column-importe-licitacion-IVA'),
      importAdjudicacioIva: this.traducir('column-importe-adjudicacion-IVA'),
      codiPEP: this.traducir('column-codigo-PEP'),
      codiTerritori: this.traducir('column-codigo-territorio'),
      importReserva: this.traducir('column-importe-reserva'),
      importDisposicio: this.traducir('column-importe-disposicion'),
      importRemanent: this.traducir('column-importe-remanente'),
      adjudicacio: this.traducir('column-adjudicacion'),
      adjudicacioPosicio: this.traducir('column-adjudicacion-posicion'),
      reserva: this.traducir('column-reserva'),
      reservaPosicio: this.traducir('column-reserva-posicion'),
      disposicio: this.traducir('column-disposicion'),
      disposicioPosicio: this.traducir('column-disposicion-posicion')
    };
    this.traduccionesLabels = {
      reserva: this.traducir('label-reserva'),
      comanda: this.traducir('label-comanda'),
      codiDocumentReserva: this.traducir('label-codigo-document-reserva'),
      codiDocumentDisposicio: this.traducir('label-codigo-document-disposicio'),
      importProposta: this.traducir('label-importe-proposta'),
      importPropostaIva: this.traducir('label-importe-proposta-iva'),
      prorroga: this.traducir('label-importe-prorroga'),
      prorrogaIva: this.traducir('label-importe-prorroga-iva'),
      propostaModificacio: this.traducir('label-importe-proposta-modificacion'),
      propostaModificacioIva: this.traducir('label-importe-proposta-modificacion-iva'),
      modificacio: this.traducir('label-importe-modificacio'),
      modificacioIva: this.traducir('label-importe-modificacio-iva'),
      importMinorar: this.traducir('label-importe-minorar'),
      importMinorarIva: this.traducir('label-importe-minorar-iva'),
      ivaAMinorar: this.traducir('label-iva-a-minorar'),
      import: this.traducir('label-importe'),
      IVA: this.traducir('label-IVA'),
      importIva: this.traducir('label-importe-IVA')
    };
  }

  public static importDadesEconomiques(data: DadesEconomiquesSharedFormModel, partidesForm: GeecFormGroup, partidesPeticionariesForm: GeecFormGroup, taskKey: string): void {
    if (CustomUtils.isT1OrModT1(taskKey)) {
      partidesForm.setValue(data.partidesPressupostaries);
      partidesPeticionariesForm.setValue(data.partidesPeticionaries);
    } else {
      PartidaPressupostariaService._updateDadesEconomiques(partidesForm, data.partidesPressupostaries, taskKey, false);
      PartidaPressupostariaService._updateDadesEconomiques(partidesPeticionariesForm, data.partidesPeticionaries, taskKey, true);
    }
  }

  private static _updateDadesEconomiques(partidesForm: GeecFormGroup, partidesNoves: PartidaPressupostariaFormModel[], taskKey: string, isPartidaPeticionaria: boolean): void {
    const partides: PartidaPressupostariaFormModel[] = createArray(partidesForm.value);
    const isProT1: boolean = CustomUtils.isTask(taskKey, TaskKey.PRO_T1);
    partidesNoves.forEach((partidaNova: PartidaPressupostariaFormModel) => {
      const partida = partides.find((partidaOriginal: PartidaPressupostariaFormModel) => CustomUtils.areDefined(partidaOriginal.id, partidaNova.id) && partidaOriginal.id === partidaNova.id);
      if (CustomUtils.isDefined(partida)) {
        if (isProT1) {
          partida.importLicitacio = partidaNova.importLicitacio;
          partida.tipusIvaLicitacio = partidaNova.tipusIvaLicitacio;
          partida.importLicitacioIva = partidaNova.importLicitacioIva;
        } else {
          partida.importAdjudicacio = partidaNova.importAdjudicacio;
          partida.tipusIvaAdjudicacio = partidaNova.tipusIvaAdjudicacio;
          partida.importAdjudicacioIva = partidaNova.importAdjudicacioIva;
          if (isPartidaPeticionaria) {
            partida.disposicio = partidaNova.disposicio;
          }
        }
      } else if (isProT1) {
        partides.push(partidaNova);
      }
    });
    partidesForm.setValue(partides);
  }

  public buildForm(): GeecFormGroup {
    return this._gfb.group({
      id: [null],
      entitatPeticionariaId: [null],
      partidaPressupostaria: [null],
      centreGestor: [null],
      financamentAfectat: [null],
      compteComptable: [null],
      codiPrograma: [null],
      centreCost: [null],
      servei: [null],
      conveni: [null],
      any: [null],
      tipusIvaLicitacio: [null],
      tipusIvaAdjudicacio: [null],
      importLicitacio: [null],
      ivaImposable: [null],
      importLicitacioIva: [null],
      codiPEP: [null],
      codiTerritori: [null],
      importAdjudicacio: [null],
      importAdjudicacioIva: [null],
      importReserva: [null],
      reserva: [null],
      reservaPosicio: [null],
      adjudicacio: [null],
      adjudicacioPosicio: [null],
      importDisposicio: [null],
      disposicio: [null],
      disposicioPosicio: [null],
      estat: [null],
      importRomanent: [null],
      idDadaEconomicaOrigen: [null],
      editable: [true],
      partidaPressupostariaSenseIntegracio: [null],
      centreGestorSenseIntegracio: [null],
      codiEntitatPeticionaria: [null],
      idDadaEconomicaRelacioEcom: [null],
      isGecat: [null],
      propio: [null]
    });
  }

  public getColumns(tascaInfo: TascaInfo, templateValidations: PartidaPressupostariaFormValidatorModel,
                    tipusPartidaEcom: string, propio: boolean = true): TableColumn[] {
    const labels: PartidaLabels = this.getPartidaLabels(tascaInfo, tipusPartidaEcom, propio);

    const columns = [
      {field: "entitatPeticionariaId", header: this.traduccionesColumnas.entitatPeticionaria, width: "15em", type: TableColumnType.AUTOCOMPLETE},
      {field: "codiEntitatPeticionaria", header: this.traduccionesColumnas.codiEntitatPeticionaria, width: "15em", type: TableColumnType.TEXT},
      {field: "partidaPressupostaria", header: this.traduccionesColumnas.partidaPressupostaria, width: "15em", type: TableColumnType.AUTOCOMPLETE},
      {field: "partidaPressupostariaSenseIntegracio", header: this.traduccionesColumnas.partidaPressupostariaSenseIntegracio, width: "15em", type: TableColumnType.TEXT},
      {field: "centreGestor", header: this.traduccionesColumnas.centreGestor, width: "10em", type: TableColumnType.AUTOCOMPLETE},
      {field: "centreGestorSenseIntegracio", header: this.traduccionesColumnas.centreGestorSenseIntegracio, width: "10em", type: TableColumnType.TEXT},
      {field: "financamentAfectat", header: this.traduccionesColumnas.financamentAfectat, width: "16em", type: TableColumnType.TEXT},
      {field: "compteComptable", header: this.traduccionesColumnas.compteComptable, width: "12em", type: TableColumnType.TEXT},
      {field: "codiPrograma", header: this.traduccionesColumnas.codiPrograma, width: "10em", type: TableColumnType.TEXT},
      {field: "centreCost", header: this.traduccionesColumnas.centreCost, width: "10em", type: TableColumnType.TEXT},
      {field: "servei", header: this.traduccionesColumnas.servei, width: "10em", type: TableColumnType.TEXT},
      {field: "conveni", header: this.traduccionesColumnas.conveni, width: "10em", type: TableColumnType.TEXT},
      {field: "any", header: this.traduccionesColumnas.any, width: "5em", type: TableColumnType.INTEGER},
      {field: "importLicitacio", header: labels.importLicitacio, width: "13em", type: TableColumnType.DECIMAL},
      {field: "importAdjudicacio", header: labels.importAdjudicacio, width: "12em", type: TableColumnType.DECIMAL},
      {field: "tipusIvaLicitacio", header: labels.tipusIvaLicitacio, width: "13em", type: TableColumnType.SELECT},
      {field: "tipusIvaAdjudicacio", header: labels.tipusIvaAdjudicacio, width: "13em", type: TableColumnType.SELECT},
      {field: "ivaImposable", header: this.traduccionesColumnas.ivaImposable, width: "13em", type: TableColumnType.DECIMAL},
      {field: "importLicitacioIva", header: labels.importLicitacioIva, width: "16em", type: TableColumnType.DECIMAL},
      {field: "importAdjudicacioIva", header: labels.importAdjudicacioIva, width: "16em", type: TableColumnType.DECIMAL},
      {field: "codiPEP", header: this.traduccionesColumnas.codiPEP, width: "8em", type: TableColumnType.TEXT},
      {field: "codiTerritori", header: this.traduccionesColumnas.codiTerritori, width: "10em", type: TableColumnType.TEXT},
      {field: "importReserva", header: this.traduccionesColumnas.importReserva, width: "10em", type: TableColumnType.DECIMAL},
      {field: "importDisposicio", header: this.traduccionesColumnas.importDisposicio, width: "12em", type: TableColumnType.DECIMAL},
      {field: "importRomanent", header: this.traduccionesColumnas.importRemanent, width: "11em", type: TableColumnType.DECIMAL},
      {field: "adjudicacio", header: this.traduccionesColumnas.adjudicacio, width: "8em", type: TableColumnType.TEXT},
      {field: "adjudicacioPosicio", header: this.traduccionesColumnas.adjudicacioPosicio, width: "12em", type: TableColumnType.TEXT},
      {field: "reserva", header: labels.reserva, width: "20em", type: TableColumnType.TEXT},
      {field: "reservaPosicio", header: `${labels.reserva}` + this.traduccionesColumnas.reservaPosicio, width: "23em", type: TableColumnType.TEXT},
      {field: "disposicio", header: labels.disposicio, width: "20em", type: TableColumnType.TEXT},
      {field: "disposicioPosicio", header: `${labels.disposicio}` + this.traduccionesColumnas.disposicioPosicio, width: "24em", type: TableColumnType.TEXT}
    ];
    return columns.filter((column: TableColumn) => templateValidations[column.field] && templateValidations[column.field].visible);
  }

  public getPartidaLabels(tascaInfo: TascaInfo, tipusPartidaEcom: string, propio: boolean = true): PartidaLabels {
    const labels: PartidaLabels = {
      importLicitacio: this.traduccionesColumnas.importLicitacio,
      importLicitacioIva: this.traduccionesColumnas.importLicitacioIva,
      importAdjudicacio: this.traduccionesColumnas.importAdjudicacio,
      importAdjudicacioIva: this.traduccionesColumnas.importAdjudicacioIva,
      reserva: this.traduccionesColumnas.reserva,
      disposicio: this.traduccionesColumnas.disposicio,
      tipusIvaAdjudicacio: this.traduccionesColumnas.tipusIvaAdjudicacio,
      tipusIvaLicitacio: this.traduccionesColumnas.tipusIvaLicitacio,
      subtotalLicitacio: "Subtotal licitación",
      subtotalAdjudicacio: "Subtotal adjudicación",
      partidaPressupostaria: "Partida presupuestaria"
    };
    if (Ecofin.CTTI_ID === tascaInfo.expedient.integracioOrigenId || Ecofin.ICSOL_ID === tascaInfo.expedient.integracioOrigenId) {
      labels.reserva = this.traduccionesLabels.reserva;
      labels.disposicio = this.traduccionesLabels.comanda;
    }
    if (!propio) {
      labels.reserva = this.traduccionesLabels.codiDocumentReserva;
      labels.disposicio = this.traduccionesLabels.codiDocumentDisposicio;
    }
    if (tascaInfo.expedient.isProrroga) {
      labels.importLicitacio = this.traduccionesLabels.importProposta;
      labels.importLicitacioIva = this.traduccionesLabels.importPropostaIva;
      labels.importAdjudicacio = this.traduccionesLabels.prorroga;
      labels.importAdjudicacioIva = this.traduccionesLabels.prorrogaIva;
    } else if (tascaInfo.taskeable.type === TaskableType.MODIFICACIO) {
      labels.importLicitacio = this.traduccionesLabels.propostaModificacio;
      labels.importLicitacioIva = this.traduccionesLabels.propostaModificacioIva;
      labels.importAdjudicacio = this.traduccionesLabels.modificacio;
      labels.importAdjudicacioIva = this.traduccionesLabels.modificacioIva;
    } else if (tascaInfo.taskeable.type === TaskableType.ECOM) {
      labels.importLicitacio = this.traduccionesLabels.importMinorar;
      labels.importLicitacioIva = this.traduccionesLabels.importMinorarIva;
      labels.tipusIvaLicitacio = this.traduccionesLabels.ivaAMinorar;
      if (CustomUtils.isDefined(tipusPartidaEcom) && tipusPartidaEcom === TipusPartidaEcom.DADES_ECONOMIQUES) {
        labels.importAdjudicacio = this.traduccionesLabels.import;
        labels.tipusIvaAdjudicacio = this.traduccionesLabels.IVA;
        labels.importAdjudicacioIva = this.traduccionesLabels.importIva;
      }
    }
    if (CustomUtils.isDefined(tascaInfo.tasca) && CustomUtils.isTask(tascaInfo.tasca.keyTask, TaskKey.DC_EXT_T1)) {
      labels.importAdjudicacio = "Importe";
      labels.tipusIvaAdjudicacio = "IVA";
      labels.importAdjudicacioIva = "Importe con IVA";
    }
    return labels;
  }

  getPartida(id: number): Observable<SelectOptionCodiModel> {
    return this.http.get<SelectOptionCodiModel>(AppConstants.urlGetPartidesPressupostaries + "/" + id);
  }

  getEntitatPeticionaria(id: number): Observable<SelectOptionCodiModel> {
    return this.http.get<SelectOptionCodiModel>(AppConstants.urlGetEntitats + "/entitat/" + id);
  }

  getPartidesPressupostaries(searchText: string, config: AutoCompleteConfig, integracioOrigenId?: Ecofin): Observable<SelectOptionCodiModel[]> {
    let paramsMap: LooseObject<string> = {};
    if (searchText) {
      paramsMap["like"] = searchText;
    }
    if (config) {
      paramsMap = AutoCompleteConfig.loadConfig(paramsMap, config);
    }
    if (integracioOrigenId) {
      paramsMap["ecofin"] = integracioOrigenId.toString();
    }
    let params: HttpParams = new HttpParams({fromObject: paramsMap});
    if (config && !CustomUtils.isUndefinedOrNull(config.loading) && !config.loading) {
      return this.http.get<SelectOptionCodiModel[]>(AppConstants.urlGetPartidesPressupostaries, {
        headers: new HttpHeaders().set('no-load', 'false'),
        params: params,
      });
    } else {
      return this.http.get<SelectOptionCodiModel[]>(AppConstants.urlGetPartidesPressupostaries, {
        params: params
      });
    }
  }

  /**
   * Recalculates importLicitacio, importLicitacioIva, importAdjudicacioIva and ivaImposable fields
   */
  recalculateImportsLicitacio(partida: PartidaPressupostariaFormModel, tipusIvaLicitacio: number, tipusIvaData: SelectItemData[]): void {
    if (tipusIvaLicitacio != null && tipusIvaLicitacio !== TipusIva.IVA_N4) {
      partida.tipusIvaLicitacio = tipusIvaLicitacio;
      const tipusIvaPercentLicitacio: number = NumberUtils.getTipusIvaPercent(partida.tipusIvaLicitacio, tipusIvaData);
      partida.importLicitacioIva = NumberUtils.calculateImportIva(partida.importLicitacio, tipusIvaPercentLicitacio);

      // TODO: Iva Imposable ?????????????????????????????
      partida.ivaImposable = NumberUtils.calculateBaseImposable(partida.importLicitacio, partida.importLicitacioIva);
    }
  }

  recalculateImportsAdjudicacio(partida: PartidaPressupostariaFormModel, tipusIvaAdjudicacio: number, tipusIvaData: SelectItemData[]): void {
    if (tipusIvaAdjudicacio != null && tipusIvaAdjudicacio !== TipusIva.IVA_N4) {
      partida.tipusIvaAdjudicacio = tipusIvaAdjudicacio;
      const tipusIvaPercentAdjudicacio: number = NumberUtils.getTipusIvaPercent(partida.tipusIvaAdjudicacio, tipusIvaData);
      partida.importAdjudicacioIva = NumberUtils.calculateImportIva(partida.importAdjudicacio, tipusIvaPercentAdjudicacio);
    }
  }

  importarDadesEconomiques(bytes: string, taskKey: string, taskeableId: number): Observable<ControllerResponse<DadesEconomiquesSharedFormModel>> {
    const httpParameters: HttpParams = new HttpParams({fromObject: {'taskeableId': taskeableId.toString()}});
    return this.http.post<ControllerResponse<DadesEconomiquesSharedFormModel>>(`${AppConstants.urlGetPartidesPressupostaries}/import/${taskKey}`, {csv: bytes}, {params: httpParameters});
  }
}
