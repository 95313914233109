import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {CustomUtils} from "../utils/custom.utils";
import {NumberUtils} from "../utils/number-utils";
import {isAfter, isBefore} from "date-fns";

export class GeecValidators extends Validators {

  public static validURL(control: AbstractControl): ValidationErrors | null {
    if (CustomUtils.isEmptyInputValue(control.value) || GeecValidators.isURLFormat(control.value)) {
      return null;
    }

    return {'validURL': true};
  }


  public static year(control: AbstractControl): ValidationErrors | null {
    if (CustomUtils.isEmptyInputValue(control.value)) {
      return null;
    }

    let v: number = +control.value;
    return v <= 9999 && v >= 1970 ? null : {'year': true};
  }

  public static nif(control: AbstractControl): ValidationErrors | null {
    if (CustomUtils.isEmptyInputValue(control.value) || GeecValidators.validateSpanishId(control.value)) {
      return null;
    }

    return {'nif': true};
  }

  public static nifPersonaFisica(control: AbstractControl): ValidationErrors | null {
    if (CustomUtils.isEmptyInputValue(control.value) || GeecValidators.validDNI(control.value)) {
      return null;
    }

    return {'nif': true};
  }

  public static nifPersonaJuridica(control: AbstractControl): ValidationErrors | null {
    if (CustomUtils.isEmptyInputValue(control.value) || GeecValidators.validCIF(control.value)) {
      return null;
    }

    return {'nif': true};
  }

  public static nie(control: AbstractControl): ValidationErrors | null {
    if (CustomUtils.isEmptyInputValue(control.value) || GeecValidators.validNIE(control.value)) {
      return null;
    }

    return {'nie': true};
  }


  public static noWhitespaceRequired(control: AbstractControl): ValidationErrors | null {
    if (!CustomUtils.isEmptyInputValue(control.value) && CustomUtils.isString(control.value) && control.value.trim().length) {
      return null;
    } else {
      return {noWhitespaceRequired: true};
    }
  }

  public static multipleCheckboxRequireOne(): ValidatorFn {
    return (fa: FormGroup): ValidationErrors | null => {
      let valid = false;
      const controlKeys = Object.keys(fa.controls);
      for (let j = 0; j < controlKeys.length; j++) {
        if (fa.get(controlKeys[j]).value) {
          valid = true;
          break;
        }
      }
      return valid ? null : {'multipleCheckboxRequireOne': true};
    };
  }

  public static range(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let res: ValidationErrors = null;
      if (!CustomUtils.isEmptyInputValue(control.value)) {
        const value: number = +control.value;
        const formattedMin: string = NumberUtils.formatImport(min, 0, 2);
        const formattedMax: string = NumberUtils.formatImport(max, 0, 2);
        if ((value < min) || (value > max)) {
          res = {
            range: {
              'min': formattedMin,
              'max': formattedMax,
              'actualValue': value
            }
          };
        }
      }
      return res;
    };
  }

  public static requiredOtherField(field: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const group: AbstractControl = control.parent;
      if (group && (group instanceof FormGroup)) {
        let otherField: AbstractControl = group.get(field);
        if (otherField) {
          if (!control.value && otherField.value) {
            return {'requiredOtherField': true};
          }
        }
      }
      return null;
    };
  }

  public static dateBefore(field: string, suffix: string = ""): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let validation: ValidationErrors | null = null;
      const group: AbstractControl = control.parent;
      if (group && (group instanceof FormGroup)) {
        let otherField: AbstractControl = group.get(field);
        if (otherField) {
          if (CustomUtils.isDefined(control.value) && CustomUtils.isDefined(otherField.value)) {
            if (!isBefore(control.value, otherField.value)) {
              validation = {
                dateBefore: {
                  suffix: suffix
                }
              };
            }
          }
        }
        return validation;
      }
    };
  }

  public static dateAfter(field: string, suffix: string = ""): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let validation: ValidationErrors | null = null;
      const group: AbstractControl = control.parent;
      if (group && (group instanceof FormGroup)) {
        let otherField: AbstractControl = group.get(field);
        if (otherField) {
          if (CustomUtils.isDefined(control.value) && CustomUtils.isDefined(otherField.value)) {
            if (!isAfter(control.value, otherField.value)) {
              validation = {
                dateAfter: {
                  suffix: suffix
                }
              };
            }
          }
        }
        return validation;
      }
    };
  }

  public static postalCode(control: AbstractControl): ValidationErrors | null {
    if (CustomUtils.isEmptyInputValue(control.value)) {
      // don't validate empty values
      return null;
    }

    let v: number = +control.value;
    // un numero postal vàlid ha de ser un número entre 1001 i 53000
    return v >= 1001 && v <= 53000 ? null : {postalCode: true};
  }

  public static multiSelectOnlyOne(control: AbstractControl): ValidationErrors | null {
    if (CustomUtils.isArrayNotEmpty(control.value) && ((<Array<any>>control.value).length === 1)) {
      return null;
    } else {
      return {multiSelectOnlyOne: true};
    }
  }

  public static minArray(min: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let res: ValidationErrors = null;
      if (!CustomUtils.isEmptyInputValue(control.value)) {
        res = ((<any[]>control.value).length >= min) ? null : {minArray: {min: min}};
      }
      return res;
    };
  }

  public static minLowercase(min: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let res: ValidationErrors = null;
      if (!CustomUtils.isEmptyInputValue(control.value) && CustomUtils.isString(control.value)) {
        const str: string = control.value.replace(/\s/g, '');
        const justCharsCount: number = str.length - str.replace(/\D/g, '').length;
        const lowerCaseQuantity: number = GeecValidators.getLowercaseCount(str) / justCharsCount;
        if (lowerCaseQuantity < (min / 100)) {
          res = {minLowercase: {min: min}};
        }
      }
      return res;
    };
  }

  public static initEndNoWhitespace(control: AbstractControl): ValidationErrors | null {
    if (CustomUtils.isEmptyInputValue(control.value) || !CustomUtils.isString(control.value) || GeecValidators.isStringInitEndNoWhitespace(control.value)) {
      return null;
    } else {
      return {initEndNoWhitespace: true};
    }
  }

  public static noWhitespaces(control: AbstractControl): ValidationErrors | null {
    if (CustomUtils.isEmptyInputValue(control.value) || !CustomUtils.isString(control.value) || GeecValidators.isStringNoWhitespace(control.value)) {
      return null;
    } else {
      return {noWhitespace: true};
    }
  }

  private static isStringInitEndNoWhitespace(value: string): boolean {
    return value.length === value.trim().length;
  }

  private static isStringNoWhitespace(value: string): boolean {
    return value.length === value.replace(/\s/g, "").length;
  }

  private static getLowercaseCount(text: string): number {
    let count: number = 0;

    for (let i = 0; i < text.length; i++) {
      if (text[i] >= 'a' && text[i] <= 'z') {
        count++;
      }
    }

    return count;
  }

  public static isURLFormat(text: string): boolean {
    let regexp: RegExp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    return regexp.test(text);
  }

  private static validateSpanishId(str: string): boolean {
    const DNI_REGEX: RegExp = /^(\d{8})([A-Z])$/;
    const CIF_REGEX: RegExp = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
    const NIE_REGEX: RegExp = /^[XYZ]\d{7,8}[A-Z]$/;

    // Ensure upcase and remove whitespace
    // str = str.toUpperCase().replace(/\s/, '');

    let valid: boolean = false;
    str = str.toUpperCase();
    if (str.match(DNI_REGEX)) {
      valid = GeecValidators.validDNI(str);
    } else if (str.match(CIF_REGEX)) {
      valid = GeecValidators.validCIF(str);
    } else if (str.match(NIE_REGEX)) {
      valid = GeecValidators.validNIE(str);
    }

    return valid;
  }

  private static validDNI(dni: string): boolean {
    const dni_letters: string = "TRWAGMYFPDXBNJZSQVHLCKE";
    const letter: string = dni_letters.charAt(parseInt(dni, 10) % 23);

    return letter === dni.charAt(8);
  }

  private static validNIE(nie: string): boolean {
    // Change the initial letter for the corresponding number and validate as DNI
    let nie_prefix: string = nie.charAt(0);

    switch (nie_prefix) {
      case 'X':
        nie_prefix = "0";
        break;
      case 'Y':
        nie_prefix = "1";
        break;
      case 'Z':
        nie_prefix = "2";
        break;
      default:
        return false;
    }

    return GeecValidators.validDNI(nie_prefix + nie.substr(1));
  }

  private static validCIF(cif: string) {
    const CIF_REGEX: RegExp = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
    const match: RegExpMatchArray = cif.match(CIF_REGEX);
    let letter: string;
    let number: string;
    let control: string;

    try {
      letter = match[1];
      number = match[2];
      control = match[3];
    } catch (e) {
      return false;
    }

    let even_sum: number = 0;
    let odd_sum: number = 0;
    let n: number;

    for (let i: number = 0; i < number.length; i++) {
      n = parseInt(number[i], 10);
      // Odd positions (Even index equals to odd position. i=0 equals first position)
      if (i % 2 === 0) {
        // Odd positions are multiplied first.
        n *= 2;
        // If the multiplication is bigger than 10 we need to adjust
        odd_sum += n < 10 ? n : n - 9;
        // Even positions, just sum them
      } else {
        even_sum += n;
      }
    }

    const control_digit: number = Number((10 - Number((even_sum + odd_sum).toString().substr(-1))).toString().substr(-1));
    const control_letter: string = "JABCDEFGHI".substr(control_digit, 1);

    // Control must be a digit
    if (letter.match(/[ABEH]/)) {
      return Number(control) === control_digit;

      // Control must be a letter
    } else if (letter.match(/[KPQS]/)) {
      return control === control_letter;

      // Can be either
    } else {
      return Number(control) === control_digit || control === control_letter;
    }
  }

}
