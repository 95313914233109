import {Component, Input} from '@angular/core';
import {AbstractControl, AbstractControlDirective} from '@angular/forms';
import {CustomUtils} from "../../utils/custom.utils";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'geec-show-errors',
  templateUrl: './show-errors.component.html'
})
export class ShowErrorsComponent {

  private static readonly errorMessages = {
    "min": (params: any) => ShowErrorsComponent.traducciones.min + ` ${params.min}.`,
    "max": (params: any) => ShowErrorsComponent.traducciones.max + ` ${params.max}.`,
    "required": () => ShowErrorsComponent.traducciones.required,
    "requiredTrue": () => ShowErrorsComponent.traducciones.requiredTrue,
    "email": () => ShowErrorsComponent.traducciones.email,
    "minlength": (params: any) => ShowErrorsComponent.traducciones.minlength + ` ${params.requiredLength}.`,
    "maxlength": (params: any) => ShowErrorsComponent.traducciones.maxlength + ` ${params.requiredLength}.`,
    "pattern": (params: any) => ShowErrorsComponent.traducciones.pattern + ` ${params.requiredPattern}.`,
    "year": () => ShowErrorsComponent.traducciones.year,
    "range": (params: any) => ShowErrorsComponent.traducciones.range + ` ${params.min}` + ShowErrorsComponent.traducciones.i +`${params.max}.`,
    "nif": () => ShowErrorsComponent.traducciones.nif,
    "nie": () => ShowErrorsComponent.traducciones.nie,
    "postalCode": () => ShowErrorsComponent.traducciones.postalCode,
    "multipleCheckboxRequireOne": () => ShowErrorsComponent.traducciones.multipleCheckboxRequiredOne,
    "noWhitespaceRequired": () => ShowErrorsComponent.traducciones.noWhitespaceRequired,
    "validURL": () => ShowErrorsComponent.traducciones.validURL,
    "requiredOtherField": () => ShowErrorsComponent.traducciones.requiredOtherField,
    "dateBefore": (params: any) => ShowErrorsComponent.traducciones.dateBefore + ` ${params.suffix}.`,
    "dateAfter": (params: any) => ShowErrorsComponent.traducciones.dateAfter + `${params.suffix}.`,
    "multiSelectOnlyOne": () => ShowErrorsComponent.traducciones.multiSelectionOnlyOne,
    "minArray": (params: any) => ShowErrorsComponent.traducciones.minArray + ` ${params.min}.`,
    "minLowercase": (params: any) => ShowErrorsComponent.traducciones.minLowercase + ` ${params.min}` + ShowErrorsComponent.traducciones.minLowercaseContinuacion,
    "initEndNoWhitespace": () => ShowErrorsComponent.traducciones.initEndNoWhitespace,
    "noWhitespace": () => ShowErrorsComponent.traducciones.noWhitespace
  };

  @Input()
  public control: AbstractControlDirective | AbstractControl;

  private static traducciones: any = {};
  public constructor(private _translate: TranslateService) {
      this.traducirErrores();
      this._translate.onLangChange.subscribe(() => {
        this.traducirErrores();
      })
    }

  private traducir(tag: string) : string{
    let ruta = 'show-errors';
    return this._translate.instant(ruta + '.' + tag);
  }

  private traducirErrores(){
    ShowErrorsComponent.traducciones = {
      min: this.traducir('min'),
      max: this.traducir('max'),
      required: this.traducir('required'),
      requiredTrue: this.traducir('requiredTrue'),
      email: this.traducir('email'),
      minlength: this.traducir('minlength'),
      maxlength: this.traducir('maxlength'),
      pattern: this.traducir('pattern'),
      year: this.traducir('year'),
      range: this.traducir('range'),
      i: this.traducir('i'),
      nif: this.traducir('nif'),
      nie: this.traducir('nie'),
      postalCode: this.traducir('postalCode'),
      multipleCheckboxRequiredOne: this.traducir('multipleCheckboxRequiredOne'),
      noWhitespaceRequired: this.traducir('noWhitespaceRequired'),
      validURL: this.traducir('validURL'),
      requiredOtherField: this.traducir('requiredOtherField'),
      dateBefore: this.traducir('dateBefore'),
      dateAfter: this.traducir('dateAfter'),
      multiSelectionOnlyOne: this.traducir('multiSelectionOnlyOne'),
      minArray: this.traducir('minArray'),
      minLowercase: this.traducir('minLowercase'),
      minLowercaseContinuacion: this.traducir('minLowercaseContinuacion'),
      initEndNoWhitespace: this.traducir('initEndNoWhitespace'),
      noWhitespace: this.traducir('noWhitespace')
    }
  }

  private static getMessage(type: string, params: any) {
    let msg: string;
    const fn: (params?: any) => string = ShowErrorsComponent.errorMessages[type];
    if (fn) {
      msg = fn(params);
    } else if (params && CustomUtils.isString(params['message'])) {
      msg = <string>params['message'];
    }
    return msg;
  }

  public shouldShowErrors(): boolean {
    return this.control &&
      this.control.errors &&
      (this.control.dirty);
  }

  public listOfErrors(): string[] {
    return Object.keys(this.control.errors).reduce((arr: string[], field: string) => {
      const msg: string = ShowErrorsComponent.getMessage(field, this.control.errors[field]);
      if (msg) {
        arr.push(msg);
      }
      return arr;
    }, []);
  }

}
