import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {EnvironmentType} from '../../../shared/globals';
import {environment} from '../../../../environments/environment';

@Injectable()
export class DevelopmentGuard implements CanActivate {

  private environmentType = environment.type;

  constructor(private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.environmentType === EnvironmentType.PRO) {
      this.router.navigate(['no-autoritzat']);
      return false;
    }

    return true;
  }

}
