import {MapHandler} from "../abstract/map-handler";
import {Observable} from "rxjs";
import {Mapping} from "../mapping";
import {map} from "rxjs/operators";

export class ObservableMapHandler extends MapHandler {

  private readonly factory: (obj: any) => MapHandler;

  constructor(target: Observable<any>, factory: (obj: any) => MapHandler) {
    super(target);
    this.factory = factory;
  }

  // TODO: Handle Observable.fork
  public map(mappings: Array<Mapping>): Observable<any> {
    return (<Observable<any>>this.target).pipe(map((res: any) => {
      const handler: MapHandler = this.factory(res);
      return handler.map(mappings);
    }));
  }
}
