import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {concat, Observable} from "rxjs";
import {CustomUtils} from "../../../utils/custom.utils";
import {Message} from "primeng/api";
import {FacturaService} from "../../../services/factura/factura.service";
import {NotificationsService} from "../../../../core/services/notifications/notifications.service";
import {GeecFormGroup} from "../../../../core/directives/reactive-forms/geec-form-group";
import {GeecFormBuilder} from "../../../../core/directives/reactive-forms/geec-form-builder";
import {ExpedientNumeroModel} from "../../../models/expedient/expedient-numero.model";
import {map, toArray} from "rxjs/operators";
import {IdentifiedObject, IdentifiedResponse} from "../../../../llistats/listing-detail/listing-detail.component";
import {ControllerResponse, ResponseMessage} from "../../../models/forms/controller-response.model";


@Component({
  selector: 'geec-processar-factures',
  templateUrl: './processar-factures.component.html'
})
export class ProcessarFacturesComponent implements OnInit {

  @Input() display: boolean;
  @Input() facturesIdentificades: IdentifiedObject[];
  @Output() displayChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() messagesChange: EventEmitter<Message[]> = new EventEmitter<Message[]>();
  @Output() searchBustia: EventEmitter<void> = new EventEmitter<void>();

  opcionsProcessar: ExpedientNumeroModel[];
  processarForm: GeecFormGroup;

  constructor(private facturaService: FacturaService, private notificationsService: NotificationsService, private gfb: GeecFormBuilder) {
  }

  get idExpedientSelected(): number {
    return this.processarForm.get('idExpedient').value;
  }

  ngOnInit() {
    this.facturaService.getOpcionsProcessar(this.facturesIdentificades[0].keyField).subscribe((opcions: ExpedientNumeroModel[]) => {
      this.opcionsProcessar = opcions;
      this.processarForm = this.gfb.group({
        idExpedient: [null]
      });
    });
  }

  processarFactures(): void {
    this.messagesChange.emit(null);
    const calls: Observable<IdentifiedResponse>[] = [];

    this.facturesIdentificades.forEach((bustiaFacturaIdentificada: IdentifiedObject) => {
      let observable: Observable<ControllerResponse<ResponseMessage>> = this.facturaService.processarFactura(bustiaFacturaIdentificada.keyField, this.idExpedientSelected);
      let identifiedObservable: Observable<IdentifiedResponse> = observable.pipe(map((resp: ControllerResponse<ResponseMessage>) => {
        return {identifiedField: bustiaFacturaIdentificada.keyDefinitionField, response: resp};
      }));
      calls.push(identifiedObservable);
    });

    if (CustomUtils.isArrayNotEmpty(calls)) {
      concat(...calls).pipe(toArray()).subscribe((responses: IdentifiedResponse[]) => {
        const messages: Message[] = this.notificationsService.getMessagesAndNotify(this.notificationsService.identifyMessages(responses));
        this.displayChange.emit(false);
        if (CustomUtils.isArrayNotEmpty(messages)) {
          this.messagesChange.emit(messages);
        }
        this.searchBustia.emit();
      });
    }
  }

  onClose(): void {
    this.displayChange.emit(false);
  }

}
