import {Component, Input} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {GeecFormGroup} from '../../../core/directives/reactive-forms/geec-form-group';
import {TerminisSharedFormValidatorModel} from "../../models/forms/dades-basiques/dades-basiques-shared-form-validator.model";
import {calculateDataFi} from "../../utils/calendar-utils";

@Component({
  selector: 'geec-terminis',
  templateUrl: './terminis.component.html'
})
export class TerminisComponent {

  @Input() public parentFormGroup: GeecFormGroup;
  @Input() public templateValidators: TerminisSharedFormValidatorModel;

  get terminisForm(): FormGroup {
    return this.parentFormGroup.get('terminis') as FormGroup;
  }

  public calculateDataFiExecucio(): void {
    const termini: AbstractControl = this.terminisForm.get('terminiExecucio');
    const tipusTermini: AbstractControl = this.terminisForm.get('tempsTerminiExecucio');
    const dataInici: AbstractControl = this.parentFormGroup.get("dadesExecucio").get('iniciExecucio');
    const dataFi: AbstractControl = this.parentFormGroup.get("dadesExecucio").get('fiExecucio');
    calculateDataFi(termini, tipusTermini, dataInici, dataFi);
  }

}
