import {LooseObject} from "../../shared/models/forms/reactive-form-validator";
import {TramitacioNode} from "./tramitacio-node";

export class TramitacioHistoricEnviamentCorreuNode extends TramitacioNode {

  constructor(backendJSON: LooseObject<any>) {
    super(backendJSON);

    this.icon = "fa fa-envelope-o";
  }

}
