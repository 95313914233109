import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {CustomUtils} from '../../utils/custom.utils';
import {DadesExpedientSharedFormModel} from '../../models/forms/dades-expedient/dades-expedient-form.model';
import {GeecFormGroup} from "../../../core/directives/reactive-forms/geec-form-group";
import {GeecFormBuilder} from "../../../core/directives/reactive-forms/geec-form-builder";
import {DadesExpedientSharedFormValidatorModel} from '../../models/forms/dades-expedient/dades-expedient-form-validator.model';
import {FormUtils} from "../../utils/form-utils";
import {SelectItem, TreeNode} from 'primeng/api';
import {Panel} from "primeng/panel";
import {AppConstants} from "../../../app.constants";
import {UnitatTreeNode} from "../../models/tree/unitat-tree-node";
import {UnitatModel, UnitatService, UnitatTreeModel} from "../../../core/services/unitat/unitat.service";
import {Procediments} from "../../models/expedient/procediments";
import {TreeUtils} from "../../utils/tree-utils";
import {SistemaAdjudicacio} from "../../models/expedient/sistema-adjudicacio";
import {TipusContracte} from "../../models/expedient/tipus-contracte";



@Component({
  selector: 'geec-dades-expedient',
  templateUrl: './dades-expedient.component.html'
})
export class DadesExpedientComponent implements OnInit, OnChanges {

  @Input()
  public parentFormGroup: GeecFormGroup;

  @Input()
  dadesExpedient: DadesExpedientSharedFormModel;

  booleanOptions: SelectItem[] = [...AppConstants.booleanOptions];

  @Input()
  public formValidators: Object;
  public templateValidators: DadesExpedientSharedFormValidatorModel;

  @Input()
  public noMenor: boolean;

  @Output()
  public triggerValidation?: EventEmitter<any> = new EventEmitter();

  @ViewChild('panel')
  public panel: Panel;

  numeroExpedient: string = '';
  numerosSubexpedient: string[] = [];
  unitatsPromotores: UnitatTreeNode[];
  unitatsTramitadores: UnitatTreeNode[];
  selectedUnitatsPromotores: UnitatTreeNode[] = [];
  selectedUnitatTramitadora: UnitatTreeNode;
  loadingChildrenPromotores: boolean;

  public readonly sistemaAdjudicacioEnum: typeof SistemaAdjudicacio = SistemaAdjudicacio;

  containsErrors: (form: AbstractControl) => boolean = FormUtils.containsErrors;

  constructor(private fb: GeecFormBuilder, private unitatService: UnitatService) {
  }

  get dadesExpedientForm() {
    return this.parentFormGroup.get("dadesExpedient") as FormGroup;
  }

  get titolExpedient() {
    return this.dadesExpedientForm.get('titol').value;
  }

  public get isVisibleSubTipoContrato(): boolean {
    return this.tipoContrato === TipusContracte.OBRES
      || this.tipoContrato === TipusContracte.SERVEIS
      || this.tipoContrato === TipusContracte.SUBMINISTRAMENTS;
  }

  get hasLots(): boolean {
    return <boolean>this.dadesExpedientForm.get('lots').value;
  }

  public get tipoContrato(): number {
    return this.dadesExpedientForm.get('tipusContracte').value;
  }

  get isDefinedProcediment(): boolean {
    return CustomUtils.isDefined(this.dadesExpedientForm.get('procediment').value);
  }

  get isProcedimentAbreujat(): boolean {
    return this.isDefinedProcediment &&
      this.dadesExpedientForm.get('procediment').value === Procediments.CONTRACTE_OBERT_SIMPLIFICAT_ABREUJAT;
  }

  get isProcedimentSimplificat(): boolean {
    return this.isDefinedProcediment &&
      this.dadesExpedientForm.get('procediment').value === Procediments.CONTRACTE_OBERT_SIMPLIFICAT;
  }

  get isProcedimentObert(): boolean {
    return this.isDefinedProcediment && (this.dadesExpedientForm.get('procediment').value === Procediments.CONTRACTE_OBERT_ID ||
      this.dadesExpedientForm.get('procediment').value === Procediments.CONTRACTE_OBERT_SIMPLIFICAT);
  }

  get isProcedimentGrupObert(): boolean {
    return this.isDefinedProcediment && this.isProcedimentObert || this.isProcedimentSimplificat || this.isProcedimentAbreujat;
  }

  get isProcedimentGrupNegociat(): boolean {
    return this.isProcedimentNegociatSensePublicitat || this.isProcedimentNegociat;
  }

  get isBasatConcrecioCondicions(): boolean {
    return this.dadesExpedientForm.get('procediment').value === Procediments.BASAT_CONCRECIO_CONDICIONS;
  }

  get isBasatNovaLicitacio(): boolean {
    return this.dadesExpedientForm.get('procediment').value === Procediments.BASAT_NOVA_LICITACIO;
  }

  get isProcedimentNegociat(): boolean {
    return this.dadesExpedientForm.get('procediment').value === Procediments.PROCEDIMENT_NEGOCIAT;
  }

  get isProcedimentNegociatSensePublicitat(): boolean {
    return this.dadesExpedientForm.get('procediment').value === Procediments.PROCEDIMENT_NEGOCIAT_SENSE_PUBLICITAT;
  }

  get isProcedimentRestringit(): boolean {
    return this.dadesExpedientForm.get('procediment').value === Procediments.RESTRINGIT_ID;
  }

  get sistemaAdjudicacio(): number {
    return this.dadesExpedientForm.get('sistemaAdjudicacio').value;
  }

  get isBasatAcordMarc(): boolean {
    return SistemaAdjudicacio.isBasatAcordMarc(this.sistemaAdjudicacio);
  }

  get visibleMesesField(): boolean {
    return this.isProcedimentGrupObert || this.isProcedimentGrupNegociat || this.isBasatNovaLicitacio || this.isBasatConcrecioCondicions || this.isProcedimentRestringit;
  }

  public formValidatorEventEmitter(event: Event): void {
    this.triggerValidation.next(event);
  }

  ngOnChanges(changes) {
    // TODO: PASSAR A REACTIVE MODEL
    if (changes.hasOwnProperty('formValidators') && CustomUtils.isDefined(changes.formValidators)) {
      if (CustomUtils.isDefined(this.formValidators) && this.formValidators.hasOwnProperty('dadesExpedient') && this.dadesExpedient) {
        this.templateValidators = this.formValidators['dadesExpedient'];
        FormUtils.updateFormValues(this.dadesExpedientForm, this.templateValidators);
        if (!changes['formValidators'].previousValue || !changes['formValidators'].previousValue['dadesExpedient']) {
          this.loadUnitats();
        }
      }
    }
  }

  ngOnInit() {
    if (CustomUtils.isUndefinedOrNull(this.parentFormGroup.get('dadesExpedient'))) {
      this.parentFormGroup.addControl("dadesExpedient", this.fb.dadesExpedientGroup());
    }

    if (this.dadesExpedient) {
      this.loadInitialData();
    }
  }

  loadInitialData(): void {
    if (this.dadesExpedient && this.dadesExpedient.numero) {
      this.numeroExpedient = this.dadesExpedient.numero;
      this.numerosSubexpedient = this.dadesExpedient.subExpedients;
    }

    for (let key in this.dadesExpedient) {
      if (this.dadesExpedientForm.get(key) && CustomUtils.isDefined(this.dadesExpedient[key])) {
        // si la propietat existeix al formulari i no es null
        this.dadesExpedientForm.get(key).setValue(this.dadesExpedient[key]);
      }
    }
    FormUtils.updateFormValues(this.dadesExpedientForm, this.templateValidators);
  }

  loadUnitats(): void {
    this.unitatsTramitadores = [];
    this.unitatsPromotores = [];
    this.unitatService.getUnitatTree({
      onlyPromotores: true,
      selectableNodes: this.templateValidators.unitatsPromotores.enabled,
      isTaskPage: true,
      includeInactive: true,
      organUnitats: true
    }).subscribe((promotores: UnitatTreeNode[]) => {
        this.unitatsPromotores = promotores;
        this.unitatsTramitadores = JSON.parse(JSON.stringify(promotores));
        // mark selected unitats
        this.fillSelectedTreeUnitats();
      },
      () => {
        console.log("No s'han pogut carregar les unitats!");
      }
    );
  }

  fillSelectedTreeUnitats(): void {
    if (CustomUtils.isArrayNotEmpty(this.dadesExpedient.unitatsPromotores)) {
      this.dadesExpedient.unitatsPromotores.forEach((unitatSelected: number) => {
        const unitatFound: UnitatTreeNode = <UnitatTreeNode>TreeUtils.findNode(this.unitatsPromotores,
          (node: TreeNode) => node.data && (<UnitatModel>node.data).id === unitatSelected);
        if (unitatFound) {
          this.selectedUnitatsPromotores.push(unitatFound);
        } else {
          this.unitatService.findUnitatInTree(unitatSelected).subscribe((unitat: UnitatTreeModel) => {
            if (unitat) {
              this.selectedUnitatsPromotores.push(new UnitatTreeNode(unitat, [], false, false));
            }
          });
        }
      });
    }

    if (CustomUtils.isDefined(this.dadesExpedient.unitatTramitadora)) {
      const unitatFound: UnitatTreeNode = <UnitatTreeNode>TreeUtils.findNode(this.unitatsTramitadores,
        (node: TreeNode) => node.data && (<UnitatModel>node.data).id === this.dadesExpedient.unitatTramitadora);
      if (unitatFound != null) {
        this.selectedUnitatTramitadora = unitatFound;
      }
    }
  }

  changeSelectedUnitatPromotora(): void {
    this.dadesExpedientForm.get('unitatsPromotores').setValue(this.selectedUnitatsPromotores.map((unitatPromo: UnitatTreeNode) => unitatPromo.data.id));
  }

  expandOrCollapse(event: Event): void {
    if (this.panel.collapsed) {
      this.panel.expand(event);
    } else {
      this.panel.collapse(event);
    }
  }
}
