
  /**
   * Branch: remotes/origin/develop
   * Last commit: 
   *
   * commit e007dc4ee8c421cebf2cb5b1c196f66f13ef8839
   * Author: Jordi Romagos Figueras <jordi.romagos@dxc.com>
   * Date:   Fri Nov 15 12:37:25 2024 +0100
   *     Update environment.ts
   */
   
   
  export const version = '1.0.0-CEGE-build.15-11-2024 12:39:25';

