import {TramitacioTaskDocumentNode} from "../classes/tramitacio-task-document-node";
import {TramitacioTaskNode} from "../classes/tramitacio-task-node";
import {TramitacioFacturaNode} from "../classes/tramitacio-factura-node";
import {TramitacioEstructuralNode} from "../classes/tramitacio-estructural-node";
import {TramitacioExpedientNode} from '../classes/tramitacio-expedient-node';
import {TramitacioNode} from "../classes/tramitacio-node";
import {TramitacioNodeType} from "../classes/model";
import {TramitacioDocumentComptableNode} from "../classes/tramitacio-document-comptable-node";
import {TramitacioDocumentGefactNode} from "../classes/tramitacio-document-gefact-node";
import {TramitacioDocumentSistemaExternNode} from "../classes/tramitacio-document-sistema-extern-node";
import {TramitacioHistoricEnviamentCorreuNode} from "../classes/tramitacio-historic-enviament-correu-node";
import {TramitacioEvidenciesPscpNode} from "../classes/tramitacio-evidencies-pscp-node";
import {TramitacioIntegracioComptableNode} from "../classes/tramitacio-integracio-comptable-node";
import {TramitacioTaskDocumentComptableEcofinNode} from "../classes/tramitacio-task-document-comptable-ecofin-node";
import {TramitacioManamentNode} from "../classes/tramitacio-manament-node";
import {TramitacioTaskEnviarSntNode} from "../classes/tramitacio-task-enviar-snt-node";

export class TramitacioNodeFactory {
  public static new(target: any): TramitacioNode {
    switch (target.tipusNode) {
      case TramitacioNodeType.expedient:
        return new TramitacioExpedientNode(target);
      case TramitacioNodeType.factura:
        return new TramitacioFacturaNode(target);
      case TramitacioNodeType.taskForm:
      case TramitacioNodeType.taskAjustament:
      case TramitacioNodeType.taskDocumentComptable:
      case TramitacioNodeType.taskPscp:
      case TramitacioNodeType.taskGlic:
      case TramitacioNodeType.taskEnviamentCorreu:
      case TramitacioNodeType.taskDocumentComptableSenseIntegracio:
        return new TramitacioTaskNode(target);
      case TramitacioNodeType.taskDocument:
        return new TramitacioTaskDocumentNode(target);
      case TramitacioNodeType.fase:
        const nodeFase: TramitacioEstructuralNode = new TramitacioEstructuralNode(target);
        nodeFase.expandedIcon = 'fa fa-folder-open-o';
        nodeFase.collapsedIcon = 'fa fa-folder-o';
        return nodeFase;
      case TramitacioNodeType.documentComptable:
        return new TramitacioDocumentComptableNode(target);
      case TramitacioNodeType.documentGefact:
        return new TramitacioDocumentGefactNode(target);
      case TramitacioNodeType.documentSistemaExtern:
        return new TramitacioDocumentSistemaExternNode(target);
      case TramitacioNodeType.historicEnviamentCorreu:
        return new TramitacioHistoricEnviamentCorreuNode(target);
      case TramitacioNodeType.evidenciesPscp:
        return new TramitacioEvidenciesPscpNode(target);
      case TramitacioNodeType.integracioComptable:
        return new TramitacioIntegracioComptableNode(target);
      case TramitacioNodeType.taskDocumentComptableEcofin:
        return new TramitacioTaskDocumentComptableEcofinNode(target);
      case TramitacioNodeType.manament:
        return new TramitacioManamentNode(target);
      case TramitacioNodeType.taskEnviarSNT:
        return new TramitacioTaskEnviarSntNode(target);
      default:
        return new TramitacioEstructuralNode(target);
    }
  }
}
