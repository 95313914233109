import {Injectable} from "@angular/core";
import {GeecFormGroup} from "../../../../core/directives/reactive-forms/geec-form-group";
import {AppConstants, TaskKey} from "../../../../app.constants";
import {Validators} from "@angular/forms";
import {GeecValidators} from "../../../validators/geec-validators";
import {GeecFormBuilder} from "../../../../core/directives/reactive-forms/geec-form-builder";
import {OfertaFormValidatorModel} from "../../../models/forms/dades-basiques/dades-basiques-shared-form-validator.model";
import {TableColumn, TableColumnType} from "../../../interfaces/table-column";
import {TascaInfo} from "../../../models/forms/tasca-info";
import {CustomUtils} from "../../../utils/custom.utils";
import {SistemaAdjudicacio} from "../../../models/expedient/sistema-adjudicacio";
import {TranslateService} from "@ngx-translate/core";

export interface OfertaLabels {
  descriptiu: string;
  descripcio: string;
  import: string;
  importIva: string;
  adjudicacio: string;
}

@Injectable()
export class OfertaService {

  traduccionesColumns: any = {};
  traduccionesLabels: any = {};
  constructor(private _gfb: GeecFormBuilder,
              private _translate: TranslateService) {
    this.traducirColumnasYLabels();
    this._translate.onLangChange.subscribe(() => {
      this.traducirColumnasYLabels();
    })
  }

  private traducir(tag: string) : string{
    let ruta = 'dades-ofertes-standalone';
    return this._translate.instant(ruta + '.' + tag);
  }


  private traducirColumnasYLabels() {
    this.traduccionesColumns = {
      oferta: this.traducir('column-indice'),
      nif: this.traducir('column-nif'),
      nom: this.traducir('column-nombre'),
      descripcio: this.traducir('column-descripcion'),
      dataPresentacio: this.traducir('column-fecha-presentacion'),
      tipusPresentacio: this.traducir('column-tipo-presentacion'),
      esUTE: this.traducir('column-esUTE'),
      importOferta: this.traducir('column-importe-oferta'),
      moneda: this.traducir('column-moneda'),
      tipusIva: this.traducir('column-tipo-IVA'),
      importOfertaIva: this.traducir('column-importe-oferta-IVA'),
      adjudicacio: this.traducir('column-adjudicacion'),
      acceptada: this.traducir('column-aceptada'),
      dadesBancaries: this.traducir('column-datos-bancarias-licitador'),
      dataComprovacio: this.traducir('column-fecha-datos-bancarias'),
      estat: this.traducir('column-estado'),
      nomApoderat: this.traducir('column-apoderado-nombre'),
      nifApoderat: this.traducir('column-apoderado-nif'),
      creditor: this.traducir('column-creditor'),
      idSarcat: this.traducir('column-id-sarcat')
    };
    this.traduccionesLabels = {
      oferta: this.traducir('label-descriptivo'),
      descripcioOferta: this.traducir('label-descripcion'),
      import: this.traducir('label-importe'),
      importIva: this.traducir('label-importe-iva'),
      adjudicacio: this.traducir('label-adjudicacion'),
      proposisio: this.traducir('label-proposicion'),
      descripcioProposicio: this.traducir('label-descripcion-proposicion'),
      importProposicio: this.traducir('label-importe-proposicion'),
      importProposicioIva: this.traducir('label-importe-proposicion-iva'),
      admissio: this.traducir('label-admision'),
      solicitudParticipacio: this.traducir('label-solicitud-participacion'),
      solicitudParticipacioCurt: this.traducir('label-solicitud-participacion-corto'),
    };
  }



  createForm(): GeecFormGroup {
    const {min, max} = AppConstants.importRange;
    return this._gfb.group({
      idOferta: [null],
      index: [null],
      nif: [null],
      nom: [{value: null, disabled: true}],
      descripcio: [null, [Validators.maxLength(150)]],
      dataPresentacio: [null],
      tipusPresentacio: [null],
      esUTE: [false],
      importOferta: [null, [GeecValidators.range(min, max)]],
      moneda: [null],
      tipusIva: [null],
      importOfertaIva: [null],
      adjudicacio: [false],
      acceptada: [false],
      estat: [null],
      apoderatNom: [null, [Validators.maxLength(80)]],
      apoderatNIF: [null, GeecValidators.nif],
      creditor: [null],
      idSarcat: [null],
      idAddOfertaSDARef: [null],
      idLicitador: [null],
      idSucursal: [null],
      idRepresentant: [null],
      dataDadesBancaries: [{value: null, disabled: true}],
      dadesBancariesLicitadorId: [{value: null, disabled: true}],
      descartada: [false],
      tipusIvaExpedient: [null],
      importLicitacio: [null],
      ordreRecepcio: [null],
      idOfertaExtern: [null]
    });
  }

  initColumns(tascaInfo: TascaInfo, taskKey: string, templateValidators: OfertaFormValidatorModel): TableColumn[] {
    const labels: OfertaLabels = this.getLabels(tascaInfo, taskKey);
    return [
      {field: "index", header: labels.descriptiu, width: "6em", type: TableColumnType.INTEGER},
      {field: "nif", header: this.traduccionesColumns.nif, width: "10em", type: TableColumnType.TEXT},
      {field: "nom", header: this.traduccionesColumns.nom, width: "14em", type: TableColumnType.TEXT},
      {field: "descripcio", header: this.traduccionesColumns.descripcio, width: "12em", type: TableColumnType.TEXT},
      {field: "dataPresentacio", header: this.traduccionesColumns.dataPresentacio, width: "14em", type: TableColumnType.TIMESTAMP},
      {field: "tipusPresentacio", header: this.traduccionesColumns.tipusPresentacio, width: "13em", type: TableColumnType.SELECT},
      {field: "esUTE", header: this.traduccionesColumns.esUTE, width: "5em", type: TableColumnType.BOOLEAN},
      {field: "importOferta", header: labels.import, width: "11em", type: TableColumnType.DECIMAL},
      {field: "moneda", header: this.traduccionesColumns.moneda, width: "7em", type: TableColumnType.SELECT},
      {field: "tipusIva", header: this.traduccionesColumns.tipusIva, width: "10em", type: TableColumnType.SELECT},
      {field: "importOfertaIva", header: labels.importIva, width: "13em", type: TableColumnType.DECIMAL},
      {field: "adjudicacio", header: labels.adjudicacio, width: "12em", type: TableColumnType.BOOLEAN},
      {field: "acceptada", header: this.traduccionesColumns.acceptada, width: "8em", type: TableColumnType.BOOLEAN},
      {field: "dadesBancariesLicitadorId", header: this.traduccionesColumns.dadesBancaries, width: "11em", type: TableColumnType.SELECT},
      {field: "dataDadesBancaries", header: this.traduccionesColumns.dataComprovacio, width: "14em", type: TableColumnType.TIMESTAMP},
      {field: "estat", header: this.traduccionesColumns.estat, width: "8em", type: TableColumnType.SELECT},
      {field: "apoderatNom", header: this.traduccionesColumns.nomApoderat, width: "12em", type: TableColumnType.TEXT},
      {field: "apoderatNIF", header: this.traduccionesColumns.nifApoderat, width: "11em", type: TableColumnType.TEXT},
      // {field: "creditor", header: this.traduccionesColumns.creditor, width: "8em", type: TableColumnType.TEXT},
      {field: "idSarcat", header: this.traduccionesColumns.idSarcat, width: "8em", type: TableColumnType.TEXT}
    ].filter((column: TableColumn) => templateValidators[column.field] && templateValidators[column.field].visible);
  }

  public getLabels(tascaInfo: TascaInfo, taskKey: string): OfertaLabels {
    const labels: OfertaLabels = {
      descriptiu: this.traduccionesColumns.oferta,
      descripcio: this.traduccionesLabels.descripcioOferta,
      import: this.traduccionesLabels.import,
      importIva: this.traduccionesLabels.importIva,
      adjudicacio: this.traduccionesLabels.adjudicacio
    };
    if (this._isSDA(tascaInfo)) {
      labels.descriptiu = this.traduccionesLabels.proposisio;
      labels.descripcio = this.traduccionesLabels.descripcioProposicio;
      labels.import = this.traduccionesLabels.importProposicio;
      labels.importIva = this.traduccionesLabels.importProposicioIva;
      labels.adjudicacio = this.traduccionesLabels.adjudicacio;
    } else if (this._isSollicitudParticipacio(taskKey)) {
      labels.descripcio = this.traduccionesLabels.solicitudParticipacio;
      labels.descriptiu = this.traduccionesLabels.solicitudParticipacioCurt;
    }
    return labels;
  }

  private _isSollicitudParticipacio(taskKey: string): boolean {
    return CustomUtils.isTask(taskKey, [TaskKey.R_REC_SOL, TaskKey.R_AVA_SOL]);
  }

  private _isSDA(tascaInfo: TascaInfo): boolean {
    return tascaInfo.expedient.sistemaAdjudicacio === SistemaAdjudicacio.SISTEMA_DINAMIC_ADQUISICIO;
  }
}
