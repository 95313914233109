import {LooseObject} from "./reactive-form-validator";
import {TascaInfo} from "./tasca-info";

export class FrontDTO {

  expedientId: number;
  taskId: string;
  taskeableId: number;
  formDTO: Object;
  reopenMotiu: string;
  taskOptions: LooseObject<string>;

  constructor(expedientId: number, taskId: string, taskeableId: number, formDTO: Object, taskOptions?: LooseObject<any>) {
    this.expedientId = expedientId;
    this.taskId = taskId;
    this.taskeableId = taskeableId;
    this.formDTO = formDTO;
    this.taskOptions = taskOptions || null;
  }

  public static fromTascaInfo(tascaInfo: TascaInfo, formDto: any, taskOptions?: any): FrontDTO {
    return new FrontDTO(tascaInfo.expedient.id, tascaInfo.tasca.id, tascaInfo.taskeable.id, formDto, taskOptions);
  }
}

